import React from "react";
import ReactDOM from "react-dom";

import TagManager from 'react-gtm-module'
import Router from "./Router/Router";
import "./App.scss";

if(process.env.GTM_ID?.length && process.env.GTM_ID?.length > 0) {
    const tagManagerArgs = {
        gtmId: process.env.GTM_ID
    }

    TagManager.initialize(tagManagerArgs)
}

ReactDOM.render(<Router />, document.getElementById("root"));
