import * as React from 'react';
import { createContext, useContext, useEffect, useState } from 'react';
import { getRequest } from '../../../Helper/ApiClient';

interface BasketHeaderInfo {
  total_quantity: number;
  total_price: number;
  is_backorder_confirmation: boolean;
}

interface INcwContext {
  basketInfo: BasketHeaderInfo | null;
  loading: boolean;
  refetchNcwBasketHeader: () => void;
}

const NcwContext = createContext<INcwContext>({
  basketInfo: null,
  loading: false,
  refetchNcwBasketHeader: () => {}
});

export function NcwProvider({ children }: any) {
  const [basketInfo, setBasketInfo] = useState<BasketHeaderInfo | null>(null);

  const basketHeaderRequest = getRequest<BasketHeaderInfo>('/api/onlineshop/newcomer-week/basket-header');

  useEffect(() => {
    if (basketHeaderRequest.isSuccess && basketHeaderRequest.data) {
      setBasketInfo(basketHeaderRequest.data);
    } else {
      setBasketInfo(null);
    }
  }, [basketHeaderRequest.data]);

  const refetchNcwBasketHeader = () => {
    basketHeaderRequest.refetch();
  };

  return (
    <NcwContext.Provider
      value={{
        basketInfo,
        refetchNcwBasketHeader,
        loading: basketHeaderRequest.isLoading,
      }}
    >
      {children}
    </NcwContext.Provider>
  );
}

export function useNcw() {
  const context = useContext(NcwContext);
  if (!context) {
    throw new Error('useNcw() must be used within NcwProvider');
  }
  return context;
}
