import React, { useEffect } from 'react';
import { Col, Container, Row, Table } from 'react-bootstrap';
import { getRequest, postRequest } from '../../../Helper/ApiClient';
import { formatCurrencyEur } from '../../../Helper/formatter';
import NotifyUser from '../../../Abstracts/ProductList/Products/Product/ProductAttributes/ProductAttribute/NotifyUser/NotifyUser';
import Link from '../../../Abstracts/Link/Link';

export default function Eta() {
  const etaProductsRequest = getRequest(
    '/api/onlineshop/eta-product-attributes'
  );
  const visitPageMutation = postRequest('/api/onlineshop/visit-page');

  useEffect(() => {
    let formData = new FormData();
    formData.append('page', 'eta');
    visitPageMutation.mutate(formData);
  }, []);

  return (
    <Container>
      <Row className="pt-5">
        <Col>
          <h1>ETA</h1>
          <p>
            This is an overview of all deliveries which we will receive in the
            near future. An ETA is a raw guess of the arrival time.
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          {etaProductsRequest.isLoading ? (
            <span>loading...</span>
          ) : (
            <>
              <div className="d-none d-md-block">
                <Table borderless>
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Brand</th>
                      <th>Product</th>
                      <th>Flavour</th>
                      <th>Price</th>
                      <th className="text-center">Notifications</th>
                    </tr>
                  </thead>
                  <tbody>
                    {etaProductsRequest.isSuccess &&
                      etaProductsRequest.data.length > 0 &&
                      etaProductsRequest.data.map((eta: any) => {
                        return (
                          <tr
                            key={eta.id}
                            className="border-bottom bg-secondary border-white border-5"
                          >
                            <td className="align-middle">
                              <div>{eta.eta_date}</div>
                            </td>
                            <td className="align-middle">
                              <Link
                                className="inherit"
                                href={'/brand/' + eta.product_size_erp_id}
                              >
                                {eta.brand_name}
                              </Link>
                            </td>
                            <td className="align-middle">
                              <Link
                                className="inherit"
                                href={'/productSize/' + eta.product_size_erp_id}
                              >
                                {eta.product_size_name}
                              </Link>
                            </td>
                            <td className="align-middle">
                              {eta.product_attribute_name}
                            </td>
                            <td className="align-middle">
                              <div>{formatCurrencyEur(eta.price)}</div>
                            </td>
                            <td>
                              <div>
                                <NotifyUser
                                  id={eta.product_attribute_erp_id}
                                  has_notify_me={eta.has_notify_me}
                                />
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    {etaProductsRequest.isSuccess &&
                      etaProductsRequest.data.length < 1 && (
                        <tr>
                          <td colSpan={6} className="p-5 text-center">
                            <i>No Results.</i>
                          </td>
                        </tr>
                      )}
                  </tbody>
                </Table>
              </div>
              <div className="d-sm-block d-md-none">
                <Table borderless>
                  <tbody>
                    {etaProductsRequest.isSuccess &&
                      etaProductsRequest.data.length > 0 &&
                      etaProductsRequest.data.map((eta: any) => {
                        return (
                          <React.Fragment key={eta.id}>
                            <tr className="bg-secondary border-bottom">
                              <td>Date:</td>
                              <td className="border-start">
                                <div>{eta.eta_date}</div>
                              </td>
                            </tr>
                            <tr className="bg-secondary border-bottom">
                              <td>Brand:</td>
                              <td className="border-start">
                                <div>{eta.brand_name}</div>
                              </td>
                            </tr>
                            <tr className="bg-secondary border-bottom">
                              <td>Product:</td>
                              <td className="border-start">
                                <div>{eta.product_size_name}</div>
                              </td>
                            </tr>
                            <tr className="bg-secondary border-bottom">
                              <td>Flavour:</td>
                              <td className="border-start">
                                <div>{eta.product_attribute_name}</div>
                              </td>
                            </tr>
                            <tr className="bg-secondary border-bottom">
                              <td>Price:</td>
                              <td className="border-start">
                                <div>{formatCurrencyEur(eta.price)}</div>
                              </td>
                            </tr>
                            <tr className="bg-secondary border-bottom">
                              <td colSpan={2} className="p-0">
                                <div className="p-2">
                                  <NotifyUser
                                    id={eta.product_attribute_erp_id}
                                    has_notify_me={eta.has_notify_me}
                                  />
                                </div>
                              </td>
                            </tr>
                            <tr className="bg-secondary border-bottom border-5 border-white"></tr>
                          </React.Fragment>
                        );
                      })}
                  </tbody>
                </Table>
              </div>
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
}
