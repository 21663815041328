import { useMutation, UseMutationResult } from 'react-query';
import makeRequest from './makeRequest';
import { SyntheticEvent } from 'react';

export default function usePatchMutation<TData>(url: string): UseMutationResult<
  TData,
  Error,
  BodyInit
> & {
  onUpdateHandler: (event: SyntheticEvent) => void;
} {
  const mutation = useMutation<TData, Error, BodyInit>((body) =>
    makeRequest(url, 'PATCH', body)
  );

  const onUpdateHandler = function (event: SyntheticEvent) {
    const target = event.target;
    if (!(target instanceof HTMLElement)) {
      throw Error('Invalid event target!');
    }

    const body: DOMStringMap = {};
    if (target instanceof HTMLSelectElement) {
      body[target.name] = target.value;
    } else if (target instanceof HTMLInputElement) {
      if (target.type == 'checkbox') {
        if (target.checked) {
          body[target.name] = '1';
        } else {
          body[target.name] = '0';
        }
      } else {
        body[target.name] = target.value;
      }
    }
    for (const property in target.dataset) {
      body[property] = target.dataset[property];
    }

    mutation.mutate(JSON.stringify(body));
  };

  return { ...mutation, onUpdateHandler };
}
