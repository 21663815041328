import React from 'react';

// @ts-ignore
import styles from './PreHeader.module.scss?module';
import Vts from './Vts/Vts';

export default function PreHeader() {
  if (false) {
    return <Vts />;
  } else {
    return <></>;
  }
}
