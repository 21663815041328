import { useMutation, UseMutationResult } from 'react-query';
import makeRequest from './makeRequest';
import { SyntheticEvent } from 'react';

export default function useDeleteMutation<TData>(
  url: string
): UseMutationResult<TData, Error, null | void> & {
  onClickHandler: (event: SyntheticEvent) => void;
  onClickIfConfirmedHandler: (event: SyntheticEvent) => void;
} {
  const mutation = useMutation<TData, Error, null | void>(() =>
    makeRequest(url, 'DELETE')
  );

  const onClickIfConfirmedHandler = function (event: SyntheticEvent) {
    const target = event.currentTarget;
    let confirmMessage = 'Delete?';
    // @ts-ignore
    let dataset = target.dataset;
    if (typeof dataset['confirm_message'] === 'string') {
      confirmMessage = dataset['confirm_message'];
    }
    if (confirm(confirmMessage)) {
      onClickHandler(event);
    }
  };
  const onClickHandler = function (event: SyntheticEvent) {
    mutation.mutate();
  };

  return { ...mutation, onClickHandler, onClickIfConfirmedHandler };
}
