import { faSquareList } from '@fortawesome/sharp-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { IProductListProductAttribute } from '../../../../../ProductList';
// @ts-ignore
import style from './NutritionFacts.module.scss?module';
import { getRequest } from '../../../../../../../Helper/ApiClient';

interface INutritionFactImage {
  language: string;
  public_url: string;
  is_default: boolean;
}

const NutritionFacts = ({
  product_attribute_id,
}: {
  product_attribute_id?: number;
}) => {
  const getNutritionFactsRequest = getRequest(
    `/api/onlineshop/product-attributes/${product_attribute_id}/nutrition-facts`,
    undefined,
    { enabled: false }
  );
  const [show, setShow] = useState(false);
  const [labelLink, setLabelLink] = useState<string>();

  const handleLocaleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setLabelLink(
      getNutritionFactsRequest.data?.find(
        (nutritionFactImage) =>
          nutritionFactImage.language == event.currentTarget.value
      )?.public_url
    );
  };

  const handleClickNfBtn = () => {
    getNutritionFactsRequest.refetch().then(() => setShow(true));
  };

  useEffect(() => {
    if (
      getNutritionFactsRequest.isFetched &&
      getNutritionFactsRequest.data &&
      getNutritionFactsRequest.data.length > 0
    ) {
      const default_label = getNutritionFactsRequest.data?.find(
        (nutritionFactImage) => nutritionFactImage.is_default
      )?.public_url;

      if (default_label) {
        setLabelLink(default_label);
      } else {
        setLabelLink(getNutritionFactsRequest.data[0].public_url);
      }
    }
  }, [getNutritionFactsRequest.isFetching]);

  return (
    <>
      <div onClick={() => handleClickNfBtn()} style={{ cursor: 'pointer' }}>
        <FontAwesomeIcon
          size="lg"
          icon={faSquareList}
          className={'fa-rotate-180'}
        />
      </div>

      <Modal show={show} centered size="lg" onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <select
            style={{ textTransform: 'capitalize' }}
            defaultValue={
              getNutritionFactsRequest.data?.find(
                (nutritionFactImage) => nutritionFactImage.is_default
              )?.language
            }
            onChange={handleLocaleChange}
          >
            {getNutritionFactsRequest.data?.map((nutritionFactImage, key) => (
              <option
                key={key}
                value={nutritionFactImage.language}
              >
                {nutritionFactImage.language}
              </option>
            ))}
          </select>
        </Modal.Header>
        <Modal.Body>
          <div className="w-100 text-center">
            <img
              className={style.NutritionFactsImage}
              src={labelLink}
              alt="Nutrition-Facts-image"
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default NutritionFacts;
