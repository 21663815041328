import React from 'react';
import { Col, Image } from 'react-bootstrap';
import cn from 'classnames';
import { IProductSize } from '../../../../Interface/Award';

// @ts-ignore
import styles from './AwardProduct.module.scss?module';

export default function AwardProduct(props: {
  awardProduct: IProductSize;
  onClick: any;
  selected: boolean;
  votingActive: boolean;
  isWinner?: boolean;
}) {
  const votingActive = true;
  return (
    <Col
      onClick={props.onClick}
      className={cn(
        'pb-3 awards-nominee',
        props.selected ? 'awards-selected' : '',
        props.votingActive ? 'awards-voting-active' : ''
      )}
    >
      <div style={{ position: 'relative' }}>
        {props.isWinner !== undefined && props.isWinner && props.awardProduct.overlay_image &&  (
          <Image
            className="w-100"
            style={{ position: 'absolute', left: '0', top: '0' }}
            src={props.awardProduct.overlay_image}
          />
        )}
        <Image
          src={props.awardProduct?.image}
          className={`${
            props.isWinner !== undefined
              ? props.isWinner === false
                ? styles.GreyScale
                : ''
              : ''
          } w-100`}
        />
      </div>
      <div className={'fw-bold'}>{props.awardProduct.brand.name}</div>
      <div>{props.awardProduct.name}</div>
    </Col>
  );
}
