import React, { useEffect } from "react";
import { getRequest } from "../../../Helper/ApiClient";
import { MenuKey } from "./Menu";
import MenuItem from "./MenuItem/MenuItem";

interface IProps {
  active: string | null;
  setActive: React.Dispatch<React.SetStateAction<string | null>>;
  closeSidebar: () => void;
}

const Brands: React.FC<IProps> = ({ active, setActive, closeSidebar }) => {
  const topBrands = getRequest(
    "/api/onlineshop/brand/top",
    "/api/onlineshop/brand/top"
  );
  const brands = getRequest("/api/onlineshop/brand", "/api/onlineshop/brand");

  return (
    <>
      {!active && (
        <MenuItem
          text="Brands"
          icon="right"
          onClick={() => {
            setActive(MenuKey.brands);
          }}
        />
      )}

      {active === MenuKey.brands && (
        <>
          <MenuItem
            className="fw-bold"
            text="Brands"
            icon="left"
            onClick={() => setActive(null)}
          />

          {topBrands?.data?.data && (
            <>
              <MenuItem className="fw-bold" text="Top Brands" />
              {topBrands.data.data.map(({ erp_id, name }, key) => (
                <MenuItem
                  key={erp_id}
                  text={name}
                  link={`/product-list/brand/${erp_id}`}
                  onClick={closeSidebar}
                />
              ))}
            </>
          )}

          {brands?.data?.data && (
            <>
              <MenuItem className="fw-bold" text="All Brands" />
              {Object.keys(brands.data.data).map((letter) =>
                brands.data.data[letter].map((brand) => (
                  <MenuItem
                    key={brand.erp_id}
                    text={brand.name}
                    link={`product-list/brand/${brand.erp_id}`}
                    onClick={closeSidebar}
                  />
                ))
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default Brands;
