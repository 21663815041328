import * as React from 'react';
import { useContext, useEffect } from 'react';
import { Accordion, Col, Container, Row } from 'react-bootstrap';
//@ts-ignore
import style from './VtsBasketPositions.module.scss?module';
import { formatCurrencyEur } from '../../../../../Helper/formatter';
import VtsBasketPosition from './VtsBasketPosition/VtsBasketPosition';
import { VtsContext } from '../../../VirtualTradeShow';
import VtsBasketConfirmed from '../VtsBasketConfirmed/VtsBasketConfirmed';
import VtsBasketSamplePosition from './VtsBasketSamplePosition/VtsBasketSamplePosition';
import usePatchMutation from '../../../../../Helper/usePatchMutation';
import {
  VtsBasketPositionInterface,
  VtsBasketExhibitorPositionInterface,
} from '../../../../../../Interface/Vts';
import LoadingSpinner from '../../../../../Abstracts/LoadingSpinner/LoadingSpinner';
import ButtonMutation from '../../../../../Abstracts/ButtonMutation/ButtonMutation';
import {useSearchParams} from "react-router-dom";
import {getRequest} from "../../../../../Helper/ApiClient";

export default function VtsBasketPositions(props: { basketRequest: any }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const vtsContext = useContext(VtsContext);
  const basketRequest = props.basketRequest;
  const basketPatchMutation = usePatchMutation(`/api/onlineshop/vts-basket${searchParams.toString().length > 0 ? `?${searchParams.toString()}` : ''}`);

  let basket = basketRequest.data;
  let positions = basketRequest.data?.positions;
  let sample_positions = basketRequest.data?.sample_positions;

  useEffect(() => {
    if (basketPatchMutation.isSuccess) {
      basketRequest.refetch();
    }
  }, [basketPatchMutation.isSuccess]);

  if (basketRequest.isError) {
    return <span>Error: {basketRequest.error.message}</span>;
  }

  if (!basket) {
    return (
      <Row className={'py-5 text-center'}>
        <Col>
          <LoadingSpinner />
        </Col>
      </Row>
    );
  }

  if (basket.is_confirmed) {
    return <VtsBasketConfirmed />;
  }

  return (
    <>
      <Row>
        <Col>
          <h2 className={'fs-3 fst-italic fw-bold'}>Product overview</h2>
        </Col>
      </Row>
      {positions && positions.length > 0 ? (
        <>
          <Accordion className={style.accordion}>
            {getExhibitorPositions(positions).map((exhibitorPosition) => {
              const key = exhibitorPosition.vts_exhibitor.id.toString();
              return (
                <Accordion.Item eventKey={key} key={key}>
                  <Accordion.Header
                    aria-controls={'content' + key}
                    id={'header' + key}
                  >
                    <Row className={style.rowInHeader}>
                      <Col xs={6}>
                        <div
                          style={{
                            width: '100%',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {exhibitorPosition.vts_exhibitor.name}
                        </div>
                      </Col>
                      <Col xl={1} className={'d-none d-xl-block'}></Col>
                      <Col xl={1} className={'d-none d-xl-block text-end'}>
                        {exhibitorPosition.totalQuantity}
                      </Col>
                      <Col xl={1} className={'d-none d-xl-block'}></Col>
                      <Col
                        xs={4}
                        xl={2}
                        className={'text-end'}
                        style={{ direction: 'rtl' }}
                      >
                        {formatCurrencyEur(exhibitorPosition.totalPriceExclVat)}
                      </Col>
                    </Row>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className={'w-100'}>
                      {exhibitorPosition.positions.map((position) => {
                        return (
                          <VtsBasketPosition
                            key={position.product_attribute.id}
                            basketPosition={position}
                            refetchBasket={basketRequest.refetch}
                          />
                        );
                      })}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              );
            })}
          </Accordion>
          <Row className={'fw-bolder fst-italic fs-3'}>
            <Col xl={7} xs={2}>
              Total
            </Col>
            <Col xl={1} className={'text-end d-none d-xl-block'}>
              {basket.total_positions_quantity}
            </Col>
            <Col xl={3} xs={8} className={'text-end'}>
              {formatCurrencyEur(basket.total_positions_price_excl_vat)}
            </Col>
          </Row>
        </>
      ) : (
        <div className={'py-4 text-center'}>Your order is still empty.</div>
      )}

      {sample_positions && sample_positions.length > 0 && (
        <>
          <Row className={'pt-4'}>
            <Col>
              <h2 className={'fs-3 fst-italic fw-bold'}>Free samples</h2>
            </Col>
          </Row>
          {sample_positions.map((sample_position) => {
            return (
              <VtsBasketSamplePosition
                key={sample_position.id}
                basketSamplePosition={sample_position}
                refetchBasket={basketRequest.refetch}
              />
            );
          })}
          <Row className={'pt-2 fw-bolder fst-italic fs-5'}>
            <Col xs={6}>Total</Col>
            <Col xs={3} md={5} xl={2} className={'text-end'}>
              {basket.total_sample_positions_quantity}
            </Col>
          </Row>
        </>
      )}

      {vtsContext.vts && vtsContext.vts.is_confirmation && (
        <Row className={'pt-5'}>
          <Col>
            {/*<Button*/}
            {/*  data-confirm="1"*/}
            {/*  onClick={basketPatchMutation.onUpdateHandler}*/}
            {/*  className={'btn btn-primary'}*/}
            {/*>*/}
            {/*  Confirm*/}
            {/*</Button>*/}
            <ButtonMutation
              data-confirm="1"
              onClick={basketPatchMutation.onUpdateHandler}
              mutationResult={basketPatchMutation}
            >
              Confirm
            </ButtonMutation>
          </Col>
        </Row>
      )}
    </>
  );
}

function getExhibitorPositions(
  all_positions: VtsBasketPositionInterface[]
): VtsBasketExhibitorPositionInterface[] {
  const map = new Map();

  const all_positions_sorted = all_positions.sort(sortPositions);

  for (const position of all_positions_sorted) {
    const key = '_' + position.product_attribute.product_size.vts_exhibitor.id;
    if (map.has(key)) {
      map.get(key).positions.push(position);
      map.get(key).totalQuantity += position.quantity;
      map.get(key).totalPriceExclVat +=
        position.quantity * position.singlePriceExclVat;
    } else {
      map.set(key, {
        vts_exhibitor: position.product_attribute.product_size.vts_exhibitor,
        totalQuantity: position.quantity,
        totalPriceExclVat: position.quantity * position.singlePriceExclVat,
        positions: [position],
      });
    }
  }
  return Array.from(map.values());
}

const sortPositions = (
  a: VtsBasketPositionInterface,
  b: VtsBasketPositionInterface
) => {
  // sort by brand
  if (
    a.product_attribute.product_size.product.brand.name <
    b.product_attribute.product_size.product.brand.name
  ) {
    return -1;
  } else if (
    a.product_attribute.product_size.product.brand.name >
    b.product_attribute.product_size.product.brand.name
  ) {
    return 1;
  } else {
    // sort by product_size
    if (
      a.product_attribute.product_size.name <
      b.product_attribute.product_size.name
    ) {
      return -1;
    } else if (
      a.product_attribute.product_size.name >
      b.product_attribute.product_size.name
    ) {
      return 1;
    } else {
      // sort by product_attribute
      if (a.product_attribute.name < b.product_attribute.name) {
        return -1;
      } else if (a.product_attribute.name > b.product_attribute.name) {
        return 1;
      } else {
        return 0;
      }
    }
  }
};
