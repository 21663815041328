import React from 'react';
import { Table as BootstrapTable, TableProps } from 'react-bootstrap';

// @ts-ignore
import styles from './Table.module.scss?module';
import cn from 'classnames';

export default function Table({ children, ...props }: TableProps) {
  return (
    <BootstrapTable
      borderless
      className={cn(props.className, styles.Table)}
      {...props}
    >
      {children}
    </BootstrapTable>
  );
}
