import { useState, useEffect } from "react";

function getScroll() {
  const x = window.scrollX;
  const y = window.scrollY;
  return {
    x,
    y,
  };
}

export default function useScroll() {
  const [scroll, setScroll] = useState(getScroll());
  useEffect(() => {
    function handleScroll() {
      setScroll(getScroll());
    }
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return scroll;
}
