import * as React from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import { getRequest, postRequest } from '../../../../../Helper/ApiClient';
import FlipCard from './FlipCard/FlipCard';
import { IVtsExhibitor } from '../../../Main/Admin/Exhibitors/Exhibitors';

export default function Highlight({ exhibitor }: { exhibitor: IVtsExhibitor }) {
  const highlightRequest = getRequest(
    '/api/virtual-trade-show/exhibitor-highlights/' + exhibitor.id
  );

  const viewHighlightMutation = postRequest(
    '/api/virtual-trade-show/view-highlight'
  );

  const handleViewHighlight = (highlightId: number, viewed: boolean) => {
    if (viewed) {
      return;
    }
    let formData = new FormData();
    formData.append('highlight_id', highlightId.toString());
    viewHighlightMutation.mutate(formData, {
      onSuccess() {
        highlightRequest.refetch();
      },
    });
  };

  return (
    <>
      <Row>
        <Col>
          <h2 className={'popupHeadline'}>Highlight of {exhibitor.name}</h2>
        </Col>
      </Row>

      <Row className="align-items-stretch">
        {highlightRequest.isLoading && (
          <Col className="text-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Col>
        )}
        {highlightRequest.isSuccess &&
          highlightRequest.data &&
          highlightRequest.data.map((highlight: any) => {
            return (
              <Col
                key={highlight.id}
                md={8}
                xl={4}
                className={'pt-3 mx-auto mx-xl-0 d-flex justify-content-center'}
              >
                <FlipCard
                  imageInitial={highlight.imageInitialLink}
                  imageFlipped={highlight.imageFlippedLink}
                  viewed={highlight.isViewedByUser}
                  syncHighlightInteraction={() =>
                    handleViewHighlight(highlight.id, highlight.isViewedByUser)
                  }
                />
              </Col>
            );
          })}
      </Row>
    </>
  );
}
