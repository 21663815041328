import React, { Fragment, useEffect, useRef, useState } from 'react';

// @ts-ignore
import styles from './ProductPages.module.scss?module';
import { getRequest } from '../../../../Helper/ApiClient';
import { INewComerWeekProduct } from '../ProductsSlideshow/ProductsSlideshow';
import { useNavigate, useParams } from 'react-router-dom';
import SlideShowSlider from './SlideShowSlider/SlideShowSlider';
import { useSliderGesture } from '../../../../Hooks/useSliderGesture';
import useNewcomerWeekHTMLConfig from '../../../../Hooks/useNewcomerWeekHTMLConfig';
import Paginator from '../../Paginator/Paginator';
import useViewport from '../../../../Hooks/useViewport';
import { Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import ProductSlidePageInformation from './SlideShowSlider/VerticalSections/ProductSlidePageInformation/ProductSlidePageInformation';
import ProductSlidePageProducts from './SlideShowSlider/VerticalSections/ProductSlidePageProducts/ProductSlidePageProducts';

export type AnimationStepsTypes = {
  hideNoneActiveProducts: boolean;
  moveActiveProductToRight: boolean;
  showProductInformationSection: boolean;
  showProductSizeSection: boolean;
};

export default function ProductPages() {
  const viewport = useViewport(1200);
  useNewcomerWeekHTMLConfig(styles.NewcomerWeekHtml);
  const { pid } = useParams();
  const navigate = useNavigate();
  const productsRequest = getRequest<INewComerWeekProduct[]>(
    `/api/onlineshop/newcomer-week/products`
  );
  const [activeIndex, setActiveIndex] = useState(-1);
  const [isLastProduct, setIsLastProduct] = useState(false);
  const [isFirstProduct, setIsFirstProduct] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [animationSteps, setAnimationSteps] = useState({
    hideNoneActiveProducts: false,
    moveActiveProductToRight: false,
    showProductInformationSection: false,
    showProductSizeSection: false,
  });
  const [productDetailVisible, setProductDetailVisible] = useState(false);
  const cursorRef = useRef<HTMLDivElement>(null);
  const [cursorType, setCursorType] = useState<
    'none' | 'next' | 'prev' | 'more'
  >('none');

  const sliderRef = useSliderGesture({
    onSwipeLeft: slideToPrevProduct,
    onSwipeRight: slideToNextProduct,
  });

  useEffect(() => {
    if (!pid || isNaN(Number(pid))) {
      if (
        productsRequest.data &&
        productsRequest.data.length > 0 &&
        viewport == 'mobile'
      ) {
        navigate(`/newcomer-week/products/${productsRequest.data[0].erp_id}`);
      } else {
        navigate(
          `/newcomer-week/products/${
            productsRequest.data && productsRequest.data[1].erp_id
          }`
        );
      }
    }
    if (pid && productsRequest.data) {
      const index = productsRequest.data.findIndex(
        (product) => product.erp_id === Number(pid)
      );

      if (index !== -1) {
        setActiveIndex(index);
        setIsLastProduct(index === productsRequest.data.length - 1);
        setIsFirstProduct(index === 0);
      }
    }

    return () => {
      setActiveIndex(-1);
    };
  }, [pid, productsRequest.data]);

  function slideToNextProduct() {
    if (productsRequest.data && activeIndex < productsRequest.data.length - 1) {
      const nextIndex = activeIndex + 1;
      const nextProduct = productsRequest.data[nextIndex];
      navigate(`/newcomer-week/products/${nextProduct.erp_id}`);
    }
  }

  function slideToPrevProduct() {
    if (activeIndex > 0 && productsRequest.data) {
      const prevIndex = activeIndex - 1;
      const prevProduct = productsRequest.data[prevIndex];
      navigate(`/newcomer-week/products/${prevProduct.erp_id}`);
    }
  }
  function getActiveCursor() {
    switch (cursorType) {
      case 'none':
        return null;

      case 'next':
        return <FontAwesomeIcon size="xl" icon={faArrowRight} />;

      case 'prev':
        return <FontAwesomeIcon size="xl" icon={faArrowLeft} />;

      case 'more':
        return 'MORE';

      default:
        return null;
    }
  }

  function handleSlideClick(index: number) {
    const {
      hideNoneActiveProducts,
      moveActiveProductToRight,
      showProductInformationSection,
      showProductSizeSection,
    } = animationSteps;
    if (
      hideNoneActiveProducts ||
      moveActiveProductToRight ||
      showProductInformationSection ||
      showProductSizeSection
    )
      return;
    if (activeIndex == index) {
      // hide Custom Cursor
      setProductDetailVisible(true);
      setIsHovering(false);
      setCursorType('none');

      // Start playing anmation
      setAnimationSteps((prevVal) => ({
        ...prevVal,
        hideNoneActiveProducts: true,
      }));
      setTimeout(() => {
        setAnimationSteps((prevVal) => ({
          ...prevVal,
          moveActiveProductToRight: true,
        }));
      }, 500);
      setTimeout(() => {
        setAnimationSteps((prevVal) => ({
          ...prevVal,
          showProductInformationSection: true,
        }));
      }, 700);
      setTimeout(() => {
        setAnimationSteps((prevVal) => ({
          ...prevVal,
          showProductSizeSection: true,
        }));
      }, 900);
    } else if (index > activeIndex) {
      slideToNextProduct();
    } else if (index < activeIndex) {
      slideToPrevProduct();
    }
  }

  function updateCursor(
    activeProduct: boolean,
    nextActiveProduct: boolean,
    prevActiveProduct: boolean
  ) {
    if (activeProduct) {
      setCursorType('more');
    } else if (nextActiveProduct) {
      setCursorType('next');
    } else if (prevActiveProduct) {
      setCursorType('prev');
    }
  }

  return (
    <>
      {viewport == 'desktop' && (
        <>
          <div
            ref={cursorRef}
            className={`${styles.CustomCursor} ${
              isHovering ? styles.IsHovering : styles.NotHovering
            }`}
          >
            {!productDetailVisible && getActiveCursor()}
          </div>
          <div
            onMouseLeave={() => {
              setIsHovering(false);
              setCursorType('none');
            }}
            onMouseMove={(e) => {
              // no mouse Tracking if product Detail is visible
              if (productDetailVisible) return;

              if (cursorRef && cursorRef.current) {
                cursorRef.current.style.left = `${
                  e.clientX - cursorRef.current.offsetWidth / 2
                }px`;
                cursorRef.current.style.top = `${
                  e.clientY - cursorRef.current.offsetHeight / 2
                }px`;
                if (!productDetailVisible && cursorType !== 'none') {
                  setIsHovering(true);
                }
              }
            }}
            className="d-flex"
            style={{
              height: '100%',
              maxWidth: '100vw',
              contain: 'paint',
              flexGrow: '1',
              cursor: productDetailVisible ? 'default' : 'none',
              backgroundColor:
                productsRequest?.data?.[activeIndex < 0 ? 0 : activeIndex]
                  ?.colorBackground,
            }}
          >
            <div
              className="d-flex"
              style={{
                transform:
                  activeIndex !== 0
                    ? `translate3d(-${(activeIndex - 1) * (100 / 3)}%, 0,0)`
                    : `translate3d(${100 / 3}%, 0,0)`,
                transition: 'all 750ms ease',
              }}
            >
              {productsRequest.data?.map((product, index) => {
                const activeProduct = index === activeIndex;
                const nonActiveProduct = index !== activeIndex;
                const nextActiveProduct = index === activeIndex + 1;
                const prevActiveProduct = index === activeIndex - 1;
                return (
                  <div
                    key={product.erp_id}
                    style={{
                      backgroundImage: `url("${product.backgroundUrl}")`,
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center',
                      minHeight: '100%',
                      minWidth: 'calc(100% / 3)',
                      position: 'relative',
                      transition: 'all 800ms',
                      transform:
                        activeProduct && animationSteps.moveActiveProductToRight
                          ? `translate3d(-100%, 0, 0)`
                          : nextActiveProduct &&
                            animationSteps.hideNoneActiveProducts
                          ? `translate3d(100% , 0, 0)`
                          : prevActiveProduct &&
                            animationSteps.hideNoneActiveProducts
                          ? `translate3d(-100%, 0, 0)`
                          : 'translate3d(0, 0, 0)',
                      zIndex: activeProduct ? 99999 : -1,
                      opacity: activeProduct
                        ? 1
                        : nextActiveProduct
                        ? 1
                        : prevActiveProduct
                        ? 1
                        : 0,
                    }}
                    className={`d-flex align-items-center justify-content-center`}
                    onMouseMove={() => {
                      if (!productDetailVisible) {
                        updateCursor(
                          activeProduct,
                          nextActiveProduct,
                          prevActiveProduct
                        );
                      }
                    }}
                    onClick={() => handleSlideClick(index)}
                  >
                    {product.discountPromotion && (
                      <div
                        className="bg-danger d-flex justify-content-center align-items-center"
                        style={{
                          color: '#eee',
                          position: 'absolute',
                          top: '10px',
                          left: '10px',
                          fontSize: '2.2rem',
                          width: '250px',
                          height: '50px',
                          padding: '10px',
                        }}
                      >
                        {product.discountPromotion}% off
                      </div>
                    )}
                    <Image
                      alt="prodcut-image"
                      src={product.product.image_transparent_url}
                      width={'100%'}
                      style={{
                        transform:
                          activeProduct && cursorType === 'more'
                            ? 'scale(1.1)'
                            : 'scale(1)',
                        transition: 'all 1s ease',
                      }}
                    />
                    {activeProduct && animationSteps.hideNoneActiveProducts && (
                      <>
                        <ProductSlidePageInformation
                          index={index}
                          ncwProduct={product}
                          viewport={viewport}
                          activeProduct={activeProduct}
                          animationSteps={animationSteps}
                        />

                        <ProductSlidePageProducts
                          animationSteps={animationSteps}
                          setAnimationSteps={setAnimationSteps}
                          setProductDetailVisible={setProductDetailVisible}
                          activeProduct={activeProduct}
                          viewport={viewport}
                          ncwProduct={product}
                        />
                      </>
                    )}
                    <div
                      className={nonActiveProduct ? styles.Overlay : ''}
                    ></div>
                  </div>
                );
              })}
            </div>
          </div>
        </>
      )}
      {viewport == 'mobile' && (
        <>
          <div>
            <div
              style={{
                overscrollBehavior: 'none',
                maxWidth: '100vw',
                contain: 'paint',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  transition: 'transform 750ms ease',
                  overscrollBehavior: 'contain',
                }}
                ref={sliderRef}
              >
                <div
                  style={{
                    display: 'flex',
                    transform: `translate3d(-${activeIndex}00vw, 0,0)`,
                    transition: 'transform 500ms ease-in-out',
                  }}
                >
                  {productsRequest.data?.map((product, index) => {
                    return (
                      <Fragment key={product.erp_id}>
                        <SlideShowSlider
                          index={index}
                          activeIndex={activeIndex}
                          ncwProduct={product}
                        />
                      </Fragment>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <Paginator
            isFirstItem={isFirstProduct}
            isLastItem={isLastProduct}
            requestData={productsRequest.data}
            slideToNextItem={slideToNextProduct}
            slideToPrevItem={slideToPrevProduct}
          />
        </>
      )}
    </>
  );
}
