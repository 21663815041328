import React from 'react';
// @ts-ignore
import style from './SortBySelectFilter.module.scss?module';

const SortBySelectFilter = ({
  onChange,
}: {
  onChange: React.ChangeEventHandler<HTMLSelectElement>;
}) => {
    return (
      <select
        defaultValue={'top'}
        onChange={onChange}
        className={style.sortBySelect}
      >
        <option value={'top'}>{'Top sellers'}</option>
        <option value={'name'}>{'Name'}</option>
        <option value={'price_low_first'}>{'Price (low to high)'}</option>
        <option value={'price_high_first'}>{'Price (high to low)'}</option>
        <option value={'new'}>{'New Products'}</option>
      </select>
    );
};

export default SortBySelectFilter;
