import React, { useState } from 'react';
import Button from '../../Button/Button';
import { getRequest, postRequest } from '../../../Helper/ApiClient';
import { useUser } from '../../../Context/UserContext';

// @ts-ignore
import styles from './LoginForm.module.scss?module';
import { Alert } from 'react-bootstrap';
import ForgotPasswordLink from '../../ForgotPasswordLink/ForgotPasswordLink';
import Link from '../../Link/Link';
import { useNavigate } from 'react-router-dom';

export default function LoginForm({ redirect }: { redirect?: string }) {
  const { fetchUser } = useUser();
  const { data: csrfToken } = getRequest('/csrf-token');
  const mutation = postRequest('/api/login');
  const [loginMessage, setLoginMessage] = useState('');
  const navigate = useNavigate();

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    data.set('_csrf_token', csrfToken);
    mutation.mutate(data, {
      onSuccess: (data: any) => {
        if (data.success) {
          fetchUser();
          if (redirect != undefined) {
            navigate(redirect);
          }
        } else {
          setLoginMessage(
            'Wrong email and password combination. please try again.'
          );
        }
      },
    });
  };

  return (
    <>
      {loginMessage.length > 0 && (
        <Alert variant="danger" dismissible onClose={() => setLoginMessage('')}>
          {loginMessage}
        </Alert>
      )}
      <form onSubmit={onSubmit}>
        <div className="my-2">
          <input
            className="form-control fw-bold text-primary"
            type="text"
            name="_username"
            placeholder="E-Mail address"
          />
        </div>
        <div className="my-2">
          <input
            className="form-control fw-bold text-primary"
            type="password"
            name="_password"
            placeholder="Password"
          />
        </div>
        <div className="my-3 d-flex align-items-center justify-content-between">
          <ForgotPasswordLink />
          <Button type="submit" loading={mutation.isLoading}>
            Login
          </Button>
        </div>
        <div className="my-2 text-end">
          <span>
            <small>
              New at Naskorsports?{' '}
              <Link href="/contact/customer" underline={true}>
                Register now
              </Link>
            </small>
          </span>
        </div>
      </form>
    </>
  );
}
