import {
  faStar,
  faCartShopping,
  faComment,
  faPhotoFilm,
  faSparkles,
  faGift,
  faAward,
} from '@fortawesome/pro-solid-svg-icons';
import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import TabButton from '../TabButton/TabButton';
import Feedback from './Feedback/Feedback';
import Highlight from './Highlight/Highlight';
import PlaceOrder from './PlaceOrder/PlaceOrder';
import MediaCenter from './MediaCenter/MediaCenter';
import Video from './Video/Video';
import NewProduct, { INewProductAttribute } from './NewProduct/NewProduct';
import Samples from './Samples/Samples';
import { IVtsExhibitor } from '../../Main/Admin/Exhibitors/Exhibitors';
//@ts-ignore
import styles from './ExhibitorPopup.module.scss?module';
import LogoImage from '../../../../Abstracts/LogoImage/LogoImage';
import Modal1 from '../../../../Abstracts/Modal1/Modal1';
import LoadingSpinner from '../../../../Abstracts/LoadingSpinner/LoadingSpinner';
import Awards from './Awards/Awards';
import AwardYearAwardCategories from '../../../../Abstracts/AwardYearAwardCategories/AwardYearAwardCategories';

export default function ExhibitorPopup({
  exhibitor,
  isOpen,
  onClose,
  exhibitor_tab,
  scrollIntoViewProductSize_Id,
}: {
  exhibitor?: IVtsExhibitor;
  isOpen: boolean;
  onClose: () => void;
  exhibitor_tab?: string;
  scrollIntoViewProductSize_Id?: number;
}) {
  const TAB_STATES = {
    VIDEO: 'VIDEO',
    HIGHLIGHT: 'HIGHLIGHT',
    ORDER: 'ORDER',
    FEEDBACK: 'FEEDBACK',
    MEDIA: 'MEDIA',
    NEW: 'NEW',
    SAMPLES: 'SAMPLES',
    AWARDS: 'AWARDS',
  };
  const [tabState, setTabState] = useState(exhibitor_tab ?? TAB_STATES.VIDEO);
  const [scrollIntoViewProductSizeId, setScrollIntoViewProductSizeId] =
    useState(scrollIntoViewProductSize_Id);
  const tabOnClick = (tab: string) => {
    setTabState(tab);
  };

  const showProductsAndScrollToProductSize = (productSizeId) => {
    setTabState(TAB_STATES.ORDER);
    setScrollIntoViewProductSizeId(productSizeId);
  };

  return (
    <Modal1
      show={isOpen}
      onHide={() => {
        onClose();
      }}
    >
      {!exhibitor ? (
        <div className={'py-5 text-center'}>
          <LoadingSpinner />
        </div>
      ) : (
        <div className={styles.ExhibitorPopup}>
          <Row>
            <Col>
              {exhibitor.organiser ? (
                <div onClick={() => tabOnClick(TAB_STATES.VIDEO)}>
                  <LogoImage noNavigation height={'30px'} className={'my-3'} />
                </div>
              ) : (
                <img
                  src={exhibitor.logo_image_url}
                  alt={exhibitor.name}
                  onClick={() => tabOnClick(TAB_STATES.VIDEO)}
                />
              )}
            </Col>
          </Row>
          <Row className={'g-1'}>
            {exhibitor.organiser ? (
              <>
                <Col style={{ maxWidth: '30%' }}>
                  <TabButton
                    active={tabState === TAB_STATES.FEEDBACK}
                    onClick={() => tabOnClick(TAB_STATES.FEEDBACK)}
                    text={'Give feedback'}
                    icon={faComment}
                  />
                </Col>
                <Col style={{ maxWidth: '30%' }}>
                  <TabButton
                    active={tabState === TAB_STATES.AWARDS}
                    onClick={() => tabOnClick(TAB_STATES.AWARDS)}
                    text={'NaskorSports Awards'}
                    icon={faAward}
                  />
                </Col>
              </>
            ) : (
              <>
                {exhibitor.has_highlights && (
                  <Col xs={4} lg={2}>
                    <TabButton
                      active={tabState === TAB_STATES.HIGHLIGHT}
                      onClick={() => tabOnClick(TAB_STATES.HIGHLIGHT)}
                      text={'Highlights'}
                      icon={faStar}
                    />
                  </Col>
                )}
                {exhibitor.has_new_product_attributes && (
                  <Col xs={4} lg={2}>
                    <TabButton
                      active={tabState === TAB_STATES.NEW}
                      onClick={() => tabOnClick(TAB_STATES.NEW)}
                      text={'New products'}
                      icon={faSparkles}
                    />
                  </Col>
                )}
                <Col xs={4} lg={2}>
                  <TabButton
                    active={tabState === TAB_STATES.ORDER}
                    onClick={() => tabOnClick(TAB_STATES.ORDER)}
                    text={'Place order'}
                    icon={faCartShopping}
                  />
                </Col>
                {exhibitor.has_samples && (
                  <Col xs={4} lg={2}>
                    <TabButton
                      active={tabState === TAB_STATES.SAMPLES}
                      onClick={() => tabOnClick(TAB_STATES.SAMPLES)}
                      text={'Free samples'}
                      icon={faGift}
                    />
                  </Col>
                )}
                <Col xs={4} lg={2}>
                  <TabButton
                    active={tabState === TAB_STATES.FEEDBACK}
                    onClick={() => tabOnClick(TAB_STATES.FEEDBACK)}
                    text={'Give feedback'}
                    icon={faComment}
                  />
                </Col>
                {exhibitor.has_media_files && (
                  <Col xs={4} lg={2}>
                    <TabButton
                      active={tabState === TAB_STATES.MEDIA}
                      onClick={() => tabOnClick(TAB_STATES.MEDIA)}
                      text={'Media center'}
                      icon={faPhotoFilm}
                    />
                  </Col>
                )}
              </>
            )}
          </Row>
          <Row className="mt-3">
            <Col>
              {tabState == TAB_STATES.VIDEO && (
                <>
                  <Video exhibitor={exhibitor} />
                </>
              )}
              {tabState == TAB_STATES.HIGHLIGHT && (
                <>
                  <Highlight exhibitor={exhibitor} />
                </>
              )}
              {tabState == TAB_STATES.NEW && (
                <>
                  <NewProduct
                    exhibitor={exhibitor}
                    showProductsAndScrollToProductSize={
                      showProductsAndScrollToProductSize
                    }
                  />
                </>
              )}
              {tabState == TAB_STATES.ORDER && (
                <>
                  <PlaceOrder
                    exhibitor={exhibitor}
                    scrollIntoViewProductSizeId={scrollIntoViewProductSizeId}
                  />
                </>
              )}
              {tabState == TAB_STATES.FEEDBACK && (
                <>
                  <Feedback exhibitor={exhibitor} />
                </>
              )}
              {tabState == TAB_STATES.MEDIA && (
                <>
                  <MediaCenter exhibitor={exhibitor} />
                </>
              )}
              {tabState == TAB_STATES.SAMPLES && (
                <>
                  <Samples exhibitor={exhibitor} />
                </>
              )}
            </Col>
          </Row>
          {tabState == TAB_STATES.AWARDS && <AwardYearAwardCategories />}
        </div>
      )}
    </Modal1>
  );
}
