import React, { useState, useEffect } from 'react';
import { INewComerWeekProduct } from '../../../../ProductsSlideshow/ProductsSlideshow';
import { ViewportType } from '../../../../../../../Hooks/useViewport';
import { AnimationStepsTypes } from '../../../ProductPages';
import ProductInformationContent from './ProductInformationContent/ProductInformationContent';
// import SnapSection from '../../../../../Abstracts/SnapSection/SnapSection';
import { Col, Row } from 'react-bootstrap';

//@ts-ignore
// import styles from './ProductSlidePageInformation.module.scss?module';

const ProductSlidePageInformation = ({
  ncwProduct,
  viewport,
  activeProduct,
  animationSteps,
  setIsUserOnPageInformationSection,
  index,
}: {
  ncwProduct: INewComerWeekProduct;
  viewport?: ViewportType;
  activeProduct?: boolean;
  animationSteps?: AnimationStepsTypes;
  setIsUserOnPageInformationSection?: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  index: number;
}) => {
  const [setRef, inView] = useInView({
    threshold: [1, 0.001, 1],
  });

  useEffect(() => {
    if (inView && setIsUserOnPageInformationSection) {
      setIsUserOnPageInformationSection(true);
    } else if (setIsUserOnPageInformationSection) {
      setIsUserOnPageInformationSection(false);
    }
  }, [inView, setIsUserOnPageInformationSection]);

  return (
    <>
      {viewport === 'desktop' ? (
        <div
          className={'CustomScrollbar'}
          style={{
            display: 'flex',
            height: 'inherit',
            backgroundColor: ncwProduct.colorBackground || 'black',
            minHeight: '100%',
            minWidth: '100%',
            maxHeight: '100%',
            overflow: 'auto',
            position: 'absolute',
            transition: 'all 1s',
            transform:
              activeProduct &&
              animationSteps &&
              animationSteps.showProductInformationSection
                ? 'translate3d(100%, 0, 0)'
                : 'translate3d(100%, 100%, 0)',
            opacity:
              activeProduct &&
              animationSteps &&
              animationSteps.showProductInformationSection
                ? 1
                : 0,
          }}
        >
          <div
            style={{
              // fontFamily: 'newcomer-week',
              color: ncwProduct.colorText || 'white',
              fontSize: '1rem',
              width: '100%',
              padding: '2.5rem',
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem',
            }}
          >
            <ProductInformationContent ncwProduct={ncwProduct} isDesktop />
          </div>
        </div>
      ) : (
        <Row
          id={`container-${index}`}
          ref={setRef}
          style={{
            minHeight: 'calc(100vh - 112px)',
            // scrollSnapStop: 'always',
            scrollSnapAlign: 'start',
          }}
        >
          <Col>
            <div style={{ columns: '450px' }}>
              <ProductInformationContent ncwProduct={ncwProduct} />
            </div>
          </Col>
        </Row>
      )}
    </>
  );
};

export default ProductSlidePageInformation;


const useInView = (options = {}) => {
  const [ref, setRef] = useState<HTMLElement | null>(null);
  const [inView, setInView] = useState(false);

  useEffect(() => {
    if (!ref) return;

    const observer = new IntersectionObserver(([entry]) => {
      setInView(entry.isIntersecting);
    }, options);

    observer.observe(ref);

    return () => {
      observer.disconnect();
    };
  }, [ref, options]);

  return [setRef, inView] as const;
};