import React from 'react';

// @ts-ignore
import styles from './Faq.module.scss?module';
import { Accordion, Col, Row } from 'react-bootstrap';
import { useUser } from '../../../../../Context/UserContext';

export default function Faq() {
  const userContext = useUser();

  return (
    <>
      <Row className={'pb-2'}>
        <Col>
          <h2>Frequently Asked Questions</h2>
        </Col>
      </Row>
      <Row>
        <Col xl={8}>
          <Accordion className={styles.Accordion}>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Opening hours</Accordion.Header>
              <Accordion.Body>
                Start: Thursday, May 02, 2024 9:00 a.m.
                <br />
                End: Sunday, May 05, 2024 11:59 p.m.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Login</Accordion.Header>
              <Accordion.Body>
                Please make sure to be always logged in with your Naskorsports
                user account to have full access to all features.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                How can I navigate through the Virtual Trade Show?
              </Accordion.Header>
              <Accordion.Body>
                Use the bottom toolbar to visit the different locations:
                <ul>
                  <li>Lobby</li>
                  <li>Exhibition</li>
                  <li>Information</li>
                </ul>
                Click on the button to go to the location you want to visit. In
                the Exhibition Hall, click on the highlighted circles on the
                ground to navigate. If you want to visit a brand, click on the
                booth. Click on the highlighted circles in front of the booth
                counter to use the features of the brand booth.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                What do the top menu buttons show?
              </Accordion.Header>
              <Accordion.Body>
                <ul>
                  <li>
                    <strong>Exhibitors:</strong> See all brands – click on a
                    brand to go directly to its booth.
                  </li>
                  <li>
                    <strong>Order overview:</strong> See all ordered products
                    and samples at a glance.
                  </li>
                  <li>
                    <strong>Account:</strong> Login with your Naskorsports
                    credentials / Review your interaction points (attractive
                    prizes are waiting for you)
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>What can I do in the lobby? </Accordion.Header>
              <Accordion.Body>
                You can enter the Exhibition Hall or click on the information.
                Click on the entrance area or use the buttons in your bottom
                menu.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>
                How can I enter the Exhibition Area?{' '}
              </Accordion.Header>
              <Accordion.Body>
                Click on the entrance area or click on the button “Exhibition”
                in your bottom menu. You need to be logged in with your
                NaskorSports account to enter the Area.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
              <Accordion.Header>
                What can I do in the Exhibition Area?{' '}
              </Accordion.Header>
              <Accordion.Body>
                You can visit all brands at their booths and use the following
                features:
                <ul>
                  <li>
                    <strong>Highlights:</strong> Check out current brand
                    highlights and be up to date about key products.
                  </li>
                  <li>
                    <strong>New products:</strong> Get informed about the latest
                    product launches of your favourite brands. Don´t miss out
                    the newest stuff!
                  </li>
                  <li>
                    <strong>Place order:</strong> Click on the booth counter and
                    select “place order”. Now you can enter your desired
                    quantities into the product list. Don´t forget to add the
                    desired quantities with the “ADD TO ORDER” button or “ADD
                    ALL TO ORDER” BUTTON.
                    <br />
                    You can place and adjust your order between May 02 and May
                    05, 2024 until 23:59. For an overview of your orders, click
                    “Order overview” in the top right menu.
                    <br />
                    On Sunday, May 05, at 23:59, all placed orders from your
                    “Order overview” will be processed to your overall Virtual
                    Trade Show order in our your Naskorsports account details on
                    https://naskorsports.com. Please adjust and confirm your
                    order until Monday, May 13 at 23:59.
                  </li>
                  <li>
                    <strong>Free samples:</strong> Click on the booth counter
                    and select “free samples”. Choose your samples and confirm
                    with the “Add to order” button at the bottom of the list.
                    See all collected samples in your order overview.
                    <br />
                    <strong>Please note:</strong> Sample stock is limited –
                    first come, first serve. Please check your order overview in
                    the top right menu and the sample feature at the brand booth
                    for available quantities. Please note that you cannot change
                    your samples after the end of the Virtual Trade Show. The
                    delivery of the selected samples depends on availability and
                    cannot be guaranteed. Samples will be delivered with your
                    Virtual Trade Show shipment(s).
                  </li>
                  <li>
                    <strong>Give feedback:</strong> Click on the booth counter
                    and select “give feedback”. Answer the questions giving 1 to
                    4 star ratings (1 = very bad, 4 = very good). Answer all
                    questions to submit your feedback.
                  </li>
                  <li>
                    <strong>Media center:</strong> Click on the booth counter
                    and select “media center”. Watch and download content from
                    different categories. Download a “Complete media kit” for
                    your own marketing activities.
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="7">
              <Accordion.Header>Naskorsports Booth</Accordion.Header>
              <Accordion.Body>
                Visit us at our Naskorsports Booth in the Hall B2B! Click on the
                counter to watch our latest image video and give us your
                valuable feedback.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="8">
              <Accordion.Header>Need more help?</Accordion.Header>
              <Accordion.Body>
                If you have any questions, we are happy to help. Please contact
                us:
                <ul>
                  <li>Contact us via our chat in the bottom right corner.</li>
                  <li>
                    Call our customer service: +31 777 201 200 (May 02-05, 2024,
                    every day from 8:00 am to 5:30 pm.)
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="9">
              <Accordion.Header>When can I confirm my order?</Accordion.Header>
              <Accordion.Body>
                The order confirmation period is from Monday, May 06, 2024 12
                p.m. until Monday, May 13, 2024 11:59 p.m.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="10">
              <Accordion.Header>
                What do I need to do during the confirmation period?
              </Accordion.Header>
              <Accordion.Body>
                The Virtual Trade Show is closed. Now you need to confirm your
                order, which you placed during the event, in your Naskorsports
                account details on https://naskorsports.com. For brands, where a
                minimum of one item was ordered during the Virtual Trade Show,
                you can add or adjust items from the entire product portfolio of
                this brand to your final Virtual Trade Show order until Monday,
                May 13, 2024 at 23:59.
              </Accordion.Body>
            </Accordion.Item>

            {userContext.user && !userContext.user.is_exhibitor && (
              <Accordion.Item eventKey="11">
                <Accordion.Header>
                  How can I release my Virtual Trade Show order?
                </Accordion.Header>
                <Accordion.Body>
                  After you confirmed your order, you can retrieve the entire
                  order or parts of the order in your Naskorsports account
                  details on https://naskorsports.com.
                  <br />
                  Until the 30th of June 2024 you can retrieve the ordered
                  products in several partial releases. These shipments will be
                  invoiced accordingly.
                  <br />
                  Products that are not in stock when you want to retrieve them,
                  can be retrieved directly after we receive them. So, stay
                  tuned for the expected time of arrival in the service center.
                  <br />
                  After you released an order, you will receive your order
                  confirmation as usual.
                </Accordion.Body>
              </Accordion.Item>
            )}
          </Accordion>
        </Col>
      </Row>
    </>
  );
}
