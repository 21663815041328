import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Table from '../../../Abstracts/TableElements/Table/Table';
import { getRequest, postRequest } from '../../../Helper/ApiClient';
import LoadingSpinner from '../../../Abstracts/LoadingSpinner/LoadingSpinner';

export default function Discontinued() {
  const discontinuedProductsRequest = getRequest(
    '/api/onlineshop/discontinued-product-attributes'
  );

  const visitPageMutation = postRequest('/api/onlineshop/visit-page');

  useEffect(() => {
    let formData = new FormData();
    formData.append('page', 'discontinued');
    visitPageMutation.mutate(formData);
  }, []);

  return (
    <Container>
      <Row className="pt-5">
        <Col>
          <h1>Discontinued</h1>
          <p>This is an overview of all recently discontinued products.</p>
        </Col>
      </Row>
      <Row>
        <Col>
          {discontinuedProductsRequest.isLoading ? (
            <div className={'py-5 text-center'}>
              <LoadingSpinner />
            </div>
          ) : (
            <>
              <div className="d-none d-md-block">
                <Table>
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Brand</th>
                      <th>Product</th>
                      <th>Flavour</th>
                    </tr>
                  </thead>
                  <tbody>
                    {discontinuedProductsRequest.isSuccess &&
                      discontinuedProductsRequest.data &&
                      discontinuedProductsRequest.data.length > 0 &&
                      discontinuedProductsRequest.data.map(
                        (discontinuedProduct: any, index: number) => {
                          return (
                            <tr key={index}>
                              <td>{discontinuedProduct.discontinued}</td>
                              <td>{discontinuedProduct.brand_name}</td>
                              <td>{discontinuedProduct.product_size_name}</td>
                              <td>
                                {discontinuedProduct.product_attribute_name}
                              </td>
                            </tr>
                          );
                        }
                      )}

                    {discontinuedProductsRequest.isSuccess &&
                      discontinuedProductsRequest.data.length < 1 && (
                        <tr>
                          <td colSpan={5} className="p-5 text-center">
                            <i>No results.</i>
                          </td>
                        </tr>
                      )}
                  </tbody>
                </Table>
              </div>
              <div className="d-sm-block d-md-none">
                <Table>
                  <tbody>
                    {discontinuedProductsRequest.isSuccess &&
                      discontinuedProductsRequest.data &&
                      discontinuedProductsRequest.data.length > 0 &&
                      discontinuedProductsRequest.data.map(
                        (discontinuedProduct: any, index: number) => {
                          return (
                            <tr key={index}>
                              <td>
                                <div
                                  style={{ color: 'darkgray' }}
                                  className={'fst-italic'}
                                >
                                  {discontinuedProduct.discontinued}
                                </div>
                                <div className={'fw-bold'}>
                                  {discontinuedProduct.brand_name}
                                </div>
                                <div>
                                  {discontinuedProduct.product_size_name}
                                </div>
                                <div>
                                  {discontinuedProduct.product_attribute_name}
                                </div>
                              </td>
                            </tr>
                          );
                        }
                      )}
                  </tbody>
                </Table>
              </div>
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
}
