import React, { useState } from 'react';
import MenuItem from '../MenuItem/MenuItem';
import MegaMenuCategories from './MegaMenuCategories/MegaMenuCategories';

const MenuCategories = () => {
  const [showMenu, setShowMenu] = useState(false);
  return (
    <MenuItem
      text="Categories"
      onMouseEnter={() => setShowMenu(true)}
      onMouseLeave={() => setShowMenu(false)}
    >
      <MegaMenuCategories show={showMenu} setShowMenu={setShowMenu} />
    </MenuItem>
  );
};

export default MenuCategories;
