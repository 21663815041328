import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
// @ts-ignore
import style from './IconLink.module.scss?module';

interface Props {
  icon: IconProp;
  link?: string;
  onClick?: () => any;
}

const IconLink = ({ icon, link, onClick }: Props) => {
  return (
    <a
      className={['d-flex flex-column justify-content-center', style.iconLink]
        .join(' ')
        .trim()}
      href={link}
      onClick={onClick}
    >
      <FontAwesomeIcon className={style.iconLinkIcon} icon={icon} />
    </a>
  );
};

export default IconLink;
