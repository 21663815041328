import * as React from 'react';
import { ISampleProductSize } from '../Samples';
import { Col, Row } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import SampleProductAttribute from './SampleProductAttribute/SampleProductAttribute';
import ButtonMutation from '../../../../../../Abstracts/ButtonMutation/ButtonMutation';
import { postRequest } from '../../../../../../Helper/ApiClient';

export interface IVtsBasketSamplePosition {
  sample_product_attribute_id: number;
  quantity: number;
}

export default function SampleProductList(props: {
  sampleProducts: ISampleProductSize[];
  afterAddPositions: () => void;
}) {
  const addToBasketMutation = postRequest(
    '/api/onlineshop/vts-basket-add-sample-position'
  );
  const [samplePositions, setSamplePositions] = useState<
    IVtsBasketSamplePosition[]
  >([]);
  const [resetInputValue, setResetInputValue] = useState<boolean>(false);

  const handleSampleOnChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const _sample_product_attribute_id = Number(
      ev.currentTarget.dataset.sample_product_attribute_id
    );
    const _qty = Number(ev.currentTarget.value);

    // console.log(_sample_product_attribute_id, _qty);

    const _samplePositions = [...samplePositions];
    const foundPosition = _samplePositions.find(
      (position) =>
        position.sample_product_attribute_id === _sample_product_attribute_id
    );
    if (_sample_product_attribute_id && _qty) {
      if (foundPosition) {
        foundPosition.quantity = _qty;
        setSamplePositions(_samplePositions);
      } else {
        const currentPositions = [...samplePositions];
        currentPositions.push({
          sample_product_attribute_id: _sample_product_attribute_id,
          quantity: _qty,
        });
        setSamplePositions(currentPositions);
      }
    }
  };

  const handleClickAddToBasket = () => {
    let formData = new FormData();
    formData.append('positionsJson', JSON.stringify(samplePositions));
    addToBasketMutation.mutate(formData, {
      onSuccess: () => {
        setSamplePositions([]);
        setResetInputValue(true);
        props.afterAddPositions();
      },
    });
  };

  useEffect(() => {
    // console.log(samplePositions);
  }, [samplePositions]);

  return (
    <>
      {props.sampleProducts.map((sampleProduct) => {
        return (
          <Row key={sampleProduct.id}>
            <Col md={6} className={'pb-3 pb-md-0 fw-bold'}>
              {sampleProduct.name}
            </Col>
            <Col md={6}>
              {sampleProduct.sample_product_attributes.length > 0 &&
                sampleProduct.sample_product_attributes.map(
                  (sample_product_attribute) => {
                    return (
                      <SampleProductAttribute
                        reset={resetInputValue}
                        onChange={handleSampleOnChange}
                        key={sample_product_attribute.id}
                        sample_product_attribute={sample_product_attribute}
                      />
                    );
                  }
                )}
            </Col>
            <Col xs={12}>
              <hr />
            </Col>
          </Row>
        );
      })}
      <Row>
        <Col className="text-end">
          {addToBasketMutation.isError && (
            <div
              style={{ display: 'inline-block' }}
              className={'text-danger me-3'}
            >
              {addToBasketMutation.error?.message.length > 0 &&
                addToBasketMutation.error.message}
            </div>
          )}
          <ButtonMutation
            mutationResult={addToBasketMutation}
            onClick={handleClickAddToBasket}
          >
            Add to order
          </ButtonMutation>
        </Col>
      </Row>
    </>
  );
}
