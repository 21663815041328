import * as React from 'react';
// @ts-ignore
import styles from './.module.scss?module';
import Popup from '../Popup/Popup';
import { FormControl } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import ButtonMutation from '../ButtonMutation/ButtonMutation';
import { postRequest } from '../../Helper/ApiClient';
import { useRef } from 'react';
import Link from '../Link/Link';
export default function ForgotPasswordLink({
  onPopupOpen,
}: {
  onPopupOpen?: CallableFunction;
}) {
  const inputRef = useRef<HTMLInputElement>(null);
  const forgetPasswordMutation = postRequest('/lost-password');
  const [popup, setPopup] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const handleClick = () => {
    setPopup(!popup);
  };

  const handleForgetPasswordSend = (
    ev: React.MouseEvent<HTMLButtonElement>
  ) => {
    let formData = new FormData();
    if (inputRef.current) {
      formData.append('_username', inputRef.current.value);
    }
    forgetPasswordMutation.mutate(formData, {
      onSuccess: (data: any) => {
        if (data.success) {
          setTimeout(() => {
            setEmailSent(true);
          }, 1000);
        }
      },
    });
  };

  useEffect(() => {
    if (popup) {
      if (onPopupOpen) {
        onPopupOpen();
        setEmailSent(false);
      }
    }
  }, [popup]);

  return (
    <>
      <small>
        <Link onClick={handleClick} underline={true}>
          Forgot your password?
        </Link>
      </small>
      <Popup
        centered
        show={popup}
        onHide={() => {
          setPopup(false);
        }}
      >
        <div className={'p-5'}>
          <h2
            style={{ fontWeight: '900', fontStyle: 'italic' }}
            className={'pb-3'}
          >
            Password Assistance
          </h2>
          {!emailSent ? (
            <>
              <p>
                Enter the E-Mail associated with your NaskorSports.com account.
                You will receive your password by E-Mail.
              </p>
              <div className="d-flex">
                <FormControl
                  ref={inputRef}
                  name="_username"
                  type="email"
                  placeholder="your@email.com"
                />
                <ButtonMutation
                  mutationResult={forgetPasswordMutation}
                  onClick={handleForgetPasswordSend}
                >
                  Send
                </ButtonMutation>
              </div>
              <p>
                If you forgot your E-Mail address, please contact our customer
                service via info@naskorsports.com
              </p>
            </>
          ) : (
            <p>A new password was sent to your E-Mail address</p>
          )}
        </div>
      </Popup>
    </>
  );
}
