import React from 'react';
import ReactCountdown from 'react-countdown';

// @ts-ignore
import styles from './Countdown.module.scss?module';

export default function Countdown(props: { dateString: string }) {
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
    } else {
      // Render a countdown
      return (
        <div className={styles.Countdown}>
          <div>
            {days}
            <span>D</span>
          </div>
          <div className={'pl-3'}>
            {hours.toString().padStart(2, '0')}
            <span>H</span>
          </div>
          <div className={'pl-3'}>
            {minutes.toString().padStart(2, '0')}
            <span>M</span>
          </div>
          <div className={'pl-3'}>
            {seconds.toString().padStart(2, '0')}
            <span>S</span>
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <ReactCountdown date={Date.parse(props.dateString)} renderer={renderer} />
    </>
  );
}
