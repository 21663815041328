import React from 'react';
import { Image } from 'react-bootstrap';
// @ts-ignore
import LogoBlue from './logo-vts-blue.svg';
// @ts-ignore
import LogoWhite from './logo-vts-white.svg';
import { NavLink } from 'react-router-dom';

export default function LogoImage(props: {
  className?: string;
  white?: boolean;
  height?: string;
}) {
  return (
    <div
      className={[props.className].join(' ')}
      style={props.height ? { height: props.height } : {}}
    >
      {/*Todo: Link to instead of a href after all routes are SPA */}
      <NavLink
        to={'/virtual-trade-show'}
        className={['inherit', props.height ? 'h-100' : 'w-100'].join(' ')}
      >
        <Image
          src={props.white ? LogoWhite : LogoBlue}
          className={[props.height ? 'h-100' : 'w-100'].join(' ')}
        />
      </NavLink>
    </div>
  );
}
