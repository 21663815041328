import * as React from 'react';
import Button, { ButtonProps } from '../Button/Button';
import { UseMutationResult, UseQueryResult } from 'react-query';
import { useEffect, useState } from 'react';

interface Props extends ButtonProps {
  mutationResult?: UseMutationResult<any, any, any, any>;
  queryResult?: UseQueryResult<any, any>;
}

const ButtonMutation: React.FC<Props> = ({
  mutationResult,
  queryResult,
  children,
  ...props
}) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (mutationResult) {
      setLoading(mutationResult.isLoading);
      setSuccess(mutationResult.isSuccess);
    }
    if (queryResult) {
      setLoading(queryResult.isFetching);
      setSuccess(queryResult.isSuccess && queryResult.isFetched && !queryResult.isFetching);
    }
  }, [mutationResult, queryResult]);

  return (
    <Button {...props} loading={loading} success={success}>
      {children}
    </Button>
  );
};

export default ButtonMutation;
