import * as React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

export default function VtsBasketConfirmed() {
  return (
    <Container>
      <Row>
        <Col>
          Thanks for your confirmation. You can now release your order in the <a href="https://naskorsports.com/vts-order-release">Service Center</a>. Several partial releases are possible and will be invoiced accordingly.<br/>
          Final order release is 30th of June 2024.<br/>
          In regards to product availability, we advise you to be fast: FIRST COME FIRST SERVE
        </Col>
      </Row>
    </Container>
  );
}
