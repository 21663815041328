import React from 'react';
import { getRequest } from '../../Helper/ApiClient';
import {
  IAward,
  IAwardYearCategory,
  IBrand,
  IProductSize,
} from '../../../Interface/Award';
import { Col, Row } from 'react-bootstrap';
import AwardBrand from './AwardBrand/AwardBrand';
import AwardProduct from './AwardProduct/AwardProduct';

export default function AwardYearAwardCategories({
  onParticipantClick,
  userVotes,
}: {
  onParticipantClick?: (categoryId: number, participantId: number) => void;
  userVotes?: { category_id: number; participant_id: number | string }[];
}) {
  const awardsRequest = getRequest<IAward>(`/api/awards`);
  const isTimeToShowWinner = (from: string, to: string) => {
    const fromDate = new Date(from);
    const toDate = new Date(to);

    const currentDate = new Date();
    fromDate.setHours(0, 0, 0, 0);
    toDate.setHours(23, 59, 59, 999);
    currentDate.setHours(0, 0, 0, 0);

    return currentDate >= fromDate && currentDate <= toDate;
  };

  const checkingForWinner = (
    awards: IAward,
    winner_id: number | null,
    participant_id: number
  ) => {
    if (isTimeToShowWinner(awards.winnerStart, awards.visibleEnd)) {
      if (winner_id !== null) {
        if (winner_id === participant_id) {
          return true;
        } else {
          return false;
        }
      }
    }
    return undefined;
  };
  return (
    <>
      {awardsRequest.data?.awardYearCategories.map(
        (awardCategoryAwardYear: IAwardYearCategory, key: number) => {
          return (
            <div key={key}>
              <Row className={'pt-5'}>
                <Col>
                  <h3 className={'fw-bold fst-italic'}>
                    {awardCategoryAwardYear?.award_category.name}
                  </h3>
                </Col>
              </Row>
              <Row className={'pt-2 pb-5'} xs={2} md={3} lg={5}>
                {awardCategoryAwardYear?.award_brands?.map(
                  (award_brand: IBrand) => {
                    return (
                      <AwardBrand
                        isWinner={checkingForWinner(
                          awardsRequest.data,
                          awardCategoryAwardYear.winner_participant_id,
                          award_brand.id
                        )}
                        key={award_brand.id}
                        awardBrand={award_brand}
                        votingActive={awardsRequest.data.voting_active}
                        selected={
                          !isTimeToShowWinner(
                            awardsRequest.data.winnerStart,
                            awardsRequest.data.visibleEnd
                          ) &&
                          (!!userVotes?.find(
                            (item) =>
                              item.category_id ==
                                awardCategoryAwardYear.award_category.id &&
                              item.participant_id == award_brand.id
                          ) ||
                            !!award_brand.user_voted)
                        }
                        onClick={() => {
                          if (onParticipantClick) {
                            onParticipantClick(
                              awardCategoryAwardYear.award_category.id,
                              award_brand.id
                            );
                          }
                        }}
                      />
                    );
                  }
                )}
                {awardCategoryAwardYear.award_products?.map(
                  (award_product: IProductSize, key: number) => {
                    return (
                      <AwardProduct
                        isWinner={checkingForWinner(
                          awardsRequest.data,
                          awardCategoryAwardYear.winner_participant_id,
                          award_product.id
                        )}
                        key={key}
                        awardProduct={award_product}
                        votingActive={awardsRequest.data.voting_active}
                        selected={
                          !isTimeToShowWinner(
                            awardsRequest.data.winnerStart,
                            awardsRequest.data.visibleEnd
                          ) &&
                          (!!userVotes?.find(
                            (item) =>
                              item.category_id ==
                                awardCategoryAwardYear.award_category.id &&
                              item.participant_id == award_product.id
                          ) ||
                            !!award_product.user_voted)
                        }
                        onClick={() => {
                          if (onParticipantClick) {
                            onParticipantClick(
                              awardCategoryAwardYear.award_category.id,
                              award_product.id
                            );
                          }
                        }}
                      />
                    );
                  }
                )}
              </Row>
            </div>
          );
        }
      )}
    </>
  );
}
