import * as React from 'react';
import NutritionFacts from '../../ProductList/Products/Product/ProductAttributes/ProductAttribute/NutritionFacts/NutritionFacts';
import { Col, FormControl, Row } from 'react-bootstrap';
import { IVtsProductAttribute } from '../VtsProductList';
import { useEffect } from 'react';

export default function ProductAttribute({
  price,
  product_attribute,
  onChange,
  reset,
}: {
  price: number;
  product_attribute: IVtsProductAttribute;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  reset: boolean;
}) {
  const inputRef = React.useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (reset) {
      if (inputRef.current) {
        inputRef.current.value = '';
      }
    }
  }, [reset]);
  return (
    <Row className="mb-2" key={product_attribute.id}>
      <Col className="pe-0">
        <div
          className="d-flex align-items-center justify-content-between h-100 px-2"
          style={{ backgroundColor: '#e1e3e7' }}
        >
          <span className="fw-bold">{product_attribute.name}</span>
          {product_attribute.has_nutrition_facts && (
            <NutritionFacts product_attribute_id={product_attribute.id} />
          )}
        </div>
      </Col>
      <Col md={2} xs={4} className="ps-0">
        <FormControl
          ref={inputRef}
          className="text-center h-100"
          min={1}
          type={"text"}
          inputMode={"numeric"}
          onChange={onChange}
          data-product_attribute_id={product_attribute.id}
          data-price={price}
          placeholder={'-'}
        />
      </Col>
    </Row>
  );
}
