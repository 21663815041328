import React from 'react';
import { Image } from 'react-bootstrap';
// @ts-ignore
import style from './SearchResult.module.scss?module';

export interface Brand {
  id: number;
  name: string;
}

export interface Product {
  id: number;
  name: string;
  image_uri: string;
  image_overlay_uri: string | null;
  unit: string;
  unit_value: string;
  brand: Brand;
}

interface Props {
  brands: Brand[] | null;
  products: Product[] | null;
}

const SearchResult = ({ brands, products }: Props) => {
  return (
    <div className={[style.searchResult, ''].join(' ')}>
      <div className={style.searchResultBrands}>
        <h2 className="fs-6 px-4 py-3 text-black">Brands</h2>
        <ul className="px-4">
          {brands && brands.length > 0 ? (
            brands.map(({ id, name }) => (
              <li key={id}>
                <a href={`/brand/${id}`}>{name}</a>
              </li>
            ))
          ) : (
            <li>No results for your search</li>
          )}
        </ul>
      </div>
      <div className={style.searchResultProducts}>
        <h2 className="fs-6 px-4 py-3 text-black">Products</h2>
        <ul className="row px-4">
          {products && products.length > 0 ? (
            products.map(
              ({ id, brand, name, image_uri, image_overlay_uri }) => (
                <li key={id} className="col-12 col-lg-6 my-2 my-lg-1">
                  <a
                    href={`/productSize/${id}`}
                    className="d-flex align-items-center"
                  >
                    <div
                      className={[
                        'position-relative',
                        style.imageContainer,
                      ].join(' ')}
                    >
                      <Image fluid src={image_uri} alt={name} />
                      {image_overlay_uri && (
                        <Image
                          fluid
                          className="position-absolute end-0"
                          src={image_overlay_uri}
                        />
                      )}
                    </div>

                    <div
                      className={['ps-0 ps-lg-3', style.textContainer].join(
                        ' '
                      )}
                    >
                      <strong className="me-1">{brand.name}</strong>
                      <div>{name}</div>
                    </div>
                  </a>
                </li>
              )
            )
          ) : (
            <li>No results for your search</li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default SearchResult;
