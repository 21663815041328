import React from 'react';

// @ts-ignore
import styles from './Home.module.scss?module';
import { Col, Container, Image, Row } from 'react-bootstrap';
import Link from '../../../Abstracts/Link/Link';
import HomeLandingPage from './HomeLandingPage/HomeLandingPage';
import { useUser } from '../../../Context/UserContext';
import NcwLogin from './NcwLogin/NcwLogin';

export default function Home() {
  const { user, loading } = useUser();

  return (
    <>
      {!user ? (
        <NcwLogin />
      ) : (
        <Container
          fluid
          className="d-flex flex-column gap-4 position-relative p-0"
        >
          {loading ? <></> : <HomeLandingPage />}

          <Row aria-label="spacer" />
        </Container>
      )}
    </>
  );
}
