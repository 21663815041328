import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import debounce from '../../../Helper/debounce';
// @ts-ignore
import style from './Search.module.scss?module';
import { getRequest } from '../../../Helper/ApiClient';
import SearchResult, { Brand, Product } from './SearchResult/SearchResult';

const Search = () => {
  const searchRef = useRef<HTMLDivElement>(null);
  const [query, setQuery] = useState<string>();
  const [active, setActive] = useState<boolean>(false);
  const { data } = getRequest<{ brands: Brand[]; products: Product[] }>(
    `/api/search/${encodeURIComponent(query ?? '')}`,
    `/api/search/${encodeURIComponent(query ?? '')}`,
    {
      enabled: Boolean(active && query && query.length > 1),
    }
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => document.removeEventListener('mousedown', handleClick);
  }, []);

  const handleClick = (event: MouseEvent) => {
    if (
      searchRef.current &&
      !searchRef?.current.contains(event.target as Node)
    ) {
      setActive(false);
    }
  };

  const handleInput = useCallback(
    debounce((value: string) => {
      setQuery(value);
    }, 500),
    []
  );

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      navigateToResults(event.currentTarget.value);
    }
  };

  const navigateToResults = (queryStr?: string) => {
    let _query = queryStr;
    if (query) {
      _query = query;
    }
    window.location.href = `/search${
      _query && _query.length > 1 ? `/${encodeURIComponent(_query)}` : ''
    }`;
  };

  return (
    <div
      ref={searchRef}
      className={[style.search, active ? style.active : ''].join(' ')}
    >
      <div style={{ position: 'relative' }}>
        <input
          type="text"
          placeholder="Search"
          onFocus={() => setActive(true)}
          onChange={(event) => handleInput(event.currentTarget.value)}
          onKeyDown={handleKeyPress}
          className={style.searchInput}
        />
        <span
          className={style.searchInputIcon}
          onClick={() => navigateToResults()}
        >
          <FontAwesomeIcon icon={faSearch} />
        </span>
      </div>
      {active && data && <SearchResult {...data} />}
    </div>
  );
};

export default Search;
