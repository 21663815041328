import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import SortBySelectFilter from './SortBySelectFilter/SortBySelectFilter';
import ProductSize from './ProductSize/ProductSize';
// @ts-ignore
import styles from './VtsProductList.module.scss?module';
import ButtonMutation from '../ButtonMutation/ButtonMutation';
import { useState } from 'react';
import { IVtsBasketPosition } from './ProductAttributes/ProductAttributes';
import { formatCurrencyEur } from '../../Helper/formatter';
import { postRequest } from '../../Helper/ApiClient';
export interface IVtsProduct {
  id: number;
  erp_id: number;
  name: string;
  brand_name: string;
  category_name: string;
  sub_category_name: string;
  unit_name: string;
  unit_value: number;
  units_per_case: number;
  image_url: string;
  promotion_text: string;
  regular_price: number;
  current_price: number;
  product_attributes: IVtsProductAttribute[];
}
export interface IVtsProductAttribute {
  id: number;
  name: string;
  has_nutrition_facts: boolean;
  nutrition_fact_images: any[];
}

export default function VtsProductList(props: {
  products: IVtsProduct[];
  onChangeFilter: React.ChangeEventHandler<HTMLSelectElement>;
  scrollIntoViewProductSizeId?: number;
}) {
  const [positions, setPositions] = useState<IVtsBasketPosition[]>([]);
  const addToBasketMutation = postRequest(
    '/api/onlineshop/vts-basket-add-position'
  );

  const getUnits = () => {
    const totalUnits = positions.reduce((acc, obj) => acc + obj.quantity, 0);
    return totalUnits;
  };

  const getTotal = () => {
    const totalPrice = positions.reduce((acc, obj) => acc + (obj.price * obj.quantity), 0);
    return formatCurrencyEur(totalPrice);
  };

  const handleClickAddAllToBasket = () => {
    let formData = new FormData();
    formData.append('positionsJson', JSON.stringify(positions));
    addToBasketMutation.mutate(formData, {
      onSuccess: () => {
        setTimeout(() => {
          setPositions([]);
        }, 1000)
      },
    });
  };

  return (
    <>
      <Row className="mb-3">
        <Col className="text-end">
          <SortBySelectFilter onChange={props.onChangeFilter} />
        </Col>
      </Row>
      {props.products.map((product) => {
        return (
          <ProductSize
            positions={positions}
            setPositions={setPositions}
            product={product}
            key={product.id}
            scrollIntoView={product.id == props.scrollIntoViewProductSizeId}
          />
        );
      })}
      {positions.length > 0 && (
        <div className={styles.stickyBar}>
          <Row>
            <Col className="d-block d-md-flex align-items-center justify-content-end fw-bold px-md-4 mb-2 mb-lg-0 text-end">
              {positions.length > 0 && (
                <span className="me-md-4 me-2">UNITS: {getUnits()}</span>
              )}
              {positions.length > 0 && (
                <span className="me-md-4 me-2">TOTAL: {getTotal()}</span>
              )}
              <ButtonMutation
                onClick={handleClickAddAllToBasket}
                mutationResult={addToBasketMutation}
              >
                Add all to order
              </ButtonMutation>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
}
