import React from 'react';
import MenuBrands from './MenuBrands/MenuBrands';
import MenuCategories from './MenuCategories/MenuCategories';
// @ts-ignore
import style from './MenuDesktop.module.scss?module';
import MenuSale from './MenuSale/MenuSale';
import MenuItem from './MenuItem/MenuItem';
import { Link } from 'react-router-dom';

const MenuDesktop = () => {
  return (
    <nav className={style.menuDesktop}>
      <ul className="list-unstyled d-flex m-0">
        <MenuBrands />
        <MenuCategories />
        <MenuSale />
        {/*<MenuItem text={<Link to={'/new'}>New</Link>} />*/}
      </ul>
    </nav>
  );
};

export default MenuDesktop;
