import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import {NavLink} from "react-router-dom";


export default function Rest() {
    return (
        <React.Fragment>
            <Container>
                <Row>
                    <Col>
                        <h1>Rest</h1>
                        <div>
                            Browse our&nbsp;
                            <a className={"blue"} target={"_blank"} href={"https://editor.swagger.io/?url=https://rest.naskorsports.com/customer-api/v1/documentation"}>
                                REST API on swagger.io
                            </a>
                        </div>
                        <div>
                            Generate your API Key <NavLink className={"blue"} to={"/service-center/api/key"}>here</NavLink>.
                        </div>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
}
