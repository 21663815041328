import React, { Dispatch, SetStateAction, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import MegaMenuContainer from '../../MegaMenuContainer/MegaMenuContainer';
// @ts-ignore
import style from './MegaMenuBrands.module.scss?module';
import { getRequest } from '../../../../../Helper/ApiClient';
import BrandsSwiper from './BrandsSwiper/BrandsSwiper';
import Link from '../../../../../Abstracts/Link/Link';

interface Props {
  show?: boolean;
  setShowMenu: Dispatch<SetStateAction<boolean>>;
}

const letterHasBrand = (data: object, letter: string) => {
  if (!data) {
    return false;
  }
  return Object.keys(data).indexOf(letter) > -1;
};

const createLetters = () => {
  return ['#', ...[...Array(26)].map((_, i) => String.fromCharCode(i + 65))];
};

const getSwiperData = (data: object) => {
  if (!data) {
    return [];
  }

  const cols: any[] = [];
  let temp: any[] = [];

  let index = 0;

  Object.keys(data).forEach((letter) => {
    if (index % 10 === 0 && index !== 0) {
      cols.push(temp);
      temp = [];
    }

    temp.push({ text: letter, link: null });
    index++;

    data[letter].forEach((brand) => {
      if (index % 10 === 0 && index !== 0) {
        cols.push(temp);
        temp = [];
      }

      temp.push({
        text: brand.name,
        link: `/brand/${brand.erp_id}`,
      });
      index++;
    });
  });

  if (temp.length > 0) {
    cols.push(temp);
  }

  return cols;
};

const MegaMenuBrands = ({ show, setShowMenu }: Props) => {
  const topBrands = getRequest(
    '/api/onlineshop/brand/top',
    '/api/onlineshop/brand/top'
  );
  const brandsByLetter = getRequest(
    '/api/onlineshop/brand',
    '/api/onlineshop/brand',
    { onSuccess: (data) => setSwiperData(getSwiperData(data?.data)) }
  );
  const [letters] = useState<string[]>(createLetters());
  const [swiperData, setSwiperData] = useState<any[]>([]);
  const [active, setActive] = useState<string>('#');
  const handleCloseMegaMenu = () => setShowMenu(false);  

  return (
    <MegaMenuContainer show={show}>
      <Row>
        <Col lg={3}>
          <span className="fs-4 fw-bold">Top Brands</span>
        </Col>
        <Col lg={9}>
          <div className="d-flex lh-1 my-2 justify-content-between flex-md-wrap gap-md-3">
            <div className="test">
              {brandsByLetter.data?.data &&
                letters.map((letter, key) => (
                  <span key={key} className="mx-2">
                    <Link
                      onClick={() => {
                        if (
                          letterHasBrand(brandsByLetter?.data?.data, letter)
                        ) {
                          setActive(letter);
                        }
                      }}
                      className={[
                        active === letter ? '' : '',
                        !letterHasBrand(brandsByLetter?.data?.data, letter)
                          ? 'text-disabled'
                          : '',
                      ].join(' ')}
                    >
                      {letter}
                    </Link>
                  </span>
                ))}
            </div>
            <div className="d-flex justify-content-between flex-wrap">
              <div className="mt-2 mt-xl-0">
                <Link
                  to="/brands"
                  className={[
                    'px-3 py-1 fw-bold bg-white text-decoration-none',
                  ].join(' ')}
                  onClick={handleCloseMegaMenu}
                >
                  All brands
                </Link>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={3}>
          <hr className="opacity-100" />
          {topBrands?.data?.data.map(({ erp_id, name }) => (
            <Link
              onClick={handleCloseMegaMenu}
              key={erp_id}
              to={`/product-list/brand/${erp_id}`}
              className={['d-block mt-1'].join(' ')}
            >
              {name}
            </Link>
          ))}
        </Col>
        <Col lg={9}>
          <hr className="opacity-100" />
          <BrandsSwiper
            setShowMenu={setShowMenu}
            data={swiperData}
            active={active}
            setActive={setActive}
          />
        </Col>
      </Row>
    </MegaMenuContainer>
  );
};

export default MegaMenuBrands;
