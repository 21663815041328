import * as React from 'react';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/pro-solid-svg-icons';
import { faTrash } from '@fortawesome/pro-regular-svg-icons';
// @ts-ignore
import style from './VtsBasketPosition.module.scss?module';
import { formatCurrencyEur } from '../../../../../../Helper/formatter';
import { VtsBasketPositionInterface } from '../../../../../../../Interface/Vts';
import useDeleteMutation from '../../../../../../Helper/useDeleteMutation';
import usePatchMutation from '../../../../../../Helper/usePatchMutation';
import { VtsContext } from '../../../../VirtualTradeShow';

let qtyTimeout: any = null;

export default function VtsBasketPosition(props: {
  basketPosition: VtsBasketPositionInterface;
  refetchBasket: () => void;
}) {
  const vtsContext = useContext(VtsContext);
  const setQtyMutation = usePatchMutation(
    '/api/onlineshop/vts-basket-product-attributes/' +
      props.basketPosition.product_attribute.id
  );
  const deleteMutation = useDeleteMutation(
    '/api/onlineshop/vts-basket-product-attributes/' +
      props.basketPosition.product_attribute.id
  );
  const [deleted, setDeleted] = useState(false);
  const [quantity, setQuantity] = useState(props.basketPosition.quantity);

  useEffect(() => {
    if (quantity != props.basketPosition.quantity) {
      requestSetQtyDelayed(quantity);
    }
  }, [quantity]);

  const requestSetQtyDelayed = (new_value: number) => {
    clearTimeout(qtyTimeout);
    qtyTimeout = setTimeout(() => {
      setQtyMutation.mutate(JSON.stringify({ quantity: new_value }));
    }, 300);
  };

  const handleQtyUpdate = (e: ChangeEvent<HTMLInputElement>) => {
    setQuantity(Math.max(0, Number(e.currentTarget.value)));
  };

  const handleQtyIncrease = () => {
    setQuantity(Math.max(0, quantity + 1));
  };

  const handleQtyDecrease = () => {
    setQuantity(Math.max(0, quantity - 1));
  };

  const handleDelete = () => {
    if (vtsContext.vts?.is_confirmation) {
      setQuantity(0);
    } else {
      setDeleted(true);
    }
    deleteMutation.mutate();
  };

  useEffect(() => {
    if (deleteMutation.isError) {
      setDeleted(false);
    }
  }, [deleteMutation.isError]);

  useEffect(() => {
    if (deleteMutation.isSuccess) {
      props.refetchBasket();
    }
  }, [deleteMutation.isSuccess]);

  useEffect(() => {
    if (setQtyMutation.isSuccess) {
      props.refetchBasket();
    }
  }, [setQtyMutation.isSuccess]);

  if (deleted) {
    return null;
  }
  if (!props.basketPosition) {
    return null;
  }
  return (
    <>
      <Row className={'py-2'}>
        <Col xl={3} md={5} xs={12}>
          {/*props.basketPosition.product_attribute.product_size.product.brand.name*/}
          {props.basketPosition.product_attribute.product_size.name}
        </Col>
        <Col
          xl={3}
          md={3}
          xs={8}
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {props.basketPosition.product_attribute.name}
        </Col>
        <Col xl={1} md={2} xs={4} className={'text-end'}>
          <small
            className={'text-muted fw-normal fst-italic d-xl-none'}
            style={{ fontSize: '.6rem', whiteSpace: 'nowrap' }}
          >
            {formatCurrencyEur(props.basketPosition.singlePriceExclVat)} / unit
          </small>
          <span className={'d-none d-xl-inline'}>
            {formatCurrencyEur(props.basketPosition.singlePriceExclVat)}
          </span>
        </Col>
        <Col xl={2} md={4} xs={8}>
          <div style={{ display: 'flex' }}>
            <button
              onClick={handleQtyDecrease}
              style={{
                backgroundColor: 'transparent',
                border: '0 hidden',
                paddingRight: '10px',
              }}
            >
              <FontAwesomeIcon icon={faMinus} />
            </button>
            <div style={{ flexGrow: 1 }}>
              <input
                min={0}
                className={style.QtyInput}
                value={quantity}
                onChange={handleQtyUpdate}
              />
            </div>
            <button
              onClick={handleQtyIncrease}
              style={{
                backgroundColor: 'transparent',
                border: '0 hidden',
                paddingLeft: '10px',
              }}
            >
              <FontAwesomeIcon icon={faPlus} />
            </button>
            <button
              onClick={handleDelete}
              style={{
                backgroundColor: 'transparent',
                border: '0 hidden',
                paddingLeft: '10px',
              }}
            >
              <FontAwesomeIcon icon={faTrash} style={{ color: '#cccccc' }} />
            </button>
          </div>
        </Col>
        <Col
          xl={2}
          md={6}
          xs={4}
          className={'text-end'}
          // style={{ backgroundColor: 'lime' }}
        >
          <strong>
            {formatCurrencyEur(
              quantity * props.basketPosition.singlePriceExclVat
            )}
          </strong>
        </Col>
      </Row>
      <Row>
        <Col>
          {setQtyMutation.error?.message.length > 0 &&
            setQtyMutation.error.message}
          {deleteMutation.error?.message.length > 0 &&
            deleteMutation.error.message}
        </Col>
      </Row>
    </>
  );
}
