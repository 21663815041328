import * as React from "react";
// @ts-ignore
import style from "./TabButton.module.scss?module";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export default function TabButton({
  active,
  onClick,
  text,
  icon,
}: {
  active: boolean;
  onClick: React.MouseEventHandler<HTMLDivElement>;
  text: string;
  icon: IconProp;
}) {
  return (
    <div
      className={[style.TabButton, active && style.active].join(" ")}
      onClick={onClick}
    >
      <div className={style.Icon}>
        <FontAwesomeIcon icon={icon} />
      </div>
      <div className={style.Text}>{text}</div>
    </div>
  );
}
