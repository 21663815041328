import React from "react";
// @ts-ignore
import style from "./UnitCount.module.scss?module";

interface Props {
  value?: string | number;
  className?: string;
}

const UnitCount = ({ value, className }: Props) => {
  return value ? (
    <span className={[style.unitCount, className].join(" ")}>{value}</span>
  ) : (
    <></>
  );
};

export default UnitCount;
