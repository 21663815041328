import * as React from 'react';
import { Row, Col } from 'react-bootstrap';
import { getRequest } from '../../../../../Helper/ApiClient';
import SwiperWrapper from '../../../../../Abstracts/SwiperWrapper/SwiperWrapper';
import SwiperSlideNewProduct from './SwiperSlideNewProduct/SwiperSlideNewProduct';
import { IProductAttribute } from '../../../../../Abstracts/ProductList/ProductList';
import { IProduct } from '../../../../Complaints/ComplaintsForm/ProductList/ProductList';

export interface INewProductAttribute {
  erp_id: number;
  image_transparent_public_url: string;
  image_background_public_url: string;
  current_price: number;
  product_attribute: IProductAttribute;
  product_size: IProduct;
}

export default function NewProduct(props: {
  exhibitor: any;
  showProductsAndScrollToProductSize?: (productSizeId: number) => void;
}) {
  const newProductAttributesRequest = getRequest<INewProductAttribute[]>(
    `/api/virtual-trade-show/exhibitors/${props.exhibitor.id}/new-products`
  );

  // Add a state for slidesPerView
  const [slidesPerView, setSlidesPerView] = React.useState(3);

  // Update slidesPerView based on screen width
  const updateSlidesPerView = () => {
    if (window.innerWidth >= 992) {
      setSlidesPerView(5);
    } else {
      setSlidesPerView(3);
    }
  };

  // Add a useEffect hook to listen to window resize and update slidesPerView on component mount
  React.useEffect(() => {
    updateSlidesPerView();
    window.addEventListener('resize', updateSlidesPerView);
    return () => {
      window.removeEventListener('resize', updateSlidesPerView);
    };
  }, []);

  return (
    <>
      <Row>
        <Col>
          <h2 className={'popupHeadline'}>
            New {props.exhibitor.name} products
          </h2>
        </Col>
      </Row>
      <Row>
        <Col className="mt-3">
          <SwiperWrapper
            slideContents={
              newProductAttributesRequest.isSuccess &&
              newProductAttributesRequest.data &&
              newProductAttributesRequest.data.map(
                (newProductAttribut: INewProductAttribute, index: number) => {
                  return (
                    <SwiperSlideNewProduct
                      new_product_attribute={newProductAttribut}
                      showProductsAndScrollToProductSize={
                        props.showProductsAndScrollToProductSize
                      }
                      key={index}
                    />
                  );
                }
              )
            }
            centeredSlides={true}
            effect={'coverflow'}
            slidesPerView={slidesPerView}
            coverflowEffect={{
              rotate: 30,
              slideShadows: false,
            }}
          />
          {/*<SwiperWrapper
            slideContents={
              newProductAttributesRequest.isSuccess &&
              newProductAttributesRequest.data &&
              newProductAttributesRequest.data.map(
                (newProductAttribut: INewProductAttribute, index: number) => {
                  return (
                    <SwiperSlideNewProduct
                      new_product_attribute={newProductAttribut}
                      showProductsAndScrollToProductSize={
                        props.showProductsAndScrollToProductSize
                      }
                      key={index}
                    />
                  );
                }
              )
            }
            centeredSlides={true}
            effect={'coverflow'}
            slidesPerView={4}
            coverflowEffect={{
              rotate: 30,
              stretch: 70,
              slideShadows: false,
            }}
          />*/}
        </Col>
      </Row>
    </>
  );
}
