import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
// @ts-ignore
import style from "./MenuItem.module.scss?module";
import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import Link from "../../../../Abstracts/Link/Link";

interface IMenuItem extends React.HTMLAttributes<HTMLAnchorElement> {
  text: string;
  icon?: "left" | "right";
  link?: string;
}

const MenuItem: React.FC<IMenuItem> = ({
  text,
  icon,
  onClick,
  link,
  className,
  ...props
}) => {
  return (
    <Link
      className={[
        style.menuItem,
        icon === "left" || (!icon && !link) ? style.menuItemGray : "",
        className,
      ]
        .join(" ")
        .trim()}
      to={link}
      onClick={onClick}
      {...props}
    >
      {icon === "left" && (
        <div className="position-absolute w-100 d-flex justify-content-start align-items-center ps-3">
          <FontAwesomeIcon icon={faChevronLeft} />
        </div>
      )}
      <span className={icon ? "" : "me-auto"}>{text}</span>
      {icon === "right" && (
        <FontAwesomeIcon className="ms-auto" icon={faChevronRight} />
      )}
    </Link>
  );
};

export default MenuItem;
