import React, { useEffect } from 'react';
import { Col, Container, Row, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getRequest, postRequest } from '../../../Helper/ApiClient';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowTrendDown,
  faArrowTrendUp,
} from '@fortawesome/pro-solid-svg-icons';
import { formatCurrencyEur } from '../../../Helper/formatter';

export default function PriceChange() {
  const priceChangeProductsRequest = getRequest(
    '/api/onlineshop/price-change-products'
  );
  const visitPageMutation = postRequest('/api/onlineshop/visit-page');

  useEffect(() => {
    let formData = new FormData();
    formData.append('page', 'price_change');
    visitPageMutation.mutate(formData);
  }, []);

  return (
    <Container>
      <Row className="pt-5">
        <Col>
          <h1>Price Changes</h1>
          <p>Information about product pricing modifications.</p>
        </Col>
      </Row>
      <Row>
        <Col>
          <Col>
            {priceChangeProductsRequest.isLoading ? (
              <span>Loading...</span>
            ) : (
              <>
                <div className="d-none d-md-block">
                  <Table borderless>
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Brand</th>
                        <th>Product</th>
                        <th className="text-end">New Price</th>
                        <th className="text-center">Trend</th>
                      </tr>
                    </thead>
                    <tbody>
                      {priceChangeProductsRequest.isSuccess &&
                        priceChangeProductsRequest.data &&
                        priceChangeProductsRequest.data.length > 0 &&
                        priceChangeProductsRequest.data.map(
                          (priceChange: any, index: number) => {
                            return (
                              <tr
                                key={index}
                                className="border-bottom bg-secondary border-white border-5"
                              >
                                <td className="align-middle">
                                  {priceChange.updated}
                                </td>
                                <td className="align-middle">
                                  <Link
                                    to={
                                      '/productSize/' +
                                      priceChange.product_size_erp_id
                                    }
                                    className="inherit"
                                  >
                                    {priceChange.brand_name}
                                  </Link>
                                </td>
                                <td className="align-middle">
                                  <Link
                                    to={
                                      '/productSize/' +
                                      priceChange.product_size_erp_id
                                    }
                                    className="inherit"
                                  >
                                    {priceChange.product_size_name}
                                  </Link>
                                </td>
                                <td className="align-middle text-end">
                                  {formatCurrencyEur(priceChange.price)}
                                </td>
                                <td className="align-middle text-center">
                                  {priceChange.price_trend == 'up' && (
                                    <FontAwesomeIcon icon={faArrowTrendUp} />
                                  )}
                                  {priceChange.price_trend == 'down' && (
                                    <FontAwesomeIcon
                                      className="text-success"
                                      icon={faArrowTrendDown}
                                    />
                                  )}
                                </td>
                              </tr>
                            );
                          }
                        )}

                      {priceChangeProductsRequest.isSuccess &&
                        priceChangeProductsRequest.data.length < 1 && (
                          <tr>
                            <td colSpan={5} className="p-5 text-center">
                              <i>No results.</i>
                            </td>
                          </tr>
                        )}
                    </tbody>
                  </Table>
                </div>
                <div className="d-sm-block d-md-none">
                  <Table borderless>
                    <tbody>
                      {priceChangeProductsRequest.isSuccess &&
                        priceChangeProductsRequest.data.length > 0 &&
                        priceChangeProductsRequest.data.map(
                          (priceChange: any) => {
                            return (
                              <React.Fragment key={priceChange.id}>
                                <tr className="bg-secondary border-bottom">
                                  <td>Date:</td>
                                  <td className="border-start">
                                    <div>{priceChange.updated}</div>
                                  </td>
                                </tr>
                                <tr className="bg-secondary border-bottom">
                                  <td>Brand:</td>
                                  <td className="border-start">
                                    <div>
                                      <Link
                                        to={
                                          '/productSize/' +
                                          priceChange.product_size_erp_id
                                        }
                                        className="inherit"
                                      >
                                        {priceChange.brand_name}
                                      </Link>
                                    </div>
                                  </td>
                                </tr>
                                <tr className="bg-secondary border-bottom">
                                  <td>Product:</td>
                                  <td className="border-start">
                                    <div>
                                      <Link
                                        to={
                                          '/productSize/' +
                                          priceChange.product_size_erp_id
                                        }
                                        className="inherit"
                                      >
                                        {priceChange.product_size_name}
                                      </Link>
                                    </div>
                                  </td>
                                </tr>
                                <tr className="bg-secondary border-bottom">
                                  <td>Price:</td>
                                  <td className="border-start">
                                    <div>
                                      {formatCurrencyEur(priceChange.price)}
                                    </div>
                                  </td>
                                </tr>
                                <tr className="bg-secondary border-bottom">
                                  <td>Trend:</td>
                                  <td className="border-start">
                                    {priceChange.price_trend == 'up' && (
                                      <FontAwesomeIcon icon={faArrowTrendUp} />
                                    )}
                                    {priceChange.price_trend == 'down' && (
                                      <FontAwesomeIcon
                                        className="text-success"
                                        icon={faArrowTrendDown}
                                      />
                                    )}
                                  </td>
                                </tr>
                                <tr className="bg-secondary border-bottom border-5 border-white"></tr>
                              </React.Fragment>
                            );
                          }
                        )}
                    </tbody>
                  </Table>
                </div>
              </>
            )}
          </Col>
        </Col>
      </Row>
    </Container>
  );
}
