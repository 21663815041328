import React from 'react';
import { Col, Image } from 'react-bootstrap';
import cn from 'classnames';
import { IBrand } from '../../../../Interface/Award';

// @ts-ignore
import styles from './AwardBrand.module.scss?module';

export default function AwardBrand(props: {
  awardBrand: IBrand;
  onClick: any;
  selected: boolean;
  votingActive: boolean;
  isWinner?: boolean;
}) {
  return (
    <Col
      onClick={props.onClick}
      className={cn(
        'pb-3 awards-nominee',
        props.selected ? 'awards-selected' : '',
        props.votingActive ? 'awards-voting-active' : ''
      )}
    >
      <div style={{ position: 'relative' }}>
        {props.isWinner !== undefined &&
          props.isWinner &&
          props.awardBrand.overlay_image && (
            <Image
              className="w-100"
              style={{ position: 'absolute', left: '0', top: '0' }}
              src={props.awardBrand.overlay_image}
            />
          )}
        <Image
          src={props.awardBrand?.image}
          className={`${
            props.isWinner !== undefined
              ? props.isWinner === false
                ? styles.GreyScale
                : ''
              : ''
          } w-100`}
        />
      </div>
      <div>{props.awardBrand.name}</div>
    </Col>
  );
}
