import React, { Dispatch, SetStateAction } from 'react';
import { Col, Row } from 'react-bootstrap';
import { getRequest } from '../../../../../Helper/ApiClient';
import MegaMenuContainer from '../../MegaMenuContainer/MegaMenuContainer';
// @ts-ignore
import styles from './MegaMenuCategories.module.scss?module';
import Link from '../../../../../Abstracts/Link/Link';

interface Props {
  show?: boolean;
  setShowMenu: Dispatch<SetStateAction<boolean>>;
}

const MegaMenuCategories = ({ show, setShowMenu }: Props) => {
  const categories = getRequest(
    '/api/onlineshop/category',
    '/api/onlineshop/category'
  );

  const getRows = (data: any[] | undefined) => {
    if (!data) {
      return [];
    }

    const arr: any[] = [];

    for (let i = 0; i < data.length; i += 5) {
      arr.push(data.slice(i, i + 5));
    }
    return arr;
  };
  const handleCloseMegaMenu = () => setShowMenu(false);

  return (
    <MegaMenuContainer show={show}>
      {getRows(categories?.data?.data).map((row, key) => (
        <Row key={key} className="my-3 align-items-stretch">
          {row.map(({ erp_id, name, sub_categories }) => (
            <Col key={erp_id} className="d-flex flex-column w-auto">
              <Link
                onClick={handleCloseMegaMenu}
                to={`/product-list/category/${erp_id}`}
                className={'fw-bold'}
              >
                {name}
              </Link>
              {sub_categories.slice(0, 3).map((sub) => (
                <Link
                  onClick={handleCloseMegaMenu}
                  key={sub.erp_id}
                  to={`/product-list/category/${erp_id}/${sub.erp_id}`}
                  className={'mt-1'}
                >
                  {sub.name}
                </Link>
              ))}
              <Link
                onClick={handleCloseMegaMenu}
                to={`/product-list/category/${erp_id}`}
                className={'mt-1'}
              >
                {'» View all'}
              </Link>
            </Col>
          ))}
        </Row>
      ))}
    </MegaMenuContainer>
  );
};

export default MegaMenuCategories;
