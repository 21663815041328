import React from 'react';
// @ts-ignore
import styles from './MenuItem.module.scss?module';

interface Props {
  text: string | React.ReactNode;
  onMouseEnter?: React.MouseEventHandler<HTMLElement>;
  onMouseLeave?: React.MouseEventHandler<HTMLElement>;
  children?: React.ReactNode;
}

const MenuItem = ({ text, onMouseEnter, onMouseLeave, children }: Props) => {
  return (
    <li
      className={['px-2 pb-4', styles.MenuItem].join(' ')}
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
    >
      <span
        className={['fst-italic cursor-pointer', styles.MenuItemText].join(' ')}
      >
        {text}
      </span>
      {children}
    </li>
  );
};

export default MenuItem;
