import { faHeart } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import UnitCount from '../../../../Abstracts/UnitCount/UnitCount';
import { useUser } from '../../../../Context/UserContext';

// @ts-ignore
import style from './Favourites.module.scss?module';

const Favourites = () => {
  const { user } = useUser();
  return (
    <a className={[style.favourites].join(' ')} href="/favourites">
      <div className="position-relative d-flex justify-content-center">
        <FontAwesomeIcon className={style.iconLinkIcon} icon={faHeart} />
        <UnitCount value={user?.favourites_count} />
      </div>
    </a>
  );
};

export default Favourites;
