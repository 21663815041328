export function formatDate(date_string?: string) {
  if (typeof date_string !== 'string') {
    return '';
  }
  const date = new Date(date_string);
  return date.toLocaleDateString('de-DE', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
}

export function formatDateTime(date_string?: string) {
  if (typeof date_string !== 'string') {
    return '';
  }
  const date = new Date(date_string);
  return (
    Intl.DateTimeFormat('de-DE', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    }).format(date) +
    ' ' +
    Intl.DateTimeFormat('de-DE', {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    }).format(date)
  );
}

export function formatFloat2Digits(int_or_float?: number) {
  if (typeof int_or_float !== 'number') {
    return '';
  }
  return Intl.NumberFormat('de-DE', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(int_or_float);
}

export function formatPercent2Digits(int_or_float?: number) {
  if (typeof int_or_float !== 'number') {
    return '';
  }
  return Intl.NumberFormat('de-DE', {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(int_or_float / 100);
}

export function formatPercent(int?: number) {
  if (typeof int !== 'number') {
    return '';
  }
  return Intl.NumberFormat('de-DE', {
    style: 'percent',
  }).format(int / 100);
}

export function formatFloat3Digits(int_or_float?: number) {
  if (typeof int_or_float !== 'number') {
    return '';
  }
  return Intl.NumberFormat('de-DE', {
    style: 'decimal',
    minimumFractionDigits: 3,
    maximumFractionDigits: 3,
  }).format(int_or_float);
}

export function formatCurrency(int_or_float?: number, currency?: string) {
  if (typeof int_or_float !== 'number') {
    return '';
  }
  if (typeof currency !== 'string') {
    return formatFloat2Digits(int_or_float);
  }
  return Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: currency,
  }).format(int_or_float);
}

export function formatCurrencyEur(int_or_float?: number) {
  return formatCurrency(int_or_float, 'EUR');
}

export function formatWeight(weightInGram?: number) {
  if (typeof weightInGram !== 'number') {
    return '';
  }
  let suffix = 'g';
  let weightString = '' + weightInGram;
  if (weightInGram > 999) {
    suffix = ' kg';
    weightString = formatFloat2Digits(weightInGram / 1000);
  }
  return weightString + suffix;
}
