import React, { useState, useEffect } from 'react';
import { INewComerWeekProduct } from '../../../../../ProductsSlideshow/ProductsSlideshow';
import { Image } from 'react-bootstrap';
export default function ProductInformationContent({
  ncwProduct,
  isDesktop,
}: {
  ncwProduct: INewComerWeekProduct;
  isDesktop?: boolean;
}) {

  const [showMoreDescription, setShowMoreDescription] = useState(false);
  useEffect(() => {
    if(ncwProduct.description.length > 150) {
      setShowMoreDescription(true);
    }
  },[ncwProduct.description]);

  return (
    <>
      {isDesktop && (
        <>
          <h2 className="m-0">{ncwProduct.product.brand.name}</h2>
          <h5 className="m-0">{ncwProduct.product.name}</h5>
        </>
      )}
      <Image style={{borderRadius: '10px'}} className={'w-100'} src={ncwProduct.product.image_nf_url} />
      <p 
        style={{maxHeight: showMoreDescription ? '150px' : '100%' , overflow: showMoreDescription ? 'hidden'  : 'visible'}} 
        className="my-2"
      >
        {ncwProduct.description}
      </p>
        <span 
          style={{cursor: 'pointer',textDecoration: 'underline', height: '100px'}} 
          className='d-block text-center text-primary' 
          onClick={() => setShowMoreDescription(!showMoreDescription)}
        >
          {showMoreDescription ? 'More': 'Less'}
        </span>
        {!showMoreDescription && <div aria-label='spacer' style={{minHeight: '30px'}} />}
    </>
  );
}
