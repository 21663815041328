import React, { useState } from 'react';
import Button from '../../../../Abstracts/Button/Button';
import { useUser } from '../../../../Context/UserContext';
import {
  formatCurrency,
  formatCurrencyEur,
} from '../../../../Helper/formatter';
// @ts-ignore
import style from './UserMenuDropdown.module.scss?module';
import UserMenuLogin from './UserMenuLogin/UserMenuLogin';
import { Button as BootstrapButton, Form, InputGroup } from 'react-bootstrap';
import { postRequest } from '../../../../Helper/ApiClient';
import { useNavigate } from 'react-router-dom';
import Link from '../../../../Abstracts/Link/Link';

const UserMenuDropdown = ({ hideMenu }: { hideMenu: CallableFunction }) => {
  const { user, loading } = useUser();
  const [userEmail, setUserEmail] = useState('');
  const switchUserMutation = postRequest('/');
  const navigate = useNavigate();

  const switchUserHandler = () => {
    let formData = new FormData();
    formData.append('_switch_user', userEmail);
    switchUserMutation.mutate(formData, {
      onSuccess: () => {
        window.location.reload();
        // navigate(0);
      },
    });
  };

  return !loading ? (
    <div
      className={['position-absolute text-black p-4', style.dropdown].join(' ')}
    >
      {user != null ? (
        <div>
          <strong>{user?.name}</strong>
          <div>{user?.email}</div>
          <Link
            href="/my-unpaid-invoices"
            onClick={() => {
              hideMenu();
            }}
            className="d-block text-decoration-none fw-bold text-nowrap text-black my-3 fst-italic"
          >{`Unpaid Invoices ${formatCurrencyEur(
            user?.erp_unpaid_sums ?? undefined
          )}`}</Link>
          {user?.vts_orders_visible &&
              <Link
                  to="/vts-order"
                  className="d-block text-decoration-none fw-bold text-nowrap text-black fst-italic"
                  onClick={() => {
                    hideMenu();
                  }}
              >
                  Virtual Trade Show Order
              </Link>
          }
          <Link
            to="/account/change-password"
            className="d-block text-decoration-none fw-bold text-nowrap text-black fst-italic"
            onClick={() => {
              hideMenu();
            }}
          >
            Change password
          </Link>
          <Link
            to="/my-orders"
            className="d-block text-decoration-none fw-bold text-nowrap text-black fst-italic"
            onClick={() => {
              hideMenu();
            }}
          >
            My Orders
          </Link>
          <Link
            to="/complaints"
            className="d-block text-decoration-none fw-bold text-nowrap text-black fst-italic"
            onClick={() => {
              hideMenu();
            }}
          >
            Complaints
          </Link>
          <Link
            to="/contact"
            className="d-block text-decoration-none fw-bold text-nowrap text-black fst-italic"
            onClick={() => {
              hideMenu();
            }}
          >
            Contact
          </Link>
          <Link
            to={'/payment-methods'}
            className="d-block fw-bold text-nowrap text-black fst-italic"
            onClick={() => {
              hideMenu();
            }}
            opacityOnHover={false}
          >
            Payment methods
          </Link>
          {user?.has_api_access && (
            <Link
              to={'/service-center/api'}
              className="d-block fw-bold text-nowrap text-black fst-italic"
              onClick={() => {
                hideMenu();
              }}
              opacityOnHover={false}
            >
              Api
            </Link>
          )}
          {user?.bonus && (
            <Link
              to={'/bonus'}
              className="d-block fw-bold text-nowrap text-black fst-italic"
              onClick={() => {
                hideMenu();
              }}
              opacityOnHover={false}
            >
              Bonus
            </Link>
          )}
          {(user?.ROLE_ALLOWED_TO_SWITCH || user?.ROLE_PREVIOUS_ADMIN) && (
            <>
              {user?.ROLE_ALLOWED_TO_SWITCH && (
                <>
                  <span className="d-block text-decoration-none fw-bold text-nowrap text-black mt-3 fst-italic">
                    Switch user
                  </span>
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder="user@email.com"
                      type="email"
                      onBlur={(ev) => setUserEmail(ev.currentTarget.value)}
                    />
                    <BootstrapButton onClick={switchUserHandler}>
                      Switch
                    </BootstrapButton>
                  </InputGroup>
                </>
              )}
              {user?.ROLE_PREVIOUS_ADMIN && (
                <a
                  href="/?_switch_user=_exit"
                  className="d-block text-decoration-none fw-bold text-nowrap text-black my-3 fst-italic"
                >
                  Exit user
                </a>
              )}
            </>
          )}
          <a href="/logout" className="d-block text-end mt-3">
            <Button>Logout</Button>
          </a>
        </div>
      ) : (
        <UserMenuLogin />
      )}
    </div>
  ) : (
    <></>
  );
};

export default UserMenuDropdown;
