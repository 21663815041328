import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ButtonHTMLAttributes, useEffect, useState } from 'react';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import { animated, Spring } from '@react-spring/web';

// @ts-ignore
import style from './Button.module.scss?module';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  loading?: boolean;
  success?: boolean;
  color?: 'blue' | 'gray';
}

const Button: React.FC<ButtonProps> = ({
  loading,
  success,
  color = 'blue',
  children,
  className,
  onClick,
  ...props
}) => {
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [clicked, setClicked] = useState<boolean>(false);
  const defaultClass = color === 'gray' ? 'btn-secondary' : 'btn-primary';

  useEffect(() => {
    if (success && clicked) {
      setShowSuccess(true);
      const timeout = setTimeout(() => {
        setShowSuccess(false);
      }, 2000);

      return () => clearTimeout(timeout);
    } else {
      setShowSuccess(false);
    }
  }, [success]);

  useEffect(() => {
    if (loading === false) {
      const timeout = setTimeout(() => {
        setClicked(false);
      }, 1000);
      return () => clearTimeout(timeout);
    }
  }, [loading]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setClicked(true);

    if (onClick) {
      onClick(event);
    }
  };

  return (
    <button
      {...props}
      onClick={handleClick}
      className={[
        'btn fw-bold fst-italic text-uppercase rounded-0',
        showSuccess ? 'btn-success' : defaultClass,
        className,
      ]
        .join(' ')
        .trim()}
      disabled={props.disabled || loading}
    >
      {clicked && loading ? (
        <div className="d-flex flex-column justify-content-center">
          <LoadingSpinner
            className="position-absolute align-self-center"
            size="sm"
          />
          <div className="text-transparent">{children}</div>
        </div>
      ) : showSuccess ? (
        <div className="d-flex flex-column justify-content-center">
          <Spring
            from={{ clipPath: 'inset(0 100% 0 0)' }}
            to={{ clipPath: 'inset(0 0 0 0)' }}
            // config={{ duration: 1000 }}
          >
            {(style) => (
              <animated.div
                className="position-absolute align-self-center"
                style={style}
              >
                <FontAwesomeIcon
                  className="text-white"
                  icon={faCheck}
                  size="lg"
                />
              </animated.div>
            )}
          </Spring>
          <div className="text-transparent">{children}</div>
        </div>
      ) : (
        children
      )}
    </button>
  );
};

export default Button;
