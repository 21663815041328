import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getRequest, postRequest } from '../../../Helper/ApiClient';
import NotifyUser from '../../../Abstracts/ProductList/Products/Product/ProductAttributes/ProductAttribute/NotifyUser/NotifyUser';
import Table from '../../../Abstracts/TableElements/Table/Table';

export default function AvailableSoon() {
  const availableSoonProductsRequest = getRequest(
    '/api/onlineshop/available-soon-product-attributes'
  );
  const visitPageMutation = postRequest('/api/onlineshop/visit-page');

  useEffect(() => {
    let formData = new FormData();
    formData.append('page', 'available_soon');
    visitPageMutation.mutate(formData);
  }, []);

  return (
    <Container>
      <Row className="pt-5">
        <Col>
          <h1>Available soon</h1>
          <p>This is an overview of products which we will receive soon.</p>
        </Col>
      </Row>
      <Row>
        <Col>
          {availableSoonProductsRequest.isLoading ? (
            <span>Loading...</span>
          ) : (
            <>
              <div className="d-none d-md-block">
                <Table>
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Brand</th>
                      <th>Product</th>
                      <th>Flavour</th>
                      <th className="text-center">Notification</th>
                    </tr>
                  </thead>
                  <tbody>
                    {availableSoonProductsRequest.isSuccess &&
                      availableSoonProductsRequest.data &&
                      availableSoonProductsRequest.data.length > 0 &&
                      availableSoonProductsRequest.data.map((availableSoon: any) => {
                        return (
                          <tr
                            key={availableSoon.id}
                            className="border-bottom bg-secondary border-white border-5"
                          >
                            <td className="align-middle">
                              {availableSoon.eta_date}
                            </td>
                            <td className="align-middle">
                              {availableSoon.brand_name}
                            </td>
                            <td className="align-middle">
                              {availableSoon.product_size_name}
                            </td>
                            <td className="align-middle">
                              {availableSoon.product_attribute_name}
                            </td>
                            <td>
                              <NotifyUser
                                id={availableSoon.product_attribute_erp_id}
                                has_notify_me={availableSoon.has_notify_me}
                              />
                            </td>
                          </tr>
                        );
                      })}

                    {availableSoonProductsRequest.isSuccess &&
                      availableSoonProductsRequest.data.length < 1 && (
                        <tr>
                          <td colSpan={5} className="p-5 text-center">
                            <i>No results.</i>
                          </td>
                        </tr>
                      )}
                  </tbody>
                </Table>
              </div>

              <div className="d-sm-block d-md-none">
                <Table borderless>
                  <tbody>
                    {availableSoonProductsRequest.isSuccess &&
                      availableSoonProductsRequest.data &&
                      availableSoonProductsRequest.data.length > 0 &&
                      availableSoonProductsRequest.data.map((availableSoon: any) => {
                        return (
                          <React.Fragment key={availableSoon.id}>
                            <tr className="bg-secondary border-bottom">
                              <td>Date:</td>
                              <td className="border-start">
                                <div>{availableSoon.eta_date}</div>
                              </td>
                            </tr>
                            <tr className="bg-secondary border-bottom">
                              <td>Brand:</td>
                              <td className="border-start">
                                <div>{availableSoon.brand_name}</div>
                              </td>
                            </tr>
                            <tr className="bg-secondary border-bottom">
                              <td>Product:</td>
                              <td className="border-start">
                                <div>{availableSoon.product_size_name}</div>
                              </td>
                            </tr>
                            <tr className="bg-secondary border-bottom">
                              <td>Flavour:</td>
                              <td className="border-start">
                                <div>{availableSoon.product_attribute_name}</div>
                              </td>
                            </tr>
                            <tr className="bg-secondary border-bottom">
                              <td colSpan={2} className="p-0">
                                <div className="p-2">
                                  <NotifyUser
                                    id={availableSoon.product_attribute_erp_id}
                                    has_notify_me={availableSoon.has_notify_me}
                                  />
                                </div>
                              </td>
                            </tr>
                            <tr className="bg-secondary border-bottom border-5 border-white"></tr>
                          </React.Fragment>
                        );
                      })}
                  </tbody>
                </Table>
              </div>
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
}
