import React from "react";
import {Col, Container, Row, Button} from "react-bootstrap";
import {getRequest, postRequest} from "../../../../Helper/ApiClient";


export default function Key() {

    const apiKeyRequest = getRequest("/api/api_settings");
    const apiKeyMutation = postRequest("/api/api_settings/generate_api_key")

    const handleButton = () => {
        apiKeyMutation.mutate(new FormData(), {onSuccess: () => apiKeyRequest.refetch()});
    }

    return (
        <React.Fragment>
            <Container>
                <Row>
                    <Col>
                        <div>
                            <h1>API Key</h1>
                            {apiKeyRequest.isLoading && <div className={"py-3"}>loading ...</div>}
                            {apiKeyRequest.isSuccess && 
                                apiKeyRequest.data && 
                                apiKeyRequest.data?.api_key?.length > 0 &&
                                <div className={"py-3"}>
                                    Your API Key: <span style={{fontFamily:'monospace', backgroundColor:'lightgrey', padding:'5px'}}>{apiKeyRequest.data.api_key}</span>
                                </div>
                            }
                            <div>
                                Use this button to create an API key.<br />
                                Your old key will be overwritten.
                            </div>
                            <div className={"py-3"}>
                                <Button variant={"primary"} onClick={handleButton}>Generate API Key</Button>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
}
