import React, { useState } from "react";
import { getRequest } from "../../../Helper/ApiClient";
import { MenuKey } from "./Menu";
import MenuItem from "./MenuItem/MenuItem";

interface IProps {
  active: string | null;
  setActive: React.Dispatch<React.SetStateAction<string | null>>;
  closeSidebar: () => void;
}

const Categories: React.FC<IProps> = ({ active, setActive, closeSidebar }) => {
  const categories = getRequest("/api/onlineshop/category", "/api/onlineshop/category");
  const [category, setCategory] = useState<any | null>();

  return (
    <>
      {!active && (
        <MenuItem
          text="Categories"
          icon="right"
          onClick={() => setActive(MenuKey.categories)}
        />
      )}

      {active === MenuKey.categories && (
        <>
          {category ? (
            <MenuItem
              className="fw-bold"
              text={category?.name}
              icon="left"
              onClick={() => setCategory(null)}
            />
          ) : (
            <MenuItem
              className="fw-bold"
              text="Categories"
              icon="left"
              onClick={() => setActive(null)}
            />
          )}

          {!category &&
            categories?.data?.data?.map((category, key) => (
              <MenuItem
                key={key}
                text={category.name}
                icon="right"
                onClick={() => setCategory(category)}
              />
            ))}

          {category && (
            <>
              <MenuItem
                text="» View all"
                link={`product-list/category/${category.erp_id}`}
                onClick={closeSidebar}
              />
              {category?.sub_categories.map((subCategory, key) => (
                <MenuItem
                  key={key}
                  text={subCategory.name}
                  link={`product-list/category/${category?.erp_id}/${subCategory.erp_id}`}
                  onClick={closeSidebar}
                />
              ))}
            </>
          )}
        </>
      )}
    </>
  );
};

export default Categories;
