import React, { useEffect, useRef, useState } from 'react';

interface LazyLoadImageProps {
  src: string;
  alt: string;
  className: string;
  height: string | number;
}

const LazyLoadImage: React.FC<LazyLoadImageProps> = ({
  src,
  alt,
  className,
  height,
}) => {
  const [loaded, setLoaded] = useState(false);
  const imageRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const lazyImage = entry.target as HTMLDivElement;
          lazyImage.style.backgroundImage = `url(${src})`;
          lazyImage.setAttribute('aria-label', alt);
          lazyImage.classList.add(className);
          setLoaded(true);
          observer.unobserve(lazyImage);
        }
      });
    });

    if (imageRef.current) {
      observer.observe(imageRef.current);
    }

    return () => {
      if (imageRef.current) {
        observer.unobserve(imageRef.current);
      }
    };
  }, [src, alt, className]);

  const imageStyle = {
    height: `${height}px`,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  };

  return (
    <div
      ref={imageRef}
      className={loaded ? className : ''}
      style={imageStyle}
    />
  );
};

export default LazyLoadImage;
