import * as React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
//@ts-ignore
import style from './VtsBasketContent.module.scss?module';
import VtsBasketPositions from './VtsBasketPositions/VtsBasketPositions';
import { useUser } from '../../../../Context/UserContext';
import { getRequest } from '../../../../Helper/ApiClient';
import { VtsBasketInterface } from '../../../../../Interface/Vts';

export default function VtsBasketContent(props) {
  const userContext = useUser();
  const basketRequest = getRequest<VtsBasketInterface>(
    '/api/onlineshop/vts-basket'
  );
  return (
    <Container className={'px-0'}>
      {userContext.user && !userContext.user.is_exhibitor && (
        <Row>
          <Col className={'py-3 text-muted'}>
            <p>
              <strong>How it works?</strong>
            </p>
            <ol>
              <li className={'pb-1'}>
                <strong>Place your order (May 02 - May 05, 2024):</strong> Place
                your order during the Virtual Trade Show.
              </li>
              <li className={'pb-1'}>
                <strong>Confirm your order (May 06 - May 13, 2024):</strong> For
                all brands ordered during the event, you have one week to
                adjust, add products and finally confirm your order in your
                Naskorsports account detail on https://naskorsports.com. After
                confirmation you can start releasing immediately. Remember:
                First come, First serve!
              </li>
              <li>
                <strong>Retrieve your order (May 06 - June 30, 2024):</strong>{' '}
                Several partial releases of your order are possible through your
                Naskorsports account details on https://naskorsports.com. Final
                release date is June 30, 2024.
              </li>
            </ol>
          </Col>
        </Row>
      )}
      <VtsBasketPositions basketRequest={basketRequest} />
    </Container>
  );
}
