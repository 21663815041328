import * as React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Admin from './Admin/Admin';
import Information from './Information/Information';
import Lobby from './Lobby/Lobby';
import Exhibition from './Exhibition/Exhibition';

export default function Main() {
  return (
    <Routes>
      <Route
        index
        element={<Navigate replace to="/virtual-trade-show/lobby" />}
      />
      <Route path="/lobby" element={<Lobby />} />
      <Route path="/exhibition/:panoNr?/:boothNr?" element={<Exhibition />} />
      <Route path="/information/*" element={<Information />} />
      <Route path="/admin/*" element={<Admin />} />
    </Routes>
  );
}
