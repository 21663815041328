import React from 'react';
import { Container } from 'react-bootstrap';
// @ts-ignore
import style from './MegaMenuContainer.module.scss?module';

export interface MegaMenuContainerProps {
  show?: boolean;
  children?: React.ReactNode;
}

const MegaMenuContainer = ({ show, children }: MegaMenuContainerProps) => {
  return (
    <div
      className={[
        'position-absolute w-100',
        show ? 'd-block' : 'd-none',
        style.submenu,
      ].join(' ')}
    >
      <Container className="py-4">{children}</Container>
    </div>
  );
};

export default MegaMenuContainer;
