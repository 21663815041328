import React, { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
// @ts-ignore
import style from './BrandsSwiper.module.scss?module';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import { faAngleLeft, faAngleRight } from '@fortawesome/pro-thin-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Link from '../../../../../../Abstracts/Link/Link';

interface Props {
  data: any[];
  active: string;
  setActive: React.Dispatch<React.SetStateAction<string>>;
  setShowMenu: Dispatch<SetStateAction<boolean>>;
}

const BrandsSwiper = ({ data, active, setActive, setShowMenu }: Props) => {
  const swiper = useRef<any>();
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  useEffect(() => {
    if (swiper?.current) {
      swiper.current.slideTo(
        data.findIndex(
          (arr: any[]) => arr.filter((el) => el.text === active)?.length > 0
        )
      );
    }
  }, [active]);

  return (
    <div className="position-relative px-5">
      <Swiper
        onSwiper={(el) => {
          swiper.current = el;
        }}
        modules={[Navigation]}
        slidesPerView={4}
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
        effect="slide"
      >
        {data.map((arr, key) => (
          <SwiperSlide key={key}>
            {arr.map(({ text, link }, key) =>
              link === null ? (
                <div
                  key={key}
                  className={[
                    active === text ? style.letterActive : '',
                    style.letter,
                    'mt-1',
                  ]
                    .join(' ')
                    .trim()}
                >
                  {text}
                </div>
              ) : (
                <Link
                  key={key}
                  to={`product-list${link}`}
                  className={['d-block mt-1'].join(' ')}
                  onClick={() => setShowMenu(false)}
                >
                  {text}
                </Link>
              )
            )}
          </SwiperSlide>
        ))}
      </Swiper>
      <div className={[style.sliderPrev].join(' ')} ref={navigationPrevRef}>
        <FontAwesomeIcon
          icon={faAngleLeft}
          className={[''].join(' ')}
          size={'3x'}
        />
      </div>
      <div className={[style.sliderNext].join(' ')} ref={navigationNextRef}>
        <FontAwesomeIcon
          icon={faAngleRight}
          className={[''].join(' ')}
          size={'3x'}
        />
      </div>
    </div>
  );
};

export default BrandsSwiper;
