import * as React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { faExternalLinkSquareAlt } from '@fortawesome/pro-regular-svg-icons';
// @ts-ignore
import style from './Legal.module.scss?module';

export default function Legal() {
  return (
    <>
      <Row className={'pt-4 pb-5'}>
        <Col>
          <Link to={'/terms'} target={'_blank'}>
            <Button size={'sm'} variant={'secondary'} className={'me-2 mb-2'}>
              Imprint <FontAwesomeIcon icon={faExternalLinkSquareAlt} />
            </Button>
          </Link>
          <br className={'d-block d-sm-none'} />
          <Link to={'/privacy-policy'} target={'_blank'}>
            <Button size={'sm'} variant={'secondary'} className={'me-2 mb-2'}>
              Privacy Policy <FontAwesomeIcon icon={faExternalLinkSquareAlt} />
            </Button>
          </Link>
          <br className={'d-block d-sm-none'} />
          <a
            href={'https://files.naskorsports.com/gtc/GTC_english.pdf'}
            target={'_blank'}
          >
            <Button size={'sm'} variant={'secondary'} className={'me-2 mb-2'}>
              Terms & Conditions{' '}
              <FontAwesomeIcon icon={faExternalLinkSquareAlt} />
            </Button>
          </a>
        </Col>
      </Row>
    </>
  );
}
