import * as React from 'react';
import { Row, Col, Image, Button } from 'react-bootstrap';
import { INewProductAttribute } from '../NewProduct';
import { formatCurrencyEur } from '../../../../../../Helper/formatter';

export default function SwiperSlideNewProduct(props: {
  new_product_attribute: INewProductAttribute;
  showProductsAndScrollToProductSize?: (productSizeId: number) => void;
}) {
  return (
    <div style={{ zIndex: '100' }}>
      <div>
        <Image
          src={props.new_product_attribute?.image_background_public_url}
          className={'w-100'}
        />
      </div>
      <div className={'text-center'}>
        <div className={'fw-bold pt-3'}>
          {props.new_product_attribute?.product_size.name}
        </div>
        <div>{props.new_product_attribute?.product_attribute.name}</div>
        <div className={'fw-bold fst-italic text-danger'}>
          {formatCurrencyEur(props.new_product_attribute?.current_price)}
        </div>
        {/*<div className={'pt-3'}>
          <Button
            onClick={() => {
              props.showProductsAndScrollToProductSize(
                props.new_product_attribute?.product_size.id
              );
            }}
            variant={'primary'}
            size={'sm'}
            style={{
              textTransform: 'uppercase',
              whiteSpace: 'nowrap',
            }}
            className={'fw-bold fst-italic'}
          >
            Shop now
          </Button>
        </div>*/}
      </div>
    </div>
  );
}
