import React, { useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { getRequest, postRequest } from "../../../../Helper/ApiClient";

export default function SkuMapping() {
  const [currentBrandId, setCurrentBrandId] = useState<Number | null>(null);
  const [flavourRefData, setFlavourRefData] = useState<any>([]);
  const [uploadModal, setUploadModal] = useState<any>({ show: false });
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const brandsRequest = getRequest("/api/brand/list");
  const createFlavourRefMutation = postRequest(
    "/api/user/productReferences/save_reference"
  );
  const uploadExcelMutation = postRequest(
    "/api/user/productReferences/uploadSheet"
  );
  const downloadExcelRequest = getRequest(
    "/api/user/productReferences/downloadSheet",
    undefined,
    { enabled: false },
    {
      responseType: "blob",
    }
  );

  const fetchFlavoursRefData = async (brand_id) => {
    const res = await fetch(
      "/api/customer_flavour_references?brand_id=" + brand_id
    );
    return res.json();
  };

  const brandSelectOnChangeHandler = (
    ev: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setCurrentBrandId(parseInt(ev.target.value));
    let _fetchFlavoursRefData = fetchFlavoursRefData(ev.target.value);
    _fetchFlavoursRefData.then((data) => {
      setFlavourRefData(data.flavours);
    });
  };

  const flavourRefInputOnBlurHandler = (
    ev: React.FocusEvent<HTMLInputElement>
  ) => {
    let fid = ev.target.dataset.product_attribute_id;
    let formData = new FormData();
    if (fid) {
      formData.append("product_attribute_id", fid.toString());
    }
    formData.append("cpc_reference", ev.currentTarget.value);
    createFlavourRefMutation.mutate(formData, {
      onSuccess: () => {
        let _fetchFlavoursRefData = fetchFlavoursRefData(currentBrandId);
        _fetchFlavoursRefData.then((data) => {
          setFlavourRefData(data.flavours);
        });
      },
    });
  };

  const handleDownload = () => {
    downloadExcelRequest.refetch().then(({ isSuccess, data, error }) => {
      if (isSuccess) {
        const downloadUrl = URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", "References upload.xlsx");
        document.body.appendChild(link);
        link.click();
        link.remove();
      } else {
        alert(error.message);
      }
    });
  };

  const handleFileOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const files = Array.from(event.target.files);
      setUploadedFile(files[0]);
    }
  };

  const handleUpload = () => {
    if (uploadedFile) {
      let formData = new FormData();
      formData.append("filepond", uploadedFile);
      uploadExcelMutation.mutate(formData, {
        onSuccess: () => {
          fetchFlavoursRefData(currentBrandId);
          setUploadModal({ show: false });
        },
      });
    }
  };

  return (
    <>
      <Container>
        <Row>
          <Col>
            <h1>SKU Mapping</h1>
            <p>
              Match and manage your internal product IDs with our products and
              benefit from an easy and fast order upload via Excel.
            </p>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-center">
            <Button
              className="mt-2"
              variant="secondary"
              onClick={handleDownload}
            >
              {downloadExcelRequest.isLoading
                ? "Downloading..."
                : "Download References"}
            </Button>
            <div className="mx-2"></div>
            <Button
              className="mt-2"
              onClick={() => setUploadModal({ show: true })}
            >
              Upload References
            </Button>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col xs={3}>
            <Form.Label>Brand</Form.Label>
            <Form.Select onChange={brandSelectOnChangeHandler}>
              <option disabled={flavourRefData.length > 0}>-</option>
              {brandsRequest.isSuccess &&
                brandsRequest.data.data.map((brand: any) => {
                  return (
                    <option key={brand.id} value={brand.id}>
                      {brand.name}
                    </option>
                  );
                })}
            </Form.Select>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col>
            <Table>
              <thead>
                <tr>
                  <th>Brand</th>
                  <th>Category</th>
                  <th>Product</th>
                  <th>Attribute</th>
                  <th>Reference</th>
                </tr>
              </thead>
              <tbody>
                {flavourRefData.length < 1 && (
                  <tr>
                    <td colSpan={5} className="text-center p-5">
                      <i>No results.</i>
                    </td>
                  </tr>
                )}
                {flavourRefData.map((_flavourRefData: any) => {
                  return (
                    <tr key={_flavourRefData.id}>
                      <td>{_flavourRefData.product_size.brand.name}</td>
                      <td>{_flavourRefData.product_size.category.name}</td>
                      <td>{_flavourRefData.product_size.name}</td>
                      <td>{_flavourRefData.name}</td>
                      <td>
                        <Form.Control
                          type="text"
                          placeholder="Reference"
                          onBlur={flavourRefInputOnBlurHandler}
                          data-product_attribute_id={_flavourRefData.id}
                          defaultValue={
                            _flavourRefData.user_reference.reference
                          }
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
      <Modal
        centered
        show={uploadModal.show}
        onHide={() => setUploadModal({ show: false })}
      >
        <Modal.Header closeButton>Upload User Reference Excel</Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>Drag & drop your files or browse.</Form.Label>
            <Form.Control
              type="file"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              onChange={handleFileOnChange}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={uploadedFile === null}
            variant="primary"
            onClick={handleUpload}
          >
            {uploadExcelMutation.isLoading ? "Uploading ..." : "Upload"}
          </Button>
          <Button
            variant="secondary"
            onClick={() => setUploadModal({ show: false })}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
