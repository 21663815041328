import React, { useState } from 'react';
import MenuItem from '../MenuItem/MenuItem';
import MegaMenuBrands from './MegaMenuBrands/MegaMenuBrands';

const MenuBrands = () => {
  const [showMenu, setShowMenu] = useState(false);
  return (
    <MenuItem
      text="Brands"
      onMouseEnter={() => setShowMenu(true)}
      onMouseLeave={() => setShowMenu(false)}
    >
      <MegaMenuBrands setShowMenu={setShowMenu} show={showMenu} />
    </MenuItem>
  );
};

export default MenuBrands;
