import React from "react";
// @ts-ignore
import style from "./GymAssortmentBadge.module.scss?module";

const GymAssortmentBadge = () => {
  return (
    <a
      href="/product-list?gym_assortment=1"
      className={[style.gymAssortmentBadge, "px-2"].join(" ")}
    >
      Gym assortment
    </a>
  );
};

export default GymAssortmentBadge;
