import * as React from 'react';
import ProductAttribute from '../ProductAttribute/ProductAttribute';
import { Col, Row } from 'react-bootstrap';
import ButtonMutation from '../../ButtonMutation/ButtonMutation';
import { useEffect, useState } from 'react';
import { postRequest } from '../../../Helper/ApiClient';
import { IVtsProductAttribute } from '../VtsProductList';
import { formatCurrencyEur } from '../../../Helper/formatter';

export interface IVtsBasketPosition {
  product_attribute_id: number;
  quantity: number;
  price: number;
}
export default function ProductAttributes({
  price,
  product_attributes,
  positions,
  setPositions,
}: {
  price: number;
  product_attributes: IVtsProductAttribute[];
  positions: IVtsBasketPosition[];
  setPositions: React.Dispatch<React.SetStateAction<IVtsBasketPosition[]>>;
}) {
  const [paPositions, setPaPositions] = useState<IVtsBasketPosition[]>([]);
  const [resetInputValue, setResetInputValue] = useState(false);
  const addToBasketMutation = postRequest(
    '/api/onlineshop/vts-basket-add-position'
  );

  const setPositionByPaIdQtyPrice = (_product_attribute_id, _qty, _price) => {
    const _positions = [...positions];
    const foundProductAttribute = _positions.find(
      (_pa) => _pa.product_attribute_id === _product_attribute_id
    );
    if (foundProductAttribute) {
      foundProductAttribute.quantity = _qty;
      setPositions(_positions);
    } else {
      const currentPaForBasket = [...positions];
      currentPaForBasket.push({
        product_attribute_id: _product_attribute_id,
        quantity: _qty,
        price: _price,
      });
      setPositions(currentPaForBasket);
    }

    const _paPositions = [...paPositions];
    const foundPaPositionProductAttribute = _paPositions.find(
      (_pa) => _pa.product_attribute_id === _product_attribute_id
    );
    if (foundPaPositionProductAttribute) {
      foundPaPositionProductAttribute.quantity = _qty;
      setPaPositions(_paPositions);
    } else {
      const currentPaPositionForBasket = [...paPositions];
      currentPaPositionForBasket.push({
        product_attribute_id: _product_attribute_id,
        quantity: _qty,
        price: _price,
      });
      setPaPositions(currentPaPositionForBasket);
    }
  };

  const removePositionByPaId = (productId: number) => {
    setPaPositions((prevPositions) =>
      prevPositions.filter(
        (position) => position.product_attribute_id !== productId
      )
    );
    setPositions((prevPositions) =>
      prevPositions.filter(
        (position) => position.product_attribute_id !== productId
      )
    );
  };

  const handleProductAttributeOnChange = (
    ev: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (resetInputValue) {
      setResetInputValue(false);
    }
    const _pa_id = Number(ev.currentTarget.dataset.product_attribute_id);
    const _price = Number(ev.currentTarget.dataset.price);
    const _qty = Number(ev.currentTarget.value);
    if (_qty > 0) {
      setPositionByPaIdQtyPrice(_pa_id, _qty, _price);
    } else {
      removePositionByPaId(_pa_id);
    }
  };

  const handleClickAddToBasket = () => {
    let formData = new FormData();
    formData.append('positionsJson', JSON.stringify(paPositions));
    addToBasketMutation.mutate(formData, {
      onSuccess: () => {
        paPositions.map((position) => {
          removePositionByPaId(position.product_attribute_id);
        })
        setResetInputValue(true);
      },
    });
  };

  const getUnits = () => {
    const totalUnits = paPositions.reduce((acc, obj) => acc + obj.quantity, 0);
    return totalUnits;
  };

  const getTotal = () => {
    const totalPrice = paPositions.reduce((acc, obj) => acc + (obj.price * obj.quantity), 0);
    return formatCurrencyEur(totalPrice);
  };

  useEffect(() => {
    if (positions.length === 0) {
      setPaPositions([]);
      setResetInputValue(true);
    }
  }, [positions])

  return (
    <>
      {product_attributes.map((product_attribute) => {
        return (
          <ProductAttribute
            price={price}
            product_attribute={product_attribute}
            key={product_attribute.id}
            onChange={handleProductAttributeOnChange}
            reset={resetInputValue}
          />
        );
      })}
      <Row className="my-4">
        <Col className="d-block d-md-flex align-items-center justify-content-end fw-bold mb-2 mb-lg-0 text-end">
          {paPositions.length > 0 && (
            <span className="me-md-4 me-2">UNITS: {getUnits()}</span>
          )}
          {paPositions.length > 0 && (
            <span className="me-md-4 me-2">TOTAL: {getTotal()}</span>
          )}
          <ButtonMutation
            onClick={handleClickAddToBasket}
            mutationResult={addToBasketMutation}
          >
            Add to order
          </ButtonMutation>
        </Col>
      </Row>
      <hr />
    </>
  );
}
