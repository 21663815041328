import * as React from "react";
import { Route, Routes } from "react-router-dom";
import Exhibitors from "./Exhibitors/Exhibitors";

export default function Admin() {
  return (
    <Routes>
      <Route path={"/exhibitors"} element={<Exhibitors />} />
    </Routes>
  );
}
