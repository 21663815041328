import { faBagShopping } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import Link from '../../../../Abstracts/Link/Link';
import UnitCount from '../../../../Abstracts/UnitCount/UnitCount';
import { formatCurrencyEur } from '../../../../Helper/formatter';
// @ts-ignore
import style from './Basket.module.scss?module';

const Basket = ({ basketInfo }) => {
  const isBigPriceLength = basketInfo?.basket_total_price.toString().length > 10;
  // const xsMobileSize = window.innerWidth <= 425;
  //  ${xsMobileSize && isBigPriceLength && 'd-none'}

  return (
    basketInfo && (
      <Link
        opacityOnHover={false}
        className={[style.basket, 'text-end'].join(' ')}
        to="/basket"
      >
        <div className="d-flex justify-content-end">
          <div
            className={`position-relative ${
              isBigPriceLength ? 'd-lg-none' : 'd-lg-flex'
            } d-xl-flex`}
          >
            <FontAwesomeIcon icon={faBagShopping} />
            <UnitCount
              className="end-0"
              value={basketInfo?.basket_total_quantity}
            />
          </div>
          {basketInfo && basketInfo?.basket_total_quantity >= 1 && (
            <span
              className={`fst-italic ms-2 ${style.basketPrice}`}
            >
              {formatCurrencyEur(basketInfo?.basket_total_price)}
            </span>
          )}
        </div>
      </Link>
    )
  );
};

export default Basket;
