import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Api from './Api/Api';
import Eta from './Eta/Eta';
import JustIn from './JustIn/JustIn';
import PriceChange from './PriceChange/PriceChange';
import Discontinued from './Discontinued/Discontinued';
import AvailableSoon from './AvailableSoon/AvailableSoon';

export default function ServiceCenter() {
  return (
    <>
      <Routes>
        <Route path="/api/*" element={<Api />} />
        <Route path="/just-in" element={<JustIn />} />
        <Route path="/eta" element={<Eta />} />
        <Route path="/price-change" element={<PriceChange />} />
        <Route path="/discontinued" element={<Discontinued />} />
        <Route path="/available-soon" element={<AvailableSoon />} />
      </Routes>
    </>
  );
}
