import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Row } from 'react-bootstrap';
import { VtsContext } from '../../../../VirtualTradeShow';
import { VtsBasketSamplePositionInterface } from '../../../../../../../Interface/Vts';
import useDeleteMutation from '../../../../../../Helper/useDeleteMutation';
import { faTrash } from '@fortawesome/pro-regular-svg-icons';

export default function VtsBasketSamplePosition(props: {
  basketSamplePosition: VtsBasketSamplePositionInterface;
  refetchBasket: () => void;
}) {
  const vtsContext = useContext(VtsContext);
  const deleteMutation = useDeleteMutation(
    '/api/onlineshop/vts-basket-sample-positions/' +
      props.basketSamplePosition.id
  );
  const [deleted, setDeleted] = useState(false);

  useEffect(() => {
    if (deleteMutation.isError) {
      setDeleted(false);
    }
  }, [deleteMutation.isError]);

  useEffect(() => {
    if (deleteMutation.isSuccess) {
      props.refetchBasket();
    }
  }, [deleteMutation.isSuccess]);

  const handleDelete = () => {
    setDeleted(true);
    deleteMutation.mutate();
  };

  if (deleted) {
    return null;
  }
  return (
    <>
      {vtsContext.vts && vtsContext.vts.is_confirmation ? (
        <Row>
          <Col>
            {
              props.basketSamplePosition.vts_sample_product_attribute
                .vts_sample_product_size.vts_exhibitor.name
            }
            <br />
            {
              props.basketSamplePosition.vts_sample_product_attribute
                .vts_sample_product_size.name
            }
            <br />
            {props.basketSamplePosition.vts_sample_product_attribute.name}
          </Col>
          <Col>
            <strong>x{props.basketSamplePosition.quantity}</strong>
          </Col>
        </Row>
      ) : (
        <>
          <Row className={'pt-1'}>
            <Col
              xl={4}
              md={5}
              xs={12}
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              <strong>
                {
                  props.basketSamplePosition.vts_sample_product_attribute
                    .vts_sample_product_size.vts_exhibitor.name
                }
              </strong>{' '}
              {
                props.basketSamplePosition.vts_sample_product_attribute
                  .vts_sample_product_size.name
              }
            </Col>
            <Col
              xl={3}
              md={4}
              xs={6}
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {props.basketSamplePosition.vts_sample_product_attribute.name}
            </Col>
            <Col xl={1} md={2} xs={3} className={'text-end'}>
              <strong>x {props.basketSamplePosition.quantity}</strong>
            </Col>
            <Col md={1} xs={3} className={'text-end'}>
              <Button onClick={handleDelete} size={'sm'} variant={'secondary'}>
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            </Col>
          </Row>
          {deleteMutation.isError && (
            <Row>
              <Col className={'text-danger text-end'}>
                {deleteMutation.error.message}
              </Col>
            </Row>
          )}
        </>
      )}
    </>
  );
}
