import React from 'react';
import LoginForm from '../../../../../Abstracts/Forms/LoginForm/LoginForm';

const UserMenuLogin = () => {
  return (
    <div>
      <h3 className="fw-bold mb-3">Account Login</h3>
      <LoginForm />
    </div>
  );
};

export default UserMenuLogin;
