import React, { useEffect } from 'react';

// @ts-ignore
import styles from './VtsOrder.module.scss?module';
import { Navigate } from 'react-router-dom';
import Confirmation from './Confirmation/Confirmation';
import { Col, Container, Row } from 'react-bootstrap';
import Countdown from '../../Abstracts/Countdown/Countdown';
import { getRequest } from '../../Helper/ApiClient';
import LoginForm from '../../Abstracts/Forms/LoginForm/LoginForm';
import { useUser } from '../../Context/UserContext';
import LoadingSpinner from '../../Abstracts/LoadingSpinner/LoadingSpinner';

export default function VtsOrder() {
  const userContext = useUser();
  const hasVtsBasketRequest = getRequest('/api/onlineshop/has-vts-basket');

  /*
        Idee:
            Wir verlinken immer auf /vts-order, also in service center und mit allen bannern.
            Hier entwickeln wir dann die nötigen Logiken und leiten weiter auf die richtigen Seiten.

        Todo IT:
            - Vor Montag 10:00, Meldung mit Info, wann er confirmen kann
            - Nach Montag 10:00, wenn er keinen Basket in confirmation oder retrieval hat, dann Meldung "schade, dass du nicht teilgenommen hast"
            - Nach Montag 10:00, wenn retrieval erfüllt ist, dann Meldung "Danke, für deine Teilnahme, alles versendet."
            - Nach Montag 10:00, und Basket, und nicht confirmed, dann weiterleiten nach /vts-order/confirmation
            - Nach Montag 10:00, und Basket, confirmed, dann weiterleiten nach /vts-order/retrieval - oder eine andere Seite wenn es eine fertige gibt

        Todo live nehmen / Marketing:
            - Symfony Routen sind mit AAA "auskommentiert".
            - Im Service Center ist ein Link auf /vts-order vorbereitet, auskommentiert.
            - Marketing wird noch einen static-Banner für die Startseite senden, um den alten zu ersetzen, wird verlinken auf /vts-order.
            - Marketing wird ab Montag 00:00 Slideshow Bilder setzen, die auf /vts-order verlinken.

        Wir können sogar /virtual-trade-show Route umleiten zu /vts-order, wenn wir wollen.
    */

  useEffect(() => {
    if (userContext.user && !hasVtsBasketRequest.data) {
      hasVtsBasketRequest.refetch();
    }
  }, [userContext.user]);

  if (userContext.loading) {
    return (
      <Container className={'py-6 text-center'}>
        <Row>
          <Col>
            <LoadingSpinner />
          </Col>
        </Row>
      </Container>
    );
  }

  if (!userContext.user) {
    return (
      <Container className={'py-6'}>
        <Row>
          <Col>
            <h1 className={'fst-italic fw-bolder'}>
              Virtual trade show - order confirmation
            </h1>
          </Col>
        </Row>
        <Row className={'pt-4'}>
          <Col lg={6}>
            <LoginForm />
          </Col>
        </Row>
      </Container>
    );
  }

  if (!hasVtsBasketRequest.data) {
    return (
      <Container className={'py-6 text-center'}>
        <Row>
          <Col>
            <LoadingSpinner />
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <>
      <Container>
        <Row className={'pt-6 pb-5'}>
          <Col>
            <h1 className={'fst-italic fw-bolder'}>
              Virtual trade show - order confirmation
            </h1>
          </Col>
        </Row>
      </Container>

      {/*Before monday 10 am*/}
      {hasVtsBasketRequest.isSuccess &&
        !hasVtsBasketRequest.data.show_baskets && (
          <Container className={'pb-6'}>
            <Row className={'pt-5 text-center fs-6'}>
              <Col>
                Confirmation period begins on Monday 6th of May at 10am and
                finishes on Monday 13th of May at 11.59pm.
              </Col>
            </Row>
            <Row>
              <Col className={'text-center'}>
                <Countdown dateString={'2024-05-06T10:00:00.000+02:00'} />
              </Col>
            </Row>
          </Container>
        )}

      {hasVtsBasketRequest.isSuccess &&
        hasVtsBasketRequest.data.show_baskets &&
        (hasVtsBasketRequest.data.has_remaining_or_release_basket ||
        hasVtsBasketRequest.data.has_confirmed_basket ? (
          <Navigate to={'/vts-order-release'} />
        ) : hasVtsBasketRequest.data.has_first_basket ? (
          hasVtsBasketRequest.data.confirmation_period_end ? (
            <Container className={'pb-6'}>
              <Row>
                <Col>
                  The confirmation period is over.
                  <br />
                  Too bad that you didn't confirm your VTS quantities on time.
                  <br />
                  We hope to see you next year.
                </Col>
              </Row>
            </Container>
          ) : (
            <Confirmation />
          )
        ) : (
          <Container className={'pb-6'}>
            <Row>
              <Col>
                Too bad that you didn´t take part at the Virtual Trade Show
                2024. We hope to see you next year!
              </Col>
            </Row>
          </Container>
        ))}
    </>
  );
}
