import React, {useEffect} from 'react';

// @ts-ignore
import styles from './Confirmation.module.scss?module';
import VtsBasketPositions from '../../VirtualTradeShow/TopRightContents/VtsBasketContent/VtsBasketPositions/VtsBasketPositions';
import { Col, Container, Row } from 'react-bootstrap';
import ButtonMutation from '../../../Abstracts/ButtonMutation/ButtonMutation';
import usePatchMutation from '../../../Helper/usePatchMutation';
import { getRequest } from '../../../Helper/ApiClient';
import { VtsBasketInterface } from '../../../../Interface/Vts';

export default function Confirmation() {
  const basketPatchMutation = usePatchMutation('/api/onlineshop/vts-basket');

  const basketRequest = getRequest<VtsBasketInterface>(
    '/api/onlineshop/vts-basket?status=confirmation'
  );

  useEffect(() => {
    if (basketPatchMutation.isSuccess) {
      window.location.reload();
    }
  }, [basketPatchMutation.isSuccess]);

  return (
    <Container className={'py-5'}>
      <Row className={'pb-3'}>
        <Col>
          <p className={'text-muted'}>Please confirm your order now!</p>
          <p className={'text-muted'}>
            After your confirmation you can release your Virtual Fitness Show
            order or parts of it. Several partial releases are possible and will
            be invoiced accordingly.
            <br />
            Final order release is 30th of June 2024.
            <br />
            In regard to product availability, we advise you to be fast: FIRST
            COME FIRST SERVE.
          </p>
          <p className={'text-danger fw-bold'}>
            All products not released until the 30th of June, will be deleted
            from your basket.
          </p>
        </Col>
      </Row>
      <VtsBasketPositions basketRequest={basketRequest} />
      {basketRequest.isSuccess && (
        <Row className={'pt-5'}>
          <Col>
            <ButtonMutation
              data-confirm="1"
              onClick={basketPatchMutation.onUpdateHandler}
              mutationResult={basketPatchMutation}
            >
              Confirm
            </ButtonMutation>
          </Col>
        </Row>
      )}
    </Container>
  );
}
