import React, { useEffect, useState } from 'react';

// @ts-ignore
import styles from './Exhibition.module.scss?module';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import ShowBooth from './ShowBooth';
import GoToPano from './GoToPano';

// @ts-ignore
const removepano_func: any = removepano;
// @ts-ignore
const embedpano_func: any = embedpano;

export default function Exhibition() {
  const location = useLocation();
  const params = useParams();
  const [krpano, setKrpano] = useState<any>();

  useEffect(() => {
    // https://krpano.com/docu/js/

    if (removepano_func) {
      removepano_func('exhibition');
    }
    if (embedpano_func) {
      embedpano_func({
        id: 'exhibition',
        vars: {
          startScene: getSceneName(params?.panoNr),
          stand: getBoothName(params?.boothNr),
        },
        xml: 'https://files.naskorsports.com/vtour/public/2024/krpano.xml',
        target: 'show_container',
        passQueryParameters: 'xml',
        onready: (krpano) => {
          setKrpano(krpano);
        },
      });
    }

    if (removepano_func) {
      return function cleanup() {
        setKrpano(null);
        removepano_func('exhibition');
      };
    }
  }, []);

  const [searchParams] = useSearchParams();
  useEffect(() => {
    if (krpano && !location.state?.viaGoToPano) {
      if (params.panoNr) {
        krpano.call("loadscene('" + getSceneName(params.panoNr) + "');");
        if (params.boothNr) {
          krpano.call(
            'lookat(get(hotspot[' +
              getBoothName(params.boothNr) +
              '].ath),5,100);'
          );
        }
      }
    }

    if (searchParams.get('popup')) {
      // @ts-ignore
      showBooth(params.boothNr);
    }
  }, [params.panoNr, params.boothNr]);

  return (
    <>
      <div id="show_container" className={styles.showContainer}></div>
      <ShowBooth />
      <GoToPano />
    </>
  );
}

function getSceneName(panoNr) {
  return panoNr ? `scene_b2b_${panoNr}` : 'scene_b2b_1';
}

function getBoothName(boothNr) {
  return boothNr ? `stand_${boothNr}` : 'start';
}
