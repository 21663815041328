import * as React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Content from '../../Abstracts/Content/Content';
// @ts-ignore
import style from './Information.module.scss?module';
import Legal from './Legal/Legal';
import Faq from './Faq/Faq';

export default function Information() {
  return (
    <Content>
      <Container className={style.Information}>
        <Row>
          <Col>
            <h1>Information</h1>
          </Col>
        </Row>
        <Faq />
        <Legal />
      </Container>
    </Content>
  );
}
