// @ts-nocheck
import React from "react";

export default function LogoAsText() {

    return (
        <span style={{textTransform:"uppercase",fontStyle:"italic"}}>
            <span style={{fontWeight:"bold"}}>
                Naskor
            </span>
            Sports
        </span>
    );

}