import * as React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { getRequest } from '../../../../../../Helper/ApiClient';

export default function MediaCenterFilter({
  filter,
  setFilter,
}: {
  filter: string[];
  setFilter: React.Dispatch<React.SetStateAction<string[]>>;
}) {
  const mediaCategoriesRequest = getRequest(
    '/api/virtual-trade-show/media-categories'
  );

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.currentTarget.checked) {
      let _filter = [...filter];
      _filter.push(event.currentTarget.value);
      setFilter(_filter);
    } else {
      let _filter = [...filter];
      let index = _filter.indexOf(event.currentTarget.value);
      _filter.splice(index, 1);
      setFilter(_filter);
    }
  };

  return (
    <>
      <p className="h2 fs-6">Filter media categories</p>
      {mediaCategoriesRequest.isSuccess &&
        mediaCategoriesRequest.data &&
        mediaCategoriesRequest.data.map((mediaCategory: any) => {
          return (
            <Form.Check
              defaultChecked={true}
              key={mediaCategory.id}
              label={mediaCategory.name}
              value={mediaCategory.id}
              onChange={handleFilterChange}
            />
          );
        })}
    </>
  );
}
