// @ts-nocheck
import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import LogoAsText from '../../Abstracts/LogoAsText/LogoAsText';
import Link from '../../Abstracts/Link/Link';

// @ts-ignore
import styles from './Footer.module.scss?module';

export default function Footer() {
  return (
    <React.Fragment>
      <Container fluid={true} className={['pb-6', styles.Footer].join(' ')}>
        <Container>
          <Row>
            <Col xs={6} md={3}>
              <div>
                <div className={'pt-lg-6 pt-3'}>
                  <strong>About us</strong>
                </div>
                <div className={'pt-lg-2 pt-1'}>
                  <Link to={'/about-us'}>Our story</Link>
                </div>
                <div className={'pt-lg-2 pt-1'}>
                  <Link to={'/brands'}>Our brands</Link>
                </div>
                <div className={'pt-lg-2 pt-1'}>
                  <Link to="/contact">Contact</Link>
                </div>
                <div className={'pt-lg-2 pt-1'}>
                  <Link to="/careers">Careers</Link>
                </div>
              </div>
            </Col>
            <Col xs={6} md={3}>
              <div>
                <div className={'pt-lg-6 pt-3'}>
                  <strong>Services</strong>
                </div>
                <div className={'pt-lg-2 pt-1'}>
                  <Link to={'/faq'}>FAQ</Link>
                </div>
                <div className={'pt-lg-2 pt-1'}>
                  <Link to={'/terms'}>Terms and conditions</Link>
                </div>
                <div className={'pt-lg-2 pt-1'}>
                  <Link to={'/privacy-policy'}>Privacy policy</Link>
                </div>
              </div>
            </Col>
            <Col>
              <Row>
                <Col>
                  <div>
                    <div className={'pt-lg-6 pt-3'}>
                      <strong>Follow us</strong>
                    </div>
                    <div className={'pt-lg-2 pt-1'}>
                      <a
                        href={'https://www.facebook.com/NASKORSPORTS'}
                        target={'_blank'}
                      >
                        <Image
                          style={{ height: '35px' }}
                          src={
                            'https://ams3.digitaloceanspaces.com/naskorsports01/document/2020/09/9834.png'
                          }
                        />
                      </a>
                      <a
                        href={'https://twitter.com/naskorsports?lang=de'}
                        target={'_blank'}
                      >
                        <Image
                          style={{ height: '35px' }}
                          src={
                            'https://ams3.digitaloceanspaces.com/naskorsports01/document/2020/09/9832.svg'
                          }
                          className={'ms-2'}
                        />
                      </a>
                    </div>
                  </div>
                </Col>
                <Col>
                  <div>
                    <div className={'pt-lg-6 pt-3'}>
                      <strong>Payment options</strong>
                    </div>
                    <div className={'pt-lg-2 pt-1'}>
                      <Image
                        style={{ height: '25px' }}
                        src={
                          'https://ams3.digitaloceanspaces.com/naskorsports01/document/2020/09/9830.svg'
                        }
                      />
                      <Image
                        style={{ height: '25px' }}
                        src={
                          'https://ams3.digitaloceanspaces.com/naskorsports01/document/2020/09/9828.svg'
                        }
                        className={'ms-2'}
                      />
                      <Image
                        style={{ height: '25px' }}
                        src={
                          'https://ams3.digitaloceanspaces.com/naskorsports01/document/2020/09/9829.svg'
                        }
                        className={'ms-2'}
                      />
                      <Image
                        style={{ height: '25px' }}
                        src={
                          'https://ams3.digitaloceanspaces.com/naskorsports01/document/2020/09/9838.png'
                        }
                        className={'ms-2'}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className={'pt-lg-3 pt-3'}>
                    <div className={'pb-2'}>
                      <strong>We deliver with</strong>
                    </div>
                    <div className={'pt-lg-2 pt-1'}>
                      <Image
                        style={{ height: '25px' }}
                        src={
                          'https://ams3.digitaloceanspaces.com/naskorsports01/document/2020/09/9837.svg'
                        }
                      />
                      <Image
                        style={{ height: '25px' }}
                        src={
                          'https://ams3.digitaloceanspaces.com/naskorsports01/document/2020/09/9836.svg'
                        }
                        className={'ms-2'}
                      />
                      <Image
                        style={{ height: '25px' }}
                        src={
                          'https://ams3.digitaloceanspaces.com/naskorsports01/document/2020/09/9835.svg'
                        }
                        className={'ms-2'}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className={styles.disclaimer}>
            <Col md={3} className={'pt-5'}>
              Copyright © {new Date().getFullYear()} <LogoAsText />.<br />
              All rights reserved.
            </Col>
            <Col md={9} className={'pt-md-5 pt-2'}>
              <LogoAsText />
              <span className={'d-none d-md-inline'}> - </span>
              <br className={'d-md-none'} />
              Trappistenweg 8 - 5932 NB Tegelen - The Netherlands
              <span className={'d-none d-md-inline'}> - </span>
              <br className={'d-md-none'} />
              <Link href="mailto:info@naskorsports.com">
                info@naskorsports.com
              </Link>{' '}
              - Tel: <Link href={'tel:+31777201200'}>+31 777201-200</Link>
              <br />
              Chamber of Commerce: 52876144
              <span className={'d-none d-md-inline'}> - </span>
              <br className={'d-md-none'} />
              VAT Number: NL850643089B01
            </Col>
          </Row>
        </Container>
      </Container>
    </React.Fragment>
  );
}
