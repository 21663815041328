import { matchPath } from "react-router-dom";

export function matchMultiplePaths(paths: string[]) {
  for (let i = 0; i < paths.length; i++) {
    const match = matchPath({ path: paths[i] }, window.location.pathname);
    if (match) {
      return match;
    }
    if (i == paths.length - 1) {
      return null;
    }
  }
}
