import * as React from 'react';
import { Col, FormControl, Row } from 'react-bootstrap';
import { IVtsSampleProductAttribute } from '../../Samples';
import { useEffect, useRef, useState } from 'react';

export default function SampleProductAttribute({
  reset,
  onChange,
  sample_product_attribute,
}: {
  reset: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  sample_product_attribute: IVtsSampleProductAttribute;
}) {
  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (reset) {
      if (inputRef.current) {
        inputRef.current.value = '';
      }
    }
  }, [reset]);
  return (
    <Row className={'py-1'}>
      <Col md={9} xs={6}>
        {sample_product_attribute.name}
      </Col>
      <Col md={3} xs={6}>
        {sample_product_attribute.stock_available > 0 ? (
          <FormControl
            ref={inputRef}
            style={{
              backgroundColor: '#e1e3e7',
            }}
            onChange={onChange}
            type="number"
            data-sample_product_attribute_id={sample_product_attribute.id}
            min={0}
            max={sample_product_attribute.stock_available}
            className="w-100 rounded-0 border-none text-end"
          />
        ) : (
          'reserved'
        )}
      </Col>
    </Row>
  );
}
