import { useState, useEffect, useRef } from 'react';

interface ToggleDivHook {
  isVisible: boolean;
  toggleVisibility: () => void;
  hideDiv: () => void;
  divRef: React.RefObject<any>;
}

const useToggleDiv = (): ToggleDivHook => {
  const [isVisible, setIsVisible] = useState(false);
  const divRef = useRef<HTMLDivElement>(null);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const hideDiv = () => {
    setIsVisible(false);
  };

  const handleOutsideClick = (event) => {
    const modalIsOpen = document.querySelector('.modal.show');
    if (divRef.current && !divRef.current.contains(event.target)) {
      if (!modalIsOpen) {
        hideDiv();
      }
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  return { isVisible, toggleVisibility, hideDiv, divRef };
};

export default useToggleDiv;
