import * as React from 'react';
// @ts-ignore
import styles from './Lobby.module.scss?module';
import { NavLink } from 'react-router-dom';
import { useUser } from '../../../../Context/UserContext';
import Popup from '../../../../Abstracts/Popup/Popup';
import LoginForm from '../../../../Abstracts/Forms/LoginForm/LoginForm';

export default function Lobby() {
  const userContext = useUser();
  const [showAccount, setShowAccount] = React.useState<boolean>(false);

  return (
    <div
      style={{
        backgroundImage:
          'url(https://files.naskorsports.com/shop/virtual-trade-show/vts-2024-lobby.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        flexGrow: '1',
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      {!userContext.loading && (
        <>
          {userContext.user ? (
            <NavLink end to={'/virtual-trade-show/exhibition'}>
              <div className={styles.exhibition} />
            </NavLink>
          ) : (
            <>
              <div
                style={{ cursor: 'pointer' }}
                className={styles.exhibition}
                onClick={() => {
                  setShowAccount(true);
                }}
              />
              <Popup
                centered
                show={showAccount}
                onHide={() => {
                  setShowAccount(false);
                }}
              >
                <div className={'p-5'}>
                  <div>
                    <h2 className={'fst-italic fw-bold fs-3 pb-3'}>
                      My account
                    </h2>
                  </div>
                  <LoginForm />
                </div>
              </Popup>
            </>
          )}
        </>
      )}
      <NavLink end to={'/virtual-trade-show/information'}>
        <div className={styles.information} />
      </NavLink>
    </div>
  );
}
