import * as React from 'react';
import { Col, Container, Modal, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getRequest } from '../../../../Helper/ApiClient';
//@ts-ignore
import style from './ExhibitorsContent.module.scss?module';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Spinner1 from '../../../../Abstracts/Spinner1/Spinner1';

export default function ExhibitorsContent(props) {
  const exhibitorOrganiserRequest = getRequest(
    '/api/virtual-trade-show/exhibitor-organiser'
  );
  const exhibitorsRequest = getRequest('/api/virtual-trade-show/exhibitors');
  return (
    <>
      <p className="text-muted text-center pb-5">
        Welcome to the Virtual Trade Show 2024! <br />
        Click on one of the exhibiting brands and get send directly to their
        booth.
      </p>
      {exhibitorOrganiserRequest.isSuccess &&
        exhibitorOrganiserRequest.data && (
          <div onClick={props.onRequestClose}>
            <Link
              to={
                '/virtual-trade-show/exhibition/' +
                exhibitorOrganiserRequest.data.panoNumberB2b +
                '/' +
                exhibitorOrganiserRequest.data.boothNumberB2b
              }
              className={'inherit'}
            >
              <strong>NaskorSports Booth</strong>
            </Link>
          </div>
        )}

      {exhibitorsRequest.isLoading && (
        <Spinner1 className={'text-center p-5'} />
      )}
      {exhibitorsRequest.isSuccess && exhibitorsRequest.data && (
        <div className={style.columnContainer}>
          {exhibitorsRequest.data.map((exhibitor: any, index: number) => {
            return (
              <div
                key={index}
                onClick={props.onRequestClose}
                className={style.item}
              >
                <Link
                  to={
                    '/virtual-trade-show/exhibition/' +
                    exhibitor.panoNumberB2b +
                    '/' +
                    exhibitor.boothNumberB2b
                  }
                  className={cn('inherit', style.itemLink)}
                >
                  {exhibitor.name}
                </Link>
              </div>
            );
          })}
        </div>
      )}

      {/*{exhibitorsRequest.isSuccess && exhibitorsRequest.data && (
        <Row xs={2} md={3} lg={4}>
          {exhibitorsRequest.data.map((exhibitor: any, index: number) => {
            return (
              <Col
                key={index}
                onClick={props.onRequestClose}
                className={'mt-2'}
              >
                <Link
                  to={"/virtual-trade-show/exhibition/" + exhibitor.panoNumberB2b + "/" + exhibitor.boothNumberB2b}
                  className={cn('inherit')}
                  style={{
                    display: 'inline-block',
                    maxWidth: '100%',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {exhibitor.name}
                </Link>
              </Col>
            );
          })}
        </Row>
      )}*/}
    </>
  );
}
