import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import {
  faXTwitter,
  faYoutube,
  faFacebook,
  faInstagram,
} from '@fortawesome/free-brands-svg-icons';
import { IVtsExhibitor } from '../../../Main/Admin/Exhibitors/Exhibitors';
import {postRequest} from "../../../../../Helper/ApiClient";
import {useEffect} from "react";

export default function Video({ exhibitor }: { exhibitor: IVtsExhibitor }) {
  const viewNaskorVideoMutation = postRequest(
    '/api/virtual-trade-show/view-naskor-video'
  );

  useEffect(() => {
    if (exhibitor.organiser) {
      const timeout = setTimeout(() => {
        viewNaskorVideoMutation.mutate(null);
      }, 30000);
      return () => clearTimeout(timeout);
    }
  }, []);

  return (
    <>
      <Row>
        <Col lg={8} className="offset-lg-2">
          <Row>
            <Col className="text-center">
              <h2 className={'popupHeadline'}>Welcome to {exhibitor.name}</h2>
              {(exhibitor.organiser || exhibitor.video_public_link) && (
                <video style={{ width: '100%' }} controls autoPlay={true}>
                  <source
                    src={
                      exhibitor.organiser
                        ? 'https://files.naskorsports.com/media/video/2023-03-31_naskorsports_imagefilm-720p.mp4'
                        : exhibitor.video_public_link
                    }
                  />
                </video>
              )}
            </Col>
          </Row>
          <Row>
            <Col lg={6} xs={12}>
              {exhibitor.website_url && (
                <a
                  className="inherit"
                  target="_blank"
                  rel="noreferrer"
                  href={exhibitor.website_url.trim().startsWith("http") ? exhibitor.website_url.trim() : "//" + exhibitor.website_url.trim()}
                >
                  {exhibitor.website_url}
                </a>
              )}
            </Col>
            <Col
              lg={6}
              xs={12}
              style={{ fontSize: '1.2rem' }}
              className="text-lg-end"
            >
              {exhibitor.youtube_url && (
                <a
                  className="inherit"
                  target="_blank"
                  rel="noreferrer"
                  href={exhibitor.youtube_url ?? '#'}
                >
                  <FontAwesomeIcon icon={faYoutube} className={'me-2'} />
                </a>
              )}
              {exhibitor.twitter_url && (
                <a
                  className="inherit"
                  target="_blank"
                  rel="noreferrer"
                  href={exhibitor.twitter_url ?? '#'}
                >
                  <FontAwesomeIcon icon={faXTwitter} className={'me-2'} />
                </a>
              )}
              {exhibitor.instagram_url && (
                <a
                  className="inherit"
                  target="_blank"
                  rel="noreferrer"
                  href={exhibitor.instagram_url ?? '#'}
                >
                  <FontAwesomeIcon icon={faInstagram} className={'me-2'} />
                </a>
              )}
              {exhibitor.facebook_url && (
                <a
                  className="inherit"
                  target="_blank"
                  rel="noreferrer"
                  href={exhibitor.facebook_url ?? '#'}
                >
                  <FontAwesomeIcon icon={faFacebook} className={'me-2'} />
                </a>
              )}
            </Col>
          </Row>
          <Row>
            <Col className={'pt-5'}>
              <p key={exhibitor.description} style={{ whiteSpace: 'pre-wrap' }}>
                {exhibitor.description}
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}
