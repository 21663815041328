import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { IVtsExhibitor } from '../../../Main/Admin/Exhibitors/Exhibitors';
import { getRequest } from '../../../../../Helper/ApiClient';
import SampleProductList from './SampleProductList/SampleProductList';
import LoadingSpinner from '../../../../../Abstracts/LoadingSpinner/LoadingSpinner';

export interface ISampleProductSize {
  id: number;
  erp_id: number;
  name: string;
  sample_product_attributes: IVtsSampleProductAttribute[];
}

export interface IVtsSampleProductAttribute {
  id: number;
  erp_id: number;
  name: string;
  stock_available: number;
}

export default function Samples({ exhibitor }: { exhibitor: IVtsExhibitor }) {
  const sampleProductsRequest = getRequest<ISampleProductSize[] | undefined>(
    `/api/onlineshop/vts-exhibitors/${exhibitor.id}/samples`
  );
  const userSampleLimitsRequest = getRequest(
    `/api/onlineshop/vts-exhibitors/${exhibitor.id}/user-sample-limits`
  );
  return (
    <>
      <Row>
        <Col>
          <h2 className={'popupHeadline'}>{exhibitor.name} Samples</h2>
        </Col>
      </Row>
      {userSampleLimitsRequest.isSuccess && userSampleLimitsRequest.data && (
        <Row>
          <Col>
            <p className="py-3 text-muted text-center">
              You have {userSampleLimitsRequest.data.exhibitor_samples_quantity}{' '}
              {exhibitor.name} samples in your order. You can choose a maximum
              of {userSampleLimitsRequest.data.exhibitor_samples_limit} samples
              per exhibitor (total maximum:{' '}
              {userSampleLimitsRequest.data.total_samples_limit}).
              <br />
              Please note: Better be fast - sample stock is limited! See all
              collected samples in your order overview.
            </p>
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          {sampleProductsRequest.isLoading && (
            <div className={'text-center py-5'}>
              <LoadingSpinner />
            </div>
          )}
          {sampleProductsRequest.isSuccess && sampleProductsRequest.data && (
            <SampleProductList
              sampleProducts={sampleProductsRequest.data}
              afterAddPositions={userSampleLimitsRequest.refetch}
            />
          )}
        </Col>
      </Row>
    </>
  );
}
