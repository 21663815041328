import * as React from 'react';
import { RandomProductResponse } from '../../../../../../Interface/Vts';
import Modal1 from '../../../../../Abstracts/Modal1/Modal1';
import {Col, Row} from "react-bootstrap";
import ProductSize from "../../../../../Abstracts/VtsProductList/ProductSize/ProductSize";
import Button from '../../../../../Abstracts/Button/Button';

export default function RobotPopup({ randomProduct, onClose }: { randomProduct?: RandomProductResponse|null, onClose: () => void }) {

  const vtsProductUrl = `/virtual-trade-show/exhibition/${randomProduct?.exhibitor_panoNr}/${randomProduct?.exhibitor_boothNr}?popup=video`;

  const getRandomProductText = (randomProduct: RandomProductResponse) => {
    const productLink = (
      <a
        href={`/virtual-trade-show/exhibition/${randomProduct.exhibitor_panoNr}/${randomProduct.exhibitor_boothNr}?popup=video`}
      >
        {randomProduct.product_name}
      </a>
    );

    var texts: React.ReactNode[] = [];

    if (
      randomProduct.sample_product_sizes?.[0].sample_product_attributes?.[0]
    ) {
      texts = [
        <>
          Congratulations on receiving <span style={{fontWeight: 'bold', fontSize: 'large'}}>{randomProduct.quantity}</span> sample{randomProduct.quantity > 1 ? 's' : ''} of{' '}
          <span style={{fontWeight: 'bold', fontSize: 'large'}}>
            {randomProduct.brand_name}'s{' '}
              {
                randomProduct.sample_product_sizes?.[0]
                ?.sample_product_attributes[0].sample_product_size_name
            }
          </span>
          !
          <br />
          Ready to discover more? Click here to explore{' '}
          {randomProduct.brand_name}'s {productLink} and access exclusive
          discounts at the {randomProduct.brand_name}'s booth. 🎉
        </>,
        <>
          With <span style={{fontWeight: 'bold', fontSize: 'large'}}>{randomProduct.quantity}</span> sample{randomProduct.quantity > 1 ? 's' : ''} of <span style={{fontWeight: 'bold', fontSize: 'large'}}>{randomProduct.brand_name}'s{' '}
          {
            randomProduct.sample_product_sizes?.[0]
              ?.sample_product_attributes[0].sample_product_size_name
          } </span>
          , you're ready to broaden your offerings.
          <br />
          Explore the benefits of {randomProduct.brand_name} and try out{' '}
          {productLink}. Take advantage of the great discounts! 🌟
        </>,
        <>
          Your fitness journey just got more exciting with{' '}
          <span style={{fontWeight: 'bold', fontSize: 'large'}}>{randomProduct.quantity}</span> sample{randomProduct.quantity > 1 ? 's' : ''} of {randomProduct.brand_name}'s{' '}
          <span style={{fontWeight: 'bold', fontSize: 'large'}}>{
            randomProduct.sample_product_sizes?.[0]
              ?.sample_product_attributes[0].sample_product_size_name
          } </span>{' '}
            automatically added to your basket.
          <br />
          Delve into the advantages of {randomProduct.brand_name}'s{' '}
          {productLink} and access special discounts at their booth. 🏋️‍♂️
        </>,
        <>
          Your basket now includes <span style={{fontWeight: 'bold', fontSize: 'large'}}>{randomProduct.quantity}</span> sample{randomProduct.quantity > 1 ? 's' : ''} of{' '}
          <span style={{fontWeight: 'bold', fontSize: 'large'}}>{randomProduct.brand_name}'s{' '}
            {
              randomProduct.sample_product_sizes?.[0]
              ?.sample_product_attributes[0].sample_product_size_name
          } </span>
          , paving the way for further exploration.
          <br />
          Dive into the benefits of {randomProduct.brand_name}'s{' '}
          {productLink} and enjoy special discounts exclusively at our trade
          show. 🛍️
        </>,
      ];
    } else {
      texts = [
        <>
          Haven't added {randomProduct.brand_name}'s {productLink} to your
          inventory yet?
          <br />
          Now's the perfect time to try it out and get your VTS discount! 🏋️‍♂️
        </>,
        <>
          Take your business to the next level with {randomProduct.brand_name}'s{' '}
          {productLink}. If you haven't tried it yet, now's your chance!
          <br />
          Visit {randomProduct.brand_name} at the trade show and enjoy exclusive
          discounts. 🤖
        </>,
        <>
          Stay ahead of the competition with {randomProduct.brand_name}'s{' '}
          {productLink} in your inventory.
          <br />
          Deliver exceptional value and results to your customers. 🤖
        </>,
        <>
          Ready to take your fitness business to new heights? If you haven't
          purchased {randomProduct.brand_name}'s {productLink} before, there's
          no better time to try it out!
          <br />
          Seize the opportunity to purchase at exclusive discounts available! 🚀
        </>,
      ];
    }

    const randomIndex = Math.floor(Math.random() * texts.length);
    return texts[randomIndex];
  };

  if (!randomProduct) {
    return <></>;
  }

  const randomText = getRandomProductText(randomProduct)

  return (
    <Modal1 show={true} onHide={onClose}>
      <Row>
        <Col xs={'auto'}>
          <img
            src="/images/vts/robot.png"
            style={{ height: '180px', float: 'left' }}
          />
        </Col>
        <Col
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <div className="d-none d-lg-block" style={{fontSize: 'large'}}>
            {randomText}
          </div>
          <div className="d-lg-none">
            {randomText}
          </div>
        </Col>
      </Row>
      <hr />
      <ProductSize
        positions={[]}
        setPositions={() => {}}
        product={randomProduct.product_size}
        key={randomProduct.product_size.id}
        scrollIntoView={false}
      />
      <Row className="pt-4 text-center">
        <Col>
          <a href={vtsProductUrl}>
            <Button>Go to {randomProduct.brand_name} Booth</Button>
          </a>
        </Col>
      </Row>
    </Modal1>
  );
}
