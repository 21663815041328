import * as React from 'react';
import { Table } from 'react-bootstrap';
import { getRequest } from '../../../../../Helper/ApiClient';
import LoadingSpinner from '../../../../../Abstracts/LoadingSpinner/LoadingSpinner';

export default function UserRewards() {
  const userRewardsRequest = getRequest('/api/virtual-trade-show/user-rewards');

  return (
    <>
      <div className={'fs-4 fw-bold pt-3 pb-4'}>
        My points:{' '}
        {userRewardsRequest.data &&
          userRewardsRequest.data.reduce(
            (acc, cur) => Number(acc) + Number(cur.user_points),
            0
          )}
      </div>

      {userRewardsRequest.isSuccess && userRewardsRequest.data ? (
        <Table>
          <thead>
            <tr>
              <td>Activity</td>
              <td>Point</td>
              <td>Activity count</td>
              <td>Earned points</td>
            </tr>
          </thead>
          <tbody>
            {userRewardsRequest.data.map((userReward: any, index: number) => {
              return (
                <tr key={index}>
                  <td>{userReward.description}</td>
                  <td>{userReward.points}</td>
                  <td>{userReward.count}</td>
                  <td>{userReward.user_points}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      ) : (
        <div className={'py-5 text-center'}>
          <LoadingSpinner />
        </div>
      )}
    </>
  );
}
