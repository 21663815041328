import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";

export default function GoToPano() {
    const { panoNr } = useParams();
    const [visitedIds, setVisitedIds] = React.useState<string[]>([panoNr ?? '']);
    const navigate = useNavigate();

    const validPairs = new Set([
        '10-2',
        '7-6',
        '9-11',
        '21-13',
        '18-17',
        '12-14'
    ]);

    // @ts-ignore
    window.goToPano = (panoNr, boothNr) => {
        if(boothNr === 'lobby') {
            navigate('/virtual-trade-show/lobby');
        }
        else {
            let regEx = /scene_b2b_(?<pano>\d+)/;
            // @ts-ignore
            const { pano } = regEx.exec(panoNr).groups;
            let url = `/virtual-trade-show/exhibition/${pano}`;
            if (boothNr) {
              url += `/${boothNr}`;
            }
            navigate(url, {
                state: {
                    viaGoToPano: true,
                },
            });
            updateVisitedIds(pano);
        }
    };

    // Update the visited IDs and check the visit direction
    const updateVisitedIds = (newPanoId: string) => {
        let lastVisited = visitedIds[visitedIds.length - 1];
        let newVisitedPair = lastVisited ? `${lastVisited}-${newPanoId}` : null;

        // Add the new pano ID to the list
        setVisitedIds([...visitedIds, newPanoId]);

        // Check if the new pair is valid
        if (newVisitedPair && validPairs.has(newVisitedPair)) {
             // @ts-ignore
            window.showBooth("robot");
        }
    }

    return null;
}