import React from 'react';

// @ts-ignore
import styles from './Spinner1.module.scss?module';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';

export default function Spinner1(props: { className?: any }) {
  return (
    <div className={props.className}>
      <FontAwesomeIcon icon={faSpinnerThird} spin={true} size={'3x'} />
    </div>
  );
}
