import * as React from "react";
import {useState} from "react";
import ExhibitorBoothPopup from "./ExhibitorBoothPopup/ExhibitorBoothPopup";
import {getRequest} from "../../../../Helper/ApiClient";
import RobotPopup from "./RobotPopup/RobotPopup";
import { RandomProductResponse } from "../../../../../Interface/Vts";

export default function ShowBooth() {
    const [booth, setBooth] = useState<string|null>(null);
    const exhibitorOrganiserRequest = getRequest('/api/virtual-trade-show/exhibitor-organiser');

    const randomProductRequest = getRequest<RandomProductResponse | null>(
      '/api/recommended-top-product', undefined, { enabled: false }
    );

    // @ts-ignore
    window.showBooth = (booth) => {
        // console.log(booth);
        if (booth === 'robot') {
          randomProductRequest.refetch();
        }
        setBooth(booth.toString());
    };

    if(!booth) {
        return null;
    }
    if(!exhibitorOrganiserRequest.isSuccess) {
        return null;
    }

    return (
      <>
        {booth === 'robot' ? (
          <>
          {randomProductRequest.isSuccess && !randomProductRequest.isFetching && (
            <RobotPopup randomProduct={randomProductRequest.data} onClose={() => setBooth(null)} />
          )}
          </>
        ) : (
          <ExhibitorBoothPopup
            boothNumber={parseInt(booth)}
            onClose={() => {
              setBooth(null);
            }}
          />
        )}
      </>
    );
}