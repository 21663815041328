import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import { getRequest } from "../../../../../../../Helper/ApiClient";
import { IProductAttribute } from "../../../../../ProductList";
// @ts-ignore
import style from "./NotifyUser.module.scss?module";

const NotifyUser = ({
  has_notify_me,
  id,
}: Pick<IProductAttribute, "has_notify_me" | "id">) => {
  const [hasNotifyMe, setHasNotifyMe] = useState<boolean>(has_notify_me);
  const { refetch, isFetching } = getRequest(
    `/api/flavour/${id}/updateNotify/${hasNotifyMe ? 0 : 1}`,
    undefined,
    { enabled: false, onSuccess: () => setHasNotifyMe(!hasNotifyMe) }
  );
  return (
    <div
      className={[
        "w-100 h-100 form-control text-center border-0 fw-bold lh-sm d-flex align-items-center justify-content-center",
        style.notifyUser,
      ].join(" ")}
    >
      {isFetching ? (
        <Spinner animation="border" size="sm" />
      ) : (
        <a
          href={void 0}
          role="button"
          className="lh-1"
          onClick={() => refetch()}
        >
          <small>
            {hasNotifyMe ? "You'll be notified" : "Notify me when available"}
          </small>
        </a>
      )}
    </div>
  );
};

export default NotifyUser;
