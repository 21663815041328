import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { useState } from "react";
import { useUser } from "../../../../../../Context/UserContext";
// @ts-ignore
import style from "./FlipCard.module.scss?module";

export default function FlipCard({
  imageInitial,
  imageFlipped,
  viewed,
  syncHighlightInteraction,
}: {
  imageInitial: string;
  imageFlipped: string;
  viewed?: boolean;
  syncHighlightInteraction?: Function;
}) {
  const [hoverTimeout, setHoverTimeout] = useState<any>(null);
  const { user } = useUser();
  const onMouseEnter = (event: React.MouseEvent) => {
    event.preventDefault();
    if (syncHighlightInteraction) {
      setHoverTimeout(setTimeout(() => syncHighlightInteraction(), 900));
    }
  };

  const onMouseLeave = (event: React.MouseEvent) => {
    event.preventDefault();
    clearTimeout(hoverTimeout);
  };

  return (
    <div
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={style.flipCard}
    >
      {user && viewed != undefined && (
        <div className={[style.status, viewed ? style.visited : ""].join(" ")}>
          <FontAwesomeIcon icon={faCheck} size={"lg"} />
        </div>
      )}

      <div className={style.flipCardInner}>
        <div className={style.flipCardFront}>
          <img className={style.imageBackground} src={imageInitial} />
        </div>
        <div className={style.flipCardBack}>
          <img className={style.imageBackground} src={imageFlipped} />
        </div>
      </div>
    </div>
  );
}
