import React, { useState } from 'react';
import MenuItem from '../MenuItem/MenuItem';
import MegaMenuSale from './MegaMenuSale/MegaMenuSale';

const MenuSale = () => {
  const [showMenu, setShowMenu] = useState(false);
  return (
    <MenuItem
      text="Sale %"
      onMouseEnter={() => setShowMenu(true)}
      onMouseLeave={() => setShowMenu(false)}
    >
      <MegaMenuSale show={showMenu} setShowMenu={setShowMenu} />
    </MenuItem>
  );
};

export default MenuSale;
