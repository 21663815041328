import React from 'react';
import ExhibitorPopup from '../../../Abstracts/ExhibitorPopup/ExhibitorPopup';
import { getRequest } from '../../../../../Helper/ApiClient';
import { IVtsExhibitor } from '../../Admin/Exhibitors/Exhibitors';
import { useSearchParams } from 'react-router-dom';

export default function ExhibitorBoothPopup(props: {
  boothNumber: number;
  onClose: () => void;
}) {
  const exhibitorRequest = getRequest<IVtsExhibitor>(
    '/api/onlineshop/vts-booth-exhibitor/' + props.boothNumber
  );

  // if(!exhibitorRequest.data) {
  //   return null;
  // }

  const [searchParams] = useSearchParams();

  const tab = searchParams.get('popup')
    ? searchParams.get('popup')?.toUpperCase()
    : undefined;
  const productSize_id = searchParams.get('productSize_id')
    ? Number(searchParams.get('productSize_id'))
    : undefined;

  return (
    <ExhibitorPopup
      exhibitor={exhibitorRequest.data}
      isOpen={true}
      onClose={props.onClose}
      exhibitor_tab={tab}
      scrollIntoViewProductSize_Id={productSize_id}
    />
  );
}
