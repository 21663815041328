import * as React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
// @ts-ignore
import styles from './NcwLogin.module.scss?module';
import LoginForm from '../../../../Abstracts/Forms/LoginForm/LoginForm';
const IMAGE_SRC = 'https://files.naskorsports.com/shop/newcomer-week/';
export default function NcwLogin() {
  return (
    <>
      <Container
        className="d-flex position-relative"
        fluid
        style={{ flexGrow: 1 }}
      >
        <div
          className={styles.NcwHomeBGImage}
          style={{
            backgroundImage: `url("${IMAGE_SRC}home/ncw-home-bg.jpg")`,
          }}
        ></div>
        <div className={styles.Overlay} aria-label="overlay" />
        <div className="my-auto" style={{ flexGrow: 1 }}>
          <Row className="justify-content-center align-items-center">
            <Col lg={4} md={5} sm={6}>
              <Card>
                <Card.Body>
                  <LoginForm />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
}
