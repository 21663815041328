import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { formatCurrencyEur } from '../../../Helper/formatter';
import ProductAttributes, {
  IVtsBasketPosition,
} from '../ProductAttributes/ProductAttributes';
import { useEffect, useRef } from 'react';
import { IVtsProduct } from '../VtsProductList';

export default function ProductSize({
  product,
  scrollIntoView,
  positions,
  setPositions
}: {
  product: IVtsProduct;
  scrollIntoView: boolean;
  positions: IVtsBasketPosition[];
  setPositions: React.Dispatch<React.SetStateAction<IVtsBasketPosition[]>>;
}) {
  const productRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scrollIntoView) {
      const timeout = setTimeout(() => {
        productRef?.current?.scrollIntoView();
      }, 200);
      return () => clearTimeout(timeout);
    }
  }, []);

  // const _product = product;

  return (
    <>
      <Row ref={productRef}>
        <Col xs={12} className="mb-3 d-block d-sm-none">
          <Row>
            <Col lg={6}>
              <h4 className="fw-bolder mb-2">{product.name}</h4>
            </Col>
            <Col lg={6}>
              {product.promotion_text && (
                <div className={'w-100 px-3 py-2 bg-danger'}>
                  <span
                    className={
                      'd-block text-white fw-bold fst-italic text-center'
                    }
                  >
                    {product.promotion_text}
                  </span>
                </div>
              )}
            </Col>
          </Row>
        </Col>
        <Col md={3} xs={6}>
          <img src={product.image_url} alt={product.name} className="w-100" />
        </Col>
        <Col md={9} xs={6}>
          <Row className="mb-3 d-none d-sm-flex">
            <Col lg={6}>
              <h4 className="fw-bolder mb-2">{product.name}</h4>
            </Col>
            <Col lg={6}>
              {product.promotion_text && (
                <div className={'w-100 px-3 py-2 bg-danger'}>
                  <span
                    className={
                      'd-block text-white fw-bold fst-italic text-center'
                    }
                  >
                    {product.promotion_text}
                  </span>
                </div>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <div>
                <div className="small">
                  <span className="fw-bold">Category: </span>
                  <span>{product.category_name}</span>
                </div>
                <div className="small">
                  <span className="fw-bold">Size: </span>
                  <span>{`${product.unit_value} ${product.unit_name}`}</span>
                </div>
                <div className="small">
                  <span className="fw-bold">Units per case: </span>
                  <span>{product.units_per_case}</span>
                </div>
              </div>
            </Col>
            <Col className="text-end">
              <div className="d-flex flex-column align-items-end justify-content-end">
                <small className="fst-italic text-decoration-line-through">
                  {formatCurrencyEur(product.regular_price)}
                </small>
                <h4 className={'fw-bold fst-italic text-danger'}>
                  {formatCurrencyEur(product.current_price)}
                </h4>
              </div>
            </Col>
          </Row>
        </Col>
        <Col xs={12}>
          <ProductAttributes
            positions={positions}
            setPositions={setPositions}
            price={product.current_price}
            product_attributes={product.product_attributes}
          />
        </Col>
      </Row>
    </>
  )
}
