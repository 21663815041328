import React, { useState } from 'react';
import { Col, Container, Row, Table } from 'react-bootstrap';
import Button from '../../../../../Abstracts/Button/Button';
import { getRequest } from '../../../../../Helper/ApiClient';
import Content from '../../../Abstracts/Content/Content';
import ExhibitorBoothPopup from "../../Exhibition/ExhibitorBoothPopup/ExhibitorBoothPopup";

export interface IVtsExhibitor {
  id: number;
  name: string;
  description: string;
  boothNumberB2b: number;
  panoNumberB2b: number;
  logo_image_url: string;
  video_public_link: string;
  website_url: string;
  facebook_url: string;
  instagram_url: string;
  youtube_url: string;
  twitter_url: string;
  complete_media_kit_url: string;
  organiser: boolean;
  has_new_product_attributes?: boolean;
  has_samples?: boolean;
  has_highlights?: boolean;
  has_media_files?: boolean;
}

export default function Exhibitors() {
  const exhibitorsRequest = getRequest<IVtsExhibitor[] | undefined>(
    '/api/virtual-trade-show/exhibitors'
  );
  const [showModal, setShowModal] = useState(false);
  const [exhibitor, setExhibitor] = useState<IVtsExhibitor>();
  const openPopup = (_exhibitor: IVtsExhibitor) => {
    setShowModal(true);
    setExhibitor(_exhibitor);
  };

  return (
    <>
      <Content>
        <Container>
          <Row>
            <Col>
              <h1>Exhibitors</h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <Table>
                <thead>
                  <tr>
                    <th>Exhibitor Name</th>
                    <th>B2B</th>
                    <th>Pano No.</th>
                    <th>Booth No.</th>
                  </tr>
                </thead>
                <tbody>
                  {exhibitorsRequest.isSuccess &&
                    exhibitorsRequest.data &&
                    exhibitorsRequest.data.map((exhibitor) => {
                      return (
                        <tr key={exhibitor.id}>
                          <td>{exhibitor.name}</td>
                          <td>
                            <Button onClick={() => openPopup(exhibitor)}>
                              B2B Popup
                            </Button>
                          </td>
                          <td>{exhibitor.panoNumberB2b}</td>
                          <td>{exhibitor.boothNumberB2b}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      </Content>
      {exhibitor && showModal && (
        <ExhibitorBoothPopup
          boothNumber={exhibitor.boothNumberB2b}
          onClose={() => {
            setShowModal(false);
          }}
        />
      )}
    </>
  );
}
