import React, { useState } from 'react';
import Brands from './Brands';
import Categories from './Categories';
import Sale from './Sale';
// @ts-ignore
import style from './Menu.module.scss?module';
import {
  faUser,
  faCartShopping,
  faHeart,
} from '@fortawesome/free-solid-svg-icons';
import { useUser } from '../../../Context/UserContext';
import { formatCurrency, formatCurrencyEur } from '../../../Helper/formatter';
import MenuItem from './MenuItem/MenuItem';
import Link from '../../../Abstracts/Link/Link';

export enum MenuKey {
  brands = 'BRANDS',
  categories = 'CATEGORIES',
  sale = 'SALE',
}

interface IProps {
  closeSidebar: () => void;
}

const Menu: React.FC<IProps> = ({ closeSidebar }) => {
  const [activeKey, setActiveKey] = useState<string | null>(null);
  const { user } = useUser();

  return (
    <div>
      {!activeKey && (
        <div className={style.navigation}>
          <div className="fw-bold">Navigation</div>
          {!user ? (
            <a
              href="/login"
              className="ps-3 py-1 text-black text-decoration-none"
            >
              <span>Login / Register</span>
            </a>
          ) : (
            <a
              href="/logout"
              className="ps-3 py-1 text-black text-decoration-none"
            >
              <span>Logout</span>
            </a>
          )}

          {user && (
            <>
              <a
                href="/favourites"
                className="ps-3 py-1 text-black text-decoration-none"
              >
                <span>Favourites {`(${user?.favourites_count})`}</span>
              </a>
              {user?.vts_orders_visible &&
                  <Link to="/vts-order" className="ps-3 py-1 text-black" onClick={closeSidebar}>
                      <span>Virtual Trade Show Order</span>
                  </Link>
              }
              <Link to="/my-orders" className="ps-3 py-1 text-black" onClick={closeSidebar}>
                <span>My Orders</span>
              </Link>
              <Link to="/complaints" className="ps-3 py-1 text-black" onClick={closeSidebar}>
                Complaints
              </Link>
              <a
                href="/my-unpaid-invoices"
                className="ps-3 py-1 text-black text-decoration-none"
              >
                <span>{`Unpaid Invoices: ${formatCurrencyEur(
                  user?.erp_unpaid_sums ?? 0
                )}`}</span>
              </a>
              <Link to="/payment-methods" className="ps-3 py-1 text-black" onClick={closeSidebar}>
                Payment methods
              </Link>
            </>
          )}
          {user?.has_api_access && (
            <Link to={'/service-center/api'} className="ps-3 py-1 text-black" onClick={closeSidebar}>
              Api
            </Link>
          )}
          {user?.bonus && (
            <Link to={'/bonus'} className="ps-3 py-1 text-black" onClick={closeSidebar}>
              Bonus
            </Link>
          )}
        </div>
      )}

      {!activeKey && (
        <MenuItem text="Gym Assortment" link="/product-list?gym_assortment=1" />
      )}

      <Brands
        active={activeKey}
        setActive={setActiveKey}
        closeSidebar={closeSidebar}
      />
      <Categories
        active={activeKey}
        setActive={setActiveKey}
        closeSidebar={closeSidebar}
      />

      <Sale
        active={activeKey}
        setActive={setActiveKey}
        closeSidebar={closeSidebar}
      />
    </div>
  );
};

export default Menu;
