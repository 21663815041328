import * as React from 'react';
import { Col, Image, Row, Table } from 'react-bootstrap';
import UserRewards from "./UserRewards/UserRewards";

export default function AccountContent() {
  return (
    <>
      <p className={'text-muted text-center'}>
        Among all visitors, we will reward the most interactive ones for their
        level of participation in the Virtual Trade Show.
        <br />
        For each activity shown in the account overview, you will earn points.
        <br />
        With the total count of collected points, you will compete with all
        visitors for the chance to win a brand new Apple MacBook Air 15"!
      </p>
      <UserRewards />
      <Row className={'pt-3'}>
        <Col
          xs={3}
          className={'d-flex flex-column justify-content-center'}
        ></Col>
        <Col xs={6}>
          <div className={'text-center'}>
            <strong>Apple MacBook Air 15"</strong>
          </div>
          <Image
            className={'w-100'}
            src={
              'https://files.naskorsports.com/shop/virtual-trade-show/vts-2024-prize.png'
            }
          />
        </Col>
        <Col
          xs={3}
          className={'d-flex flex-column justify-content-center'}
        ></Col>
      </Row>
    </>
  );
}
