import React, { useEffect } from 'react';
import { Col, Container, Row, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getRequest, postRequest } from '../../../Helper/ApiClient';
import { formatCurrencyEur } from '../../../Helper/formatter';

export default function JustIn() {
  const justInProductsRequest = getRequest('/api/onlineshop/just-in-product-attributes');
  const visitPageMutation = postRequest('/api/onlineshop/visit-page');

  useEffect(() => {
    let formData = new FormData();
    formData.append('page', 'just_in');
    visitPageMutation.mutate(formData);
  }, []);

  return (
    <Container>
      <Row className="pt-5">
        <Col>
          <h1>Just In</h1>
          <p>Check out all recently arrived products.</p>
        </Col>
      </Row>
      <Row>
        <Col>
          {justInProductsRequest.isLoading ? (
            <span>Loading...</span>
          ) : (
            <>
              <div className="d-none d-md-block">
                <Table borderless>
                  <thead>
                    <tr>
                      <th>Received, available since</th>
                      <th>Brand</th>
                      <th>Product</th>
                      <th>Flavour</th>
                      <th className="text-end">Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    {justInProductsRequest.isSuccess &&
                      justInProductsRequest.data &&
                      justInProductsRequest.data.length > 0 &&
                      justInProductsRequest.data.map(
                        (justInProduct: any, index: number) => {
                          return (
                            <tr
                              key={index}
                              className="border-bottom bg-secondary border-white border-5"
                            >
                              <td className="align-middle">
                                {justInProduct.just_in_date}
                              </td>
                              <td className="align-middle">
                                <Link
                                  to={
                                    '/productSize/' +
                                    justInProduct.product_size_erp_id
                                  }
                                  className="inherit"
                                >
                                  {justInProduct.brand_name}
                                </Link>
                              </td>
                              <td className="align-middle">
                                <Link
                                  to={
                                    '/productSize/' +
                                    justInProduct.product_size_erp_id
                                  }
                                  className="inherit"
                                >
                                  {justInProduct.product_size_name}
                                </Link>
                              </td>
                              <td className="align-middle">
                                <Link
                                  to={
                                    '/productSize/' +
                                    justInProduct.product_size_erp_id
                                  }
                                  className="inherit"
                                >
                                  {justInProduct.product_attribute_name}
                                </Link>
                              </td>
                              <td className="align-middle text-end">
                                {formatCurrencyEur(justInProduct.price)}
                              </td>
                            </tr>
                          );
                        }
                      )}

                    {justInProductsRequest.isSuccess &&
                      justInProductsRequest.data.length < 1 && (
                        <tr>
                          <td colSpan={5} className="p-5 text-center">
                            <i>No results.</i>
                          </td>
                        </tr>
                      )}
                  </tbody>
                </Table>
              </div>
              <div className="d-sm-block d-md-none">
                <Table borderless>
                  <tbody>
                    {justInProductsRequest.isSuccess &&
                      justInProductsRequest.data.length > 0 &&
                      justInProductsRequest.data.map((justInProduct: any) => {
                        return (
                          <React.Fragment key={justInProduct.id}>
                            <tr className="bg-secondary border-bottom">
                              <td>Received, available since:</td>
                              <td className="border-start">
                                <div>{justInProduct.just_in_date}</div>
                              </td>
                            </tr>
                            <tr className="bg-secondary border-bottom">
                              <td>Brand:</td>
                              <td className="border-start">
                                <div>
                                  <Link
                                    to={
                                      '/productSize/' +
                                      justInProduct.product_size_erp_id
                                    }
                                    className="inherit"
                                  >
                                    {justInProduct.brand_name}
                                  </Link>
                                </div>
                              </td>
                            </tr>
                            <tr className="bg-secondary border-bottom">
                              <td>Product:</td>
                              <td className="border-start">
                                <div>
                                  <Link
                                    to={
                                      '/productSize/' +
                                      justInProduct.product_size_erp_id
                                    }
                                    className="inherit"
                                  >
                                    {justInProduct.product_size_name}
                                  </Link>
                                </div>
                              </td>
                            </tr>
                            <tr className="bg-secondary border-bottom">
                              <td>Flavour:</td>
                              <td className="border-start">
                                <div>
                                  <Link
                                    to={
                                      '/productSize/' +
                                      justInProduct.product_size_erp_id
                                    }
                                    className="inherit"
                                  >
                                    {justInProduct.product_attribute_name}
                                  </Link>
                                </div>
                              </td>
                            </tr>
                            <tr className="bg-secondary border-bottom">
                              <td>Price:</td>
                              <td className="border-start">
                                <div>
                                  {formatCurrencyEur(justInProduct.price)}
                                </div>
                              </td>
                            </tr>
                            <tr className="bg-secondary border-bottom border-5 border-white"></tr>
                          </React.Fragment>
                        );
                      })}
                  </tbody>
                </Table>
              </div>
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
}
