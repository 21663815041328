import React from "react";
import { Spinner, SpinnerProps } from "react-bootstrap";
// @ts-ignore
import style from "./LoadingSpinner.module.scss?module";

const LoadingSpinner = (props: Omit<SpinnerProps, "animation">) => {
  return (
    <Spinner
      {...props}
      className={[style.loadingSpinner, props.className].join(" ")}
      animation="border"
    />
  );
};

export default LoadingSpinner;
