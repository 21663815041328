export default async function makeRequest(
  url: RequestInfo,
  method?: string,
  body?: BodyInit,
  headers?: {}
) {
  let all_headers = {
    ...headers,
    Accept: 'application/json',
    // 'Content-Type': 'application/json',
  };

  const init = {
    method,
    headers: all_headers,
    body,
  };

  let response = await fetch(url, init);
  const res = await response.json();
  if (!response.ok) {
    if (typeof res.detail !== 'undefined') {
      throw new Error(res.detail);
    } else {
      throw new Error(response.statusText);
    }
  }
  return res;
}
