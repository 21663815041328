import React, { useEffect, useRef } from "react";
import Menu from "./Menu/Menu";
// @ts-ignore
import style from "./Sidebar.module.scss?module";

const Sidebar = ({
  sidebarOpen,
  onSidebarToggle,
}: {
  sidebarOpen: boolean;
  onSidebarToggle: (toggle: boolean) => void;
}) => {
  const sidebarRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (sidebarOpen) {
      document
        .getElementsByTagName("body")[0]
        .style.setProperty("overflow", "hidden");
    } else {
      document
        .getElementsByTagName("body")[0]
        .style.setProperty("overflow", "auto");
    }
  }, [sidebarOpen]);

  return (
    <aside
      ref={sidebarRef}
      className={[
        "d-lg-none",
        style.SidebarWrapper,
        sidebarOpen ? style.visible : "",
      ]
        .join(" ")
        .trim()}
    >
      <Menu closeSidebar={() => onSidebarToggle(false)} />
    </aside>
  );
};

export default Sidebar;
