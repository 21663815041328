import * as React from 'react';
import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { getRequest } from '../../../../../Helper/ApiClient';

import { useSearchParams } from 'react-router-dom';
import VtsProductList, {
  IVtsProduct,
} from '../../../../../Abstracts/VtsProductList/VtsProductList';
import { IVtsExhibitor } from '../../../Main/Admin/Exhibitors/Exhibitors';
import LoadingSpinner from '../../../../../Abstracts/LoadingSpinner/LoadingSpinner';
import {useUser} from "../../../../../Context/UserContext";

const buildUrl = (exhibitor_id, searchParamsString) => {
  return (
    `/api/onlineshop/vts-exhibitors/${exhibitor_id}/product-sizes` +
    searchParamsString
  );
};

export default function PlaceOrder(props: {
  exhibitor: IVtsExhibitor;
  scrollIntoViewProductSizeId?: number;
}) {
  const userContext = useUser();
  const [searchParamsString, setSearchParamsString] = useState('');
  const [searchParams] = useSearchParams();
  const vtsProductsRequest = getRequest(
    buildUrl(props.exhibitor.id, searchParamsString),
    undefined,
    {
      onSuccess: (data) => setData(data),
    }
  );

  const [data, setData] = useState<IVtsProduct[]>();

  const handleFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    searchParams.set('sort', e.currentTarget.value);
    setSearchParamsString('?' + searchParams.toString());
  };

  return (
    <>
      <Row className={'pb-3'}>
        <Col>
          <h2 className={'popupHeadline'}>
            Place your order with {props.exhibitor.name}
          </h2>
          {userContext.user && !userContext.user.is_exhibitor &&
              <p className={'text-muted text-center'}>
                  Place your order here between May 02 and May 05, 2024.
                  <br />
                  Adjust and confirm your order in your account details at www.naskorsports.com between May 06 and May 13, 2024.
                  <br />
                  Flexible delivery feature will be available in your account details.
              </p>
          }
        </Col>
      </Row>
      <Row>
        <Col>
          {data ? (
            <VtsProductList
              products={data}
              onChangeFilter={handleFilterChange}
              scrollIntoViewProductSizeId={props.scrollIntoViewProductSizeId}
            />
          ) : (
            <div className={'text-center py-5'}>
              <LoadingSpinner />
            </div>
          )}
        </Col>
      </Row>
    </>
  );
}
