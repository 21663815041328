import React from 'react';
import { createPortal } from 'react-dom';
import Modal1 from '../../../../Abstracts/Modal1/Modal1';
import { Image, Container, Row, Col } from 'react-bootstrap';

//@ts-ignore
import styles from './SampleBoxModal.module.scss?module';

type SampleBoxModalProps = {
  showSampleBoxModal: boolean;
  setShowSampleBoxModal: React.Dispatch<React.SetStateAction<boolean>>;
  imgSrc: string;
};

const SampleBoxModal = ({
  showSampleBoxModal,
  setShowSampleBoxModal,
  imgSrc,
}) => {
  return createPortal(
    <Modal1
      closeVariant={'white'}
      className={styles.SampleBoxModal}
      show={showSampleBoxModal}
      onHide={() => {
        setShowSampleBoxModal(false);
      }}
    >
      <Container className="gap-4 d-flex flex-column">
        <div>
          <Row style={{ position: 'relative', minHeight: '200px' }}>
            <Col xs={9} sm={6} lg={3} className={styles.BoxImgContainer}>
              <Image
                width={'100%'}
                src={`${imgSrc}landing-page-box-frei.png`}
              />
            </Col>
          </Row>
          <Row>
            <Col
              className={`text-white text-center fs-4 ${styles.ContentContainer}`}
            >
              <p>Welcome to NaskorSport Newcomer Week!</p>
              <p>
                Start this Event while clicking on <strong>NEW BRANDS</strong>{' '}
                or <strong>NEW PRODUCTS</strong>
              </p>
              <p>and safe your exclusive sample box.</p>
              <p>The Box will automatically add to your order.</p>
            </Col>
          </Row>
        </div>
        <Row aria-label="spacer" />
      </Container>
    </Modal1>,
    document.body
  );
};

export default SampleBoxModal;
