import * as React from 'react';
import { Row, Col, Alert } from 'react-bootstrap';
import Button from '../../../../../Abstracts/Button/Button';
import { getRequest, postRequest } from '../../../../../Helper/ApiClient';
import ReactStars from 'react-stars';
import { useState } from 'react';
import { IVtsExhibitor } from '../../../Main/Admin/Exhibitors/Exhibitors';
import LoadingSpinner from '../../../../../Abstracts/LoadingSpinner/LoadingSpinner';

interface IQuestion {
  id: number;
  number: number;
  question: string;
  type: 'rating' | 'text' | 'bool';
}

export default function Feedback({ exhibitor }: { exhibitor: IVtsExhibitor }) {
  const [alert, setAlert] = useState('');
  const [isFeedbackGiven, setIsFeedbackGiven] = useState(false);
  const [feedbackAnswers, setFeedbackAnswers] = useState<any>({});
  const feedBackQuestionsRequest = getRequest(
    '/api/virtual-trade-show/feedbacks?exhibitor_id=' + exhibitor.id
  );

  const feedBackAnswerMutation = postRequest(
    '/api/virtual-trade-show/save-feedback'
  );

  const handleFeedbackAnswer = (
    question: IQuestion,
    value: string | number
  ) => {
    feedbackAnswers[question.id] = {
      vtsQuestion_id: question.id,
      value: value,
    };
    setFeedbackAnswers(feedbackAnswers);
    let questionsLen = feedBackQuestionsRequest.data.filter(
      (item) => item.type !== 'bool'
    ).length;
    let answersLen = Object.keys(feedbackAnswers).length;
    if (questionsLen == answersLen) {
      setIsFeedbackGiven(true);
      setAlert('');
    } else {
      setIsFeedbackGiven(false);
      // setAlert('Please answer all the questions!');
    }
  };

  const handleClickSendBtn = () => {
    if(!isFeedbackGiven) return setAlert('Please answer all the questions!');
    let formData = new FormData();
    formData.append('exhibitor_id', exhibitor.id.toString());
    formData.append('answersJson', JSON.stringify(feedbackAnswers));
    feedBackAnswerMutation.mutate(formData, {
      onSuccess: () => {
        feedBackQuestionsRequest.refetch();
      },
    });
  };

  if (feedBackQuestionsRequest.isLoading) {
    return (
      <Row className="py-5">
        <Col className={'text-center'}>
          <LoadingSpinner />
        </Col>
      </Row>
    );
  } else if (
    feedBackQuestionsRequest.isSuccess &&
    feedBackQuestionsRequest.data &&
    feedBackQuestionsRequest.data.length > 0
  ) {
    return (
      <>
        <Row className="mb-3">
          <Col>
            {alert.length > 0 && (
              <Alert variant="danger" dismissible onClose={() => setAlert('')}>
                {alert}
              </Alert>
            )}
            <h2 className={'popupHeadline'}>
              Your Feedback for {exhibitor.name}
            </h2>
            <p>
              Please answer the following questions on the rating scale. Answer
              all questions to submit your feedback.
            </p>
          </Col>
        </Row>
        <Row className={'pb-3'}>
          <Col>
            <hr />
          </Col>
        </Row>
        {feedBackQuestionsRequest.data.map((data: IQuestion) => {
          return (
            <Row key={data.id}>
              {data.type !== 'bool' && (
                <>
                  <Col>
                    <p>{data.question}</p>
                  </Col>
                  <Col>
                    {data.type == 'rating' && (
                      <ReactStars
                        count={4}
                        size={24}
                        value={feedbackAnswers[data.id]?.value || 0}
                        onChange={(rating) =>
                          handleFeedbackAnswer(data, rating)
                        }
                        half={false}
                      />
                    )}
                    {data.type == 'text' && (
                      <textarea
                        className="w-100"
                        onBlur={(event) =>
                          handleFeedbackAnswer(data, event.currentTarget.value)
                        }
                      ></textarea>
                    )}
                  </Col>
                </>
              )}
            </Row>
          );
        })}
        <Row>
          <Col>
            <Button disabled={!isFeedbackGiven} onClick={handleClickSendBtn}>
              Send Feedback
            </Button>
          </Col>
        </Row>
      </>
    );
  } else {
    return (
      <Row className={'py-5'}>
        <Col className="text-center">
          <p>
            You have already voted for <b>{exhibitor.name}</b>. Thank you for
            your feedback!
          </p>
        </Col>
      </Row>
    );
  }
}
