import * as React from 'react';
import { Link as ReactRouterDomLink } from 'react-router-dom';
// @ts-ignore
import styles from './Link.module.scss?module';

interface LinkProps {
  to?: string;
  href?: string;
  children?: React.ReactNode;
  inheritColor?: boolean;
  className?: string;
  opacityOnHover?: boolean;
  underline?: boolean;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  openInNewTab?: boolean;
  style?: React.CSSProperties;
}

export default function Link({
  to,
  href,
  children,
  inheritColor = true,
  className = '',
  opacityOnHover = true,
  underline = false,
  onClick,
  openInNewTab = false,
  style = {},
}: LinkProps) {
  const combinedClassName = `${inheritColor ? styles.inheritColor : ''} ${
    opacityOnHover ? styles.opacityOnHover : ''
  } ${underline ? styles.underline : styles.noUnderline} ${className}`;

  if (to && href) {
    throw new Error('Only one of "to" or "href" should be provided, not both.');
  }

  if (to) {
    return (
      <ReactRouterDomLink
        onClick={onClick}
        className={combinedClassName}
        to={to}
        style={style}
      >
        {children}
      </ReactRouterDomLink>
    );
  }

  if (href) {
    return (
      <a
        onClick={onClick}
        className={combinedClassName}
        href={href}
        target={openInNewTab ? '_blank' : '_self'}
        rel={openInNewTab ? 'noopener noreferrer' : ''}
        style={style}
      >
        {children}
      </a>
    );
  }

  return (
    <a
      onClick={(event) => {
        event.preventDefault();
        if (onClick) {
          onClick(event);
        }
      }}
      className={combinedClassName}
      href="#"
      style={style}
    >
      {children}
    </a>
  );
}
