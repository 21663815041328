import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import {NavLink} from "react-router-dom";


export default function Index() {
    return (
        <React.Fragment>
            <Container>
                <Row>
                    <Col>
                        <h1>API overview</h1>
                        <br />
                        <h2>SKU mapping and order upload in basket</h2>
                        <p>To use any API feature it's required to create a mapping between our SKUs and your related identifications <a className={"blue"} href={"/service-center/api/sku-mapping"}>here</a>.</p>
                        <p>
                            In case your <a className={"blue"} href={"/basket"}>basket</a> is empty you see the possibility to download an excel order template to prepare your wished quantities based on your SKU identifications.<br />
                            The basket UI will inform you about further needed steps to complete your order.<br />
                            Due to availability and promotions we offer only this interactive way to place an order.
                        </p>
                        
                        <h2>SKU master data synchronization</h2>
                        <p>Receive our article master data by <a className="blue" href="https://naskorsports.com/service-center/api/csv/download">downloading a CSV File</a> or request it from our <NavLink className={"blue"} to={"/service-center/api/rest"}>REST API</NavLink>.</p>
                        <p>Regardless of the method chosen, it is crucial to map your SKUs to ours in the <a className={"blue"} href={"/service-center/api/sku-mapping"}>SKU mapping</a>. This ensures that the data synchronized matches your system's product identifiers, facilitating a seamless integration process.</p>
                        <p>To use our <NavLink className={"blue"} to={"/service-center/api/rest"}>REST API</NavLink>, please <NavLink className={"blue"} to={"/service-center/api/key"}>create your API Key here</NavLink>.</p>
                        
                        <h2>Live stock updates</h2>
                        <p>
                            To receive live stock updates you will have to <NavLink className={"blue"} to={"/service-center/api/stock"}>provide a REST endpoint</NavLink> on your end to enable us pushing you the updated information.<br />
                            You will only receive stock updates for SKUs you mapped in our <a className={"blue"} href={"/service-center/api/sku-mapping"}>SKU mapping</a>.
                        </p>
                        
                        <h2>CSV download</h2>
                        <p>To download the csv file, access the following link:</p>
                        <p><a className="blue" href="/service-center/api/csv/download">https://naskorsports.com/service-center/api/csv/download</a></p>
                        <p>Clicking this link will initiate the download of all mapped data in CSV format. Please ensure you are logged in to your account on our website to access this feature, as it requires user authentication.</p>
                        <p><b>CSV File Specifications:</b></p>
                        <ul>
                            <li><b>Delimiter</b>: The fields in our CSV file are separated by commas (<code className="bg-secondary px-2">,</code>).</li>
                            <li><b>Text Qualifier</b>: Double quotes (<code className="bg-secondary px-2">"</code>) are used as text qualifiers. This ensures that any field content, including delimiters, is treated as part of the field value.</li>
                            <li><b>Field Separator Within a Field</b>: For fields containing multiple values, such as lists of scan codes, a semicolon (<code className="bg-secondary px-2">;</code>) is used to separate individual values.</li>
                        </ul>
                        <p>It is crucial to map your SKUs to ours in the <a className={"blue"} href={"/service-center/api/sku-mapping"}>SKU mapping</a>. This ensures that the data synchronized matches your system's product identifiers, facilitating a seamless integration process.</p>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
}
