import { faList, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/pro-solid-svg-icons';
import * as React from 'react';
import {Col, Modal, Row} from 'react-bootstrap';
import TopIcon from '../Abstracts/TopIcon/TopIcon';
import AccountContent from './AccountContent/AccountContent';
import ExhibitorsContent from './ExhibitorsContent/ExhibitorsContent';
import VtsBasketContent from './VtsBasketContent/VtsBasketContent';
// @ts-ignore
import Modal1 from '../../../Abstracts/Modal1/Modal1';
import {useUser} from "../../../Context/UserContext";
import LoginForm from "../../../Abstracts/Forms/LoginForm/LoginForm";
import Popup from "../../../Abstracts/Popup/Popup";

export default function TopRightContents() {
  const userContext = useUser();
  const [showExhibitors, setShowExhibitors] = React.useState<boolean>(false);
  const [showBasket, setShowBasket] = React.useState<boolean>(false);
  const [showAccount, setShowAccount] = React.useState<boolean>(false);

  if(userContext.loading) {
    return null;
  }

  return (
    <>
      {userContext.user &&
          <>
            <label
                onClick={() => {
                  setShowExhibitors(true);
                }}
            >
                <TopIcon text={'Exhibitors'} icon={faList} />
            </label>
            <Modal1
                show={showExhibitors}
                title={'Exhibitors'}
                onHide={() => {
                  setShowExhibitors(false);
                }}
            >
                <ExhibitorsContent
                    onRequestClose={() => {
                      setShowExhibitors(false);
                    }}
                />
            </Modal1>
          </>
      }

      {userContext.user &&
        <>
          <label
            onClick={() => {
              setShowBasket(true);
            }}
          >
            <TopIcon text={'Order overview'} icon={faShoppingCart} />
          </label>
          <Modal1
            show={showBasket}
            onHide={() => {
              setShowBasket(false);
            }}
            title={'Order overview'}
          >
            <VtsBasketContent />
          </Modal1>
        </>
      }

      <label
        onClick={() => {
          setShowAccount(true);
        }}
      >
        <TopIcon text={'Account'} icon={faUser} />
      </label>

      {userContext.user
        ? (
          <Modal1
            show={showAccount}
            onHide={() => {
              setShowAccount(false);
            }}
            title={'My account'}
          >
            <AccountContent />
          </Modal1>
        )
        : (
          <Popup
            centered
            show={showAccount}
            onHide={() => {
              setShowAccount(false);
            }}
          >
            <div className={"p-5"}>
              <div>
                <h2 className={"fst-italic fw-bold fs-3 pb-3"}>My account</h2>
              </div>
              <LoginForm />
            </div>
          </Popup>
        )
      }
    </>
  );
}
