import React from 'react';

// @ts-ignore
import styles from './ServiceCenter.module.scss?module';
import cn from 'classnames';
import { Link } from 'react-router-dom';

export default function ServiceCenter({ setShowServiceCenter }) {
  return (
    <>
      <div
        className={['position-absolute text-black', styles.dropdown].join(' ')}
      >
        <Link
          to={'/service-center/just-in'}
          className={cn('px-4 py-2', styles.menuItem)}
          onClick={() => {
            setShowServiceCenter(false);
          }}
        >
          <div>Just in</div>
          <div>Check out all recently arrived products</div>
        </Link>
        <Link
          to={'/service-center/eta'}
          className={cn('px-4 py-2', styles.menuItem)}
          onClick={() => {
            setShowServiceCenter(false);
          }}
        >
          <div>ETA</div>
          <div>All deliveries that we will receive in the near future</div>
        </Link>
        <Link
          to={'/service-center/price-change'}
          className={cn('px-4 py-2', styles.menuItem)}
          onClick={() => {
            setShowServiceCenter(false);
          }}
        >
          <div>Price changes</div>
          <div>All information about price changes</div>
        </Link>
        <Link
          to={'/service-center/discontinued'}
          className={cn('px-4 py-2', styles.menuItem)}
          onClick={() => {
            setShowServiceCenter(false);
          }}
        >
          <div>Discontinued</div>
          <div>Be informed about discontinued products</div>
        </Link>
        <Link
          to={'/service-center/available-soon'}
          className={cn('px-4 py-2', styles.menuItem)}
          onClick={() => {
            setShowServiceCenter(false);
          }}
        >
          <div>Available soon</div>
          <div>Be informed about products you can buy soon</div>
        </Link>
      </div>
    </>
  );
}
