import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import LogoImage from '../../Abstracts/LogoImage/LogoImage';
import Hamburger from 'hamburger-react';
// @ts-ignore
import style from './Header.module.scss?module';
import Basket from './Icons/Basket/Basket';
import Search from './Search/Search';
import MenuDesktop from './MenuDesktop/MenuDesktop';
import UserMenu from './UserMenu/UserMenu';
import GymAssortmentBadge from '../../Abstracts/GymAssortmentBadge/GymAssortmentBadge';
import { useUser } from '../../Context/UserContext';
import Link from '../../Abstracts/Link/Link';
import NcwMarquee from '../NewcomerWeek/Abstracts/NcwMarquee/NcwMarquee';

export default function Header({
  setSidebarOpen,
  sidebarOpen,
}: {
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  sidebarOpen: boolean;
}) {
  const { user, basketInfo } = useUser();
  return (
    <>
      <div className={style.header}>
        {Date.now() < Date.UTC(2024, 8, 22, 22, 0, 0) && (
          <Link href={'/newcomer-week/home'} opacityOnHover={false}>
            <NcwMarquee fluid />
          </Link>
        )}
        <Container>
          <Row className={'pt-4 d-none d-lg-block'}>
            <Col className={'d-flex justify-content-between'}>
              <div className={style.logo}>
                <LogoImage className={'w-100'} />
              </div>
              <div style={{ paddingTop: '3px' }}>
                <MenuDesktop />
              </div>
              <div style={{ paddingTop: '3px' }}>
                <GymAssortmentBadge />
              </div>
              <div>
                <Search />
              </div>
              <div style={{ paddingTop: '6px' }}>
                <UserMenu user={user} />
              </div>
              {basketInfo && (
                <div style={{ paddingTop: '6px' }}>
                  <Basket basketInfo={basketInfo} />
                </div>
              )}
            </Col>
          </Row>
          <div className={'d-lg-none pt-2 d-flex justify-content-between'}>
            <Hamburger
              size={25}
              toggle={setSidebarOpen}
              toggled={sidebarOpen}
            />
            <div className="d-flex align-items-center">
              <LogoImage height={'20px'} />
            </div>
            <div className="d-flex align-items-center">
              <Basket basketInfo={basketInfo} />
            </div>
          </div>
          <Row className={'d-lg-none pb-4'}>
            <Col xs={12}>
              <Search />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
