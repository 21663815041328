import React, { useEffect } from 'react';
import Modal, { ModalProps } from 'react-bootstrap/Modal';
// @ts-ignore
import styles from './Popup.module.scss?module';
import cn from 'classnames';

interface PopupProps extends ModalProps {}

interface PopupComponents {
  Header: typeof Modal.Header;
  Body: typeof Modal.Body;
  Footer: typeof Modal.Footer;
}

const Popup: React.FC<PopupProps> & PopupComponents = ({
  children,
  show,
  useGridWidth,
  ...props
}) => {
  const Header = Modal.Header;
  const Body = Modal.Body;
  const Footer = Modal.Footer;

  const [showDelayed, setShowDelayed] = React.useState(false);

  useEffect(() => {
    if (show) {
      setShowDelayed(true);
    } else {
      setTimeout(() => {
        setShowDelayed(false);
      }, 150);
    }
  }, [show]);

  return showDelayed ? (
    <Modal
      {...props}
      show={show}
      dialogClassName={cn(
        props.dialogClassName,
        useGridWidth ? styles.PopupGridWidth : ''
      )}
    >
      {children}
    </Modal>
  ) : (
    <></>
  );
};

Popup.Header = Modal.Header;
Popup.Body = Modal.Body;
Popup.Footer = Modal.Footer;

export default Popup;
