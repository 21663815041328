import React, { useState } from 'react';
import Favourites from '../Icons/Favourites/Favourites';
import IconLink from '../Icons/IconLink/IconLink';
import UserMenuDropdown from './UserMenuDropdown/UserMenuDropdown';
import { faInfoCircle, faUser } from '@fortawesome/pro-light-svg-icons';
import ServiceCenter from './ServiceCenter/ServiceCenter';
import useToggleDiv from '../../../Hooks/useToggleDiv';

const UserMenu = ({ user }) => {
  const {
    isVisible: isInfoVisible,
    toggleVisibility: toggleInfoVisibility,
    hideDiv: hideInfo,
    divRef: infoRef,
  } = useToggleDiv();

  const {
    isVisible: isAccountVisible,
    toggleVisibility: toggleAccountVisibility,
    hideDiv: hideAccount,
    divRef: accountRef,
  } = useToggleDiv();

  return (
    <div className="d-flex w-100 align-items-center justify-content-end">
      <div>
        <Favourites />
      </div>
      {user && (
        <>
          <div className="ms-2 position-relative" ref={infoRef}>
            <IconLink
              icon={faInfoCircle}
              onClick={() => toggleInfoVisibility()}
            />
            {isInfoVisible && (
              <div className="position-absolute">
                <ServiceCenter setShowServiceCenter={hideInfo} />
              </div>
            )}
          </div>
        </>
      )}
      <div className="ms-2 position-relative" ref={accountRef}>
        <IconLink icon={faUser} onClick={() => toggleAccountVisibility()} />
        {isAccountVisible && (
          <div className="position-absolute">
            <UserMenuDropdown hideMenu={hideAccount} />
          </div>
        )}
      </div>
    </div>
  );
};

export default UserMenu;
