import * as React from 'react';
import { Button, Col, Form, Image, Row } from 'react-bootstrap';
import { getRequest, postRequest } from '../../../../../Helper/ApiClient';
import MediaCenterFilter from './MediaCenterFilter/MediaCenterFilter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDownload,
  faImage,
  faVideo,
} from '@fortawesome/pro-regular-svg-icons';
//@ts-ignore
import style from './MediaCenter.module.scss?module';
import { useEffect, useState } from 'react';
import { IVtsExhibitor } from '../../../Main/Admin/Exhibitors/Exhibitors';
import LoadingSpinner from '../../../../../Abstracts/LoadingSpinner/LoadingSpinner';
import LazyLoadImage from './LazyLoadImage/LazyLoadImage';
import Modal1 from '../../../../../Abstracts/Modal1/Modal1';

export default function MediaCenter({
  exhibitor,
}: {
  exhibitor: IVtsExhibitor;
}) {
  const [url, setUrl] = useState(
    '/api/virtual-trade-show/exhibitor-media/' + exhibitor.id
  );
  const [filter, setFilter] = useState<string[]>([]);
  const [document, setDocument] = useState<any>({
    name: '',
    link: '',
    category: { id: 0, name: '' },
  });
  const [mediaPopUp, setMediaPopUp] = useState(false);
  const brandDocumentsRequest = getRequest(url);
  const mediaKitDownloadMutation = postRequest(
    '/api/virtual-trade-show/download-media-kit'
  );

  const [preview, setPreview] = useState(false);

  const handleMediaClick = (document: any) => {
    if(
      document.mime_type_prefix == 'video'
      || document.mime_type_prefix == 'image'
    ) {
      setDocument(document);
      setMediaPopUp(true);
    }
    else {
      window.open(document.link, '_blank');
    }
  };

  const formatMB = (bytes: number) => {
    bytes = bytes / 1000000;
    return bytes.toLocaleString('de-DE', { maximumFractionDigits: 1 });
  };

  const getIconByCategory = (category: { id: number; name: string }) => {
    switch (category.name) {
      case 'Videos':
        return faVideo;
      default:
        return faImage;
    }
  };

  const handleDownloadClick = () => {
    let formData = new FormData();
    formData.append('exhibitor_id', exhibitor.id.toString());
    mediaKitDownloadMutation.mutate(formData);
  };

  // useEffect(() => {
  //   if (filter.length > 0) {
  //     var newURL = '';
  //     if (url.includes('exclude_category_id')) {
  //       let urlObj = new URL(location.origin + url);
  //       let searchParams = new URLSearchParams(urlObj.search);
  //       searchParams.set('exclude_category_id', filter.join(','));
  //       newURL =
  //         '/api/virtual-trade-show/exhibitor-media/' +
  //         exhibitor.id +
  //         '?' +
  //         searchParams.toString();
  //     } else {
  //       const searchParams = new URLSearchParams();
  //       searchParams.set('exclude_category_id', filter.join(','));
  //       newURL =
  //         '/api/virtual-trade-show/exhibitor-media/' +
  //         exhibitor.id +
  //         '?' +
  //         searchParams.toString();
  //     }
  //     setUrl(newURL);
  //   }
  // }, [filter]);

  return (
    <>
      <Row className="mb-3">
        <Col>
          <h2 className={'popupHeadline'}>Media Center</h2>
          {exhibitor.complete_media_kit_url.length > 0 && (
            <Button
              href={exhibitor.complete_media_kit_url}
              onClick={handleDownloadClick}
            >
              <FontAwesomeIcon icon={faDownload} /> Download complete media kit
            </Button>
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <MediaCenterFilter filter={filter} setFilter={setFilter} />
        </Col>
        <Col>
          <p className="h2 fs-6">Display options</p>
          <Form.Check
            checked={preview}
            label={'Render previews'}
            onChange={() => {
              setPreview(!preview);
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <hr className={'py-4'} />
        </Col>
      </Row>

      {preview ? (
        <Row>
          {brandDocumentsRequest.isSuccess &&
            brandDocumentsRequest.data &&
            brandDocumentsRequest.data.map((brandDocument: any) => {
              const includes = filter.includes(brandDocument.category.id.toString())
              if(includes) {
                return null;
              }
              return (
                <Col key={brandDocument.id} xs={6} xl={4}>
                  <div
                    onClick={() => handleMediaClick(brandDocument)}
                    className={style.previewDiv}
                  >
                    {/*<Image src={brandDocument.link} className={'w-100'} />*/}
                    <LazyLoadImage
                      src={brandDocument.link}
                      alt={brandDocument.name}
                      className={'w-100'}
                      height={200}
                    />
                    <br />
                    <strong>Name:</strong> {brandDocument.name}
                    <br />
                    <strong>Category:</strong> {brandDocument.category.name}
                    <br />
                    <strong>Size:</strong> ({formatMB(brandDocument.size)} MB)
                  </div>
                </Col>
              );
            })}
        </Row>
      ) : (
        <Row>
          <Col>
            {brandDocumentsRequest.isLoading && (
              <div className={'text-center py-5'}>
                <LoadingSpinner />
              </div>
            )}
            {brandDocumentsRequest.isSuccess &&
              brandDocumentsRequest.data &&
              brandDocumentsRequest.data.map((brandDocument: any) => {
                const includes = filter.includes(brandDocument.category.id.toString())
                if(includes) {
                  return null;
                }
                return (
                  <Row
                    role="button"
                    key={brandDocument.id}
                    onClick={() => handleMediaClick(brandDocument)}
                    className={[
                      style.RowLinked,
                      'd-flex',
                      'justify-content-between',
                    ].join(' ')}
                  >
                    <Col
                      md={9}
                      style={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <FontAwesomeIcon
                        icon={getIconByCategory(brandDocument.category)}
                      />{' '}
                      {brandDocument.name}
                    </Col>
                    <Col md={3} className={style.mobileSmall}>
                      <Row>
                        <Col xs={8}>{brandDocument.category.name}</Col>
                        <Col xs={4} className="text-end">
                          {formatMB(brandDocument.size)} MB
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                );
              })}
          </Col>
        </Row>
      )}

      <Modal1
        show={mediaPopUp}
        onHide={() => {
          setMediaPopUp(false);
        }}
      >
        <div className="h-100 d-flex align-items-center justify-content-center">
          {document.mime_type_prefix == 'video'
            ? (
              <video
                width="80%"
                controls
                preload="none"
                // autoPlay
                style={{ maxWidth: '100%', margin: 'auto' }}
              >
                <source src={document.link} />
              </video>
            )
            : (document.mime_type_prefix == 'image'
                ? <Image src={document.link} style={{ maxWidth: '100%' }} />
                : document.name
            )
          }
        </div>
      </Modal1>
    </>
  );
}
