import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// @ts-ignore
import style from "./TopIcon.module.scss?module";

export default function TopIcon(props) {
  return (
    <div className={[props.className, style.TopIcon].join(" ")}>
      <div className={style.TopIconIcon}>
        <FontAwesomeIcon icon={props.icon} />
      </div>
      <span className={style.TopIconText}>{props.text}</span>
      {props.textBottom && (
        <div className={style.TextBottom}>{props.textBottom}</div>
      )}
    </div>
  );
}
