import React from 'react';
import { Image } from 'react-bootstrap';
// @ts-ignore
import LogoWhite from './logo-white.svg';
// @ts-ignore
import LogoBlue from './logo-blue.svg';

export default function LogoImage(props) {
  return (
    <div
      className={[props.className].join(' ')}
      style={props.height ? { height: props.height } : {}}
    >
      {/*Todo: Link to instead of a href after all routes are SPA */}
      <a
        href={props.noNavigation ? undefined : '/'}
        className={['inherit', props.height ? 'h-100' : 'w-100'].join(' ')}
      >
        <Image
          src={props.white ? LogoWhite : LogoBlue}
          className={[props.height ? 'h-100' : 'w-100'].join(' ')}
        />
      </a>
    </div>
  );
}
