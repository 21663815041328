import React, { useEffect } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { getRequest, postRequest } from "../../../../Helper/ApiClient";

export default function Stock() {
    const getUserApiSettings = getRequest('/api/api_settings');
    const updateUserApiSettingMutation = postRequest('/api/api_settings');

    const inputOnChangeHandler = (event: React.FocusEvent<HTMLInputElement>) => {
        let formData = new FormData();
        formData.append("name", event.currentTarget.name);
        formData.append("value", event.currentTarget.value);
        updateUserApiSettingMutation.mutate(formData, {
            onSuccess: () => {
                getUserApiSettings.refetch();
            }
        })
    }

    useEffect(() => {
        if(getUserApiSettings.isSuccess) {
            console.log(getUserApiSettings.data)
        }
    }, [getUserApiSettings]);
    return (
        <React.Fragment>
            <Container>
                <Row>
                    <Col>
                        <h1>Stock</h1>
                        <p>
                            We offer live stock updates.<br />
                            To receive them please provide an endpoint URL implementing the <a href="https://editor.swagger.io/?url=https://rest.naskorsports.com/customer-end-api/v1/documentation" target={"_blank"}>Open-API specifications</a>.<br />
                            Please note, that for stock updates we extend your provided URL and path by "/stock" as shown in the linked documentation.
                        </p>
                        <div>
                            <Form.Control
                                type={"url"}
                                placeholder="https://your-api-url.com/your-optional-path"
                                onBlur={inputOnChangeHandler}
                                name="api_url"
                                defaultValue={getUserApiSettings.data?.stock_endpoint_url}
                            />
                        </div>
                        <p className="mt-2">
                            To prevent issues by having your endpoint unprotected you can define an authorization token which we will then use to authorize our request to your endpoint.
                        </p>
                        <div>
                            <Form.Control
                                type={"text"}
                                placeholder="Authorization token (optional)."
                                onBlur={inputOnChangeHandler}
                                name="authorization_token"
                                defaultValue={getUserApiSettings.data?.stock_endpoint_auth_header}
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
}
