import React from "react";
import {Routes, Route} from "react-router-dom";
import Index from "./Index/Index";
import Rest from "./Rest/Rest";
import Key from "./Key/Key";
import Stock from "./Stock/Stock";
import SkuMapping from "./SkuMapping/SkuMapping";

export default function Api() {
    return (
        <div className={"py-4"}>
            <Routes>
                <Route path="/" element={<Index />} />
                <Route path="/index" element={<Index />} />
                <Route path="/rest" element={<Rest />} />
                <Route path="/key" element={<Key />} />
                <Route path="/stock" element={<Stock />} />
                <Route path="/sku-mapping" element={<SkuMapping />} />
            </Routes>
        </div>
    );
}
